import { Drawer } from '@material-ui/core';
import { History } from 'history';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import logo from 'src/assets/images/logos/icon.png';
import { media } from 'src/assets/_responsive-mixin';
import Auxilary from 'src/components/hoc/Auxilary';
import {
  Dropdown,
  DropdownItem,
} from 'src/components/shared/Dropdown/Dropdown';
import { Icon } from 'src/components/shared/Icon/Icon';
import { RootState, useReduxDispatch } from 'src/store';
import { logoutOidc } from 'src/store/auth/auth.actions';
import { selectCurrentUser } from 'src/store/auth/auth.selectors';
import styled from 'styled-components';
import { LocationSelector } from '../Locations/LocationSelector';
import { ProjectSelector } from '../Projects/ProjectSelector';
import { ProfileForm } from './ProfileForm';

type Props = {
  history: History;
};

export const Header: React.FC<Props> = () => {
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
  const [profileOpen, setProfileOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const user = useSelector((state: RootState) => selectCurrentUser(state.auth));

  const logout = () => {
    dispatch(logoutOidc());
  };

  return (
    <Auxilary>
      <HeaderContainer>
        <LogoContainer>
          <Logo src={logo} alt="ConForm logo" />
        </LogoContainer>
        <LeftContainer>
          {user && (
            <Auxilary>
              {user?.allLocations && <LocationSelector className="cf-mr-8" />}
              <ProjectSelector />
            </Auxilary>
          )}
        </LeftContainer>
        <RightContainer>
          <Divider />
          <NavItem>
            <NavButton onClick={() => setUserMenuOpen(!userMenuOpen)}>
              <DropdownIcon className="las la-user em-mr-1" /> {user?.username}
            </NavButton>
            <Dropdown
              active={userMenuOpen}
              onClose={() => setUserMenuOpen(false)}
            >
              <DropdownItem onClick={() => setProfileOpen(!profileOpen)}>
                <Icon type="la-user" /> {t('profile')}
              </DropdownItem>
              <DropdownItem onClick={logout}>
                <Icon type="la-sign-out-alt" /> {t('user.signout')}
              </DropdownItem>
            </Dropdown>
          </NavItem>
        </RightContainer>
      </HeaderContainer>
      <Drawer
        anchor="right"
        open={profileOpen}
        onClose={() => setProfileOpen(false)}
      >
        <ProfileForm user={user} onCancel={() => setProfileOpen(false)} />
      </Drawer>
    </Auxilary>
  );
};

const HeaderContainer = styled.header`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  background: var(--header-background);
  z-index: 99;
  padding: 0 124px 10px calc(124px + var(--sidebar-width));

  ${media.large`
    padding: 0 64px 10px calc(64px + var(--sidebar-width));
  `}

  ${media.medium`
    padding: 0 32px 10px;
  `}

  ${media.small`
    padding: 0 16px 10px;
  `}
`;

const LeftContainer = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  margin-left: calc(var(--content-offset) - 32px);
  font-family: 'Poppins';
  font-size: 1.125rem;
  font-weight: 600;

  ${media.small`
    font-size: 1rem;
  `}

  ${media.xsmall`
    font-weight: 500;
    margin-left: 0;
  `}
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled.div`
  background: var(--green);
  height: 40px;
  width: 1px;
  margin: 0 2.5rem;

  ${media.xsmall`
    margin: 0 1rem;
  `}
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;
  height: 45px;
`;

const NavButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--green-darkest);
  text-transform: capitalize;
  background: transparent;
  border: 0;
  outline: none;

  &:focus {
    outline: none;
  }

  &:hover {
    color: var(--green-dark);
  }
`;

const DropdownIcon = styled.i`
  position: relative;
  font-size: 1.5rem;
  color: var(--green);
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: var(--sidebar-width);
  height: var(--header-height);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  max-width: 30px;
  height: 30px;

  ${media.medium`
    display: none;
  `}
`;
