import { Dispatch } from 'redux';
import { getRequest } from 'src/store/api-handler';
import { PricebookDto } from 'src/store/pricebooks/pricebook';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum PricebookTypes {
  FetchAll = 'pricebooks/FetchAll'
}

export type PricebookAction = {
  type: PricebookTypes.FetchAll;
  pricebooks: PricebookDto[];
};

export const actionCreators = {
  fetchPricebooks: (pricebooks: PricebookDto[]): PricebookAction => ({
    type: PricebookTypes.FetchAll,
    pricebooks
  })
};

export const fetchPricebooks = (locationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(PricebookTypes.FetchAll));
    const { data } = await getRequest(`/pricebooks/location/${locationId}`);
    dispatch(requestEnd(PricebookTypes.FetchAll));
    return dispatch(actionCreators.fetchPricebooks(data));
  };
};
