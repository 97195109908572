import { CustomerDto } from 'src/store/customers/customer';
import {
  CustomerAction,
  CustomerTypes,
} from 'src/store/customers/customers.actions';

export type CustomersState = Readonly<{
  customers: CustomerDto[];
}>;

const initialState: CustomersState = {
  customers: [],
};

const customersReducer = (
  state: CustomersState = initialState,
  action: CustomerAction
): CustomersState => {
  switch (action.type) {
    case CustomerTypes.FetchAll:
      return { ...state, customers: action.customers };
    default:
      return state;
  }
};

export default customersReducer;
