import { Modal as MatModal } from '@material-ui/core';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { media } from 'src/assets/_responsive-mixin';
import styled from 'styled-components';
import { slideUp } from '../Animations/KeyFrames';
import { ActionButton, PrimaryButton, TertiaryButton } from '../Button/Button';
import { Header2 } from '../Typography/Header2';

type Props = {
  header?: string;
  width?: number;
  open: boolean;
  confirmText?: string;
  showActions?: boolean;
  allowCommonExitStrategies?: boolean;
  children: any;
  onClose: () => void;
  onConfirm?: () => void;
};

export const Modal: React.FC<Props> = props => {
  const {
    header,
    width,
    open,
    showActions,
    confirmText,
    allowCommonExitStrategies,
    children,
    onClose,
    onConfirm
  } = props;

  const modal = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (allowCommonExitStrategies && event.code === 'Escape') {
        event.preventDefault();
        onClose();
      }
    },
    [allowCommonExitStrategies, onClose]
  );

  /** Handle escape pressed */
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  }, [handleKeyUp]);

  /** Handle outside click */
  const handleOutsideClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (allowCommonExitStrategies && event.target === modal.current) {
      onClose();
    }
  };

  return (
    <MatModal open={open}>
      <ModalWrapper
        onMouseDown={event => handleOutsideClick(event)}
        ref={modal}
        style={{
          maxWidth: width
        }}
      >
        <CloseButton onClick={onClose} icon="las la-times" />

        {header && (
          <ModalHeader>
            <Header2>{header}</Header2>
          </ModalHeader>
        )}

        <ModalContent>{children}</ModalContent>
        {showActions && (
          <ModalActions>
            <TertiaryButton label={t('cancel')} onClick={onClose} />
            <PrimaryButton
              label={confirmText ? confirmText : t('confirm')}
              onClick={onConfirm}
            />
          </ModalActions>
        )}
      </ModalWrapper>
    </MatModal>
  );
};

Modal.defaultProps = {
  width: 700,
  showActions: false,
  allowCommonExitStrategies: true
};

const ModalWrapper = styled.div`
  background-color: #fff;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  max-height: 90%;
  margin: auto;
  overflow: hidden;
  border-radius: 8px;
  animation: ${slideUp} 300ms ease-out;
`;

export const ModalHeader = styled.section`
  padding: 2rem 4rem;
  border-bottom: 1px solid var(--primary-color20);
`;

const ModalContent = styled.section`
  display: flex;
  flex-direction: column;
  overflow: auto;

  padding: 3rem 4rem;

  ${media.xsmall`
		padding: 16px;
	`}

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 48px;
`;

const CloseButton = styled(ActionButton)`
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 500;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem 4rem;
`;
