import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { User } from 'oidc-client';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AuthContext from 'src/components/pages/Auth/AuthContext';
import LoginCallback from 'src/components/pages/Auth/LoginCallback';
import Navigation from 'src/components/pages/Navigation/Navigation';
import Container from 'src/components/shared/Container/Container';
import { PageLoader } from 'src/components/shared/Loader/PageLoader';
import routes from 'src/routes';
import { RootState, useReduxDispatch } from 'src/store/index';
import { fetchArticles } from './store/articles/articles.actions';
import { fetchProducts } from './store/products/products.actions';

type Props = {
  history: History;
};

type StateProps = {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  user: Nullable<User>;
};

const App: React.FC<Props & StateProps> = props => {
  const { history, user, isAuthenticated, isAuthenticating } = props;

  const dispatch = useReduxDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchProducts());
      dispatch(fetchArticles());
    }
  }, [isAuthenticated, dispatch]);

  if (isAuthenticating) {
    return <PageLoader />;
  }

  const path = props.history.location.pathname;
  if (path.includes('/login-complete') && !isAuthenticated) {
    return <LoginCallback history={history} />;
  }

  return (
    <AuthContext.Provider value={{ user, isAuthenticated }}>
      <ConnectedRouter history={history}>
        <Navigation history={history} />
        <Container>{routes}</Container>
      </ConnectedRouter>
    </AuthContext.Provider>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.auth.oidcUser,
  isAuthenticated: state.auth.isAuthenticated,
  isAuthenticating: state.auth.isAuthenticating
});

export const Application = connect(mapStateToProps, {})(App);
