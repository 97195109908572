import i18n from 'i18next';
import { Dispatch } from 'redux';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'src/store/api-handler';
import { UserDetailDto, UserDto, UserEditDto } from 'src/store/users/user';
import { alertError, alertSuccess } from '../alerts/alerts.actions';
import { requestBegin, requestEnd } from '../requests/requests.actions';
import { Language } from './users.reducer';

export enum UserTypes {
  Fetch = 'users/Fetch',
  FetchAll = 'users/FetchAll',
  FetchRoles = 'users/FetchRoles',
  Delete = 'users/Delete',
  Failure = 'users/Failure',
  ChangeLanguage = 'users/ChangeLanguage'
}

export type UserAction =
  | { type: UserTypes.Fetch; user: Nullable<UserDetailDto> }
  | { type: UserTypes.FetchAll; users: UserDto[] }
  | { type: UserTypes.FetchRoles; roles: string[] }
  | { type: UserTypes.Delete; user: UserDto }
  | { type: UserTypes.Failure; errors: any }
  | { type: UserTypes.ChangeLanguage; language: Language };

export const actionCreators = {
  fetchUser: (user: UserDetailDto): UserAction => ({
    type: UserTypes.Fetch,
    user
  }),
  fetchUsers: (users: UserDto[]): UserAction => ({
    type: UserTypes.FetchAll,
    users
  }),
  fetchRoles: (roles: string[]): UserAction => ({
    type: UserTypes.FetchRoles,
    roles
  }),
  deleteUser: (user: UserDto): UserAction => ({
    type: UserTypes.Delete,
    user
  }),
  userRequestFailure: (errors: any): UserAction => ({
    type: UserTypes.Failure,
    errors
  }),
  changeLanguage: (language: Language): UserAction => ({
    type: UserTypes.ChangeLanguage,
    language
  })
};

export const fetchUser = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await getRequest(`/users/${id}`);
    return ok
      ? dispatch(actionCreators.fetchUser(data))
      : dispatch(actionCreators.userRequestFailure(error));
  };
};

export const fetchUsers = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(UserTypes.FetchAll));
    const { data } = await getRequest('/users');
    dispatch(requestEnd(UserTypes.FetchAll));
    return dispatch(actionCreators.fetchUsers(data));
  };
};

export const fetchRoles = () => {
  return async (dispatch: Dispatch) => {
    const { data } = await getRequest('/users/roles');
    return dispatch(actionCreators.fetchRoles(data));
  };
};

export const createUser = (user: UserEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await postRequest('/users', user);
    if (ok) {
      dispatch(alertSuccess(i18n.t('user.created')));
      return dispatch(actionCreators.fetchUser(data));
    } else {
      dispatch(alertError(i18n.t('creation.failed')));
      return dispatch(actionCreators.userRequestFailure(error));
    }
  };
};

export const editUser = (id: number, user: UserEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await putRequest(`/users/${id}`, user);
    if (ok) {
      dispatch(alertSuccess(i18n.t('user.updated')));
      return dispatch(actionCreators.fetchUser(data));
    } else {
      dispatch(alertError(i18n.t('creation.failed')));
      return dispatch(actionCreators.userRequestFailure(error));
    }
  };
};

export const deleteUser = (id: number, user: UserDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await deleteRequest(`/users/${id}`, user);
    if (ok) {
      return dispatch(actionCreators.deleteUser(user));
    }
    return dispatch(actionCreators.userRequestFailure(error));
  };
};

export const setUserLanguage = (language: Language) => {
  return async (dispatch: Dispatch) => {
    localStorage.setItem('language', language);
    return dispatch(actionCreators.changeLanguage(language));
  };
};
