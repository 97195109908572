import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import {
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import { savePDF } from '@progress/kendo-react-pdf';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Auxilary from 'src/components/hoc/Auxilary';
import { PrimaryButton } from 'src/components/shared/Button/Button';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { RootState, useReduxDispatch } from 'src/store';
import { CargoDto } from 'src/store/cargoes/cargo';
import {
  fetchCargoWaybillData,
  fetchFilteredCargoes,
} from 'src/store/cargoes/cargoes.actions';
import {
  selectCargoWaybillData,
  selectFilteredCargoes,
} from 'src/store/cargoes/cargoes.selectors';
import { selectActiveProject } from 'src/store/projects/projects.selectors';
import { isLoadingCargoes } from 'src/store/requests/requests.selectors';
import { updateRequestFilter } from '../Labels/LabelsPage';
import './Waybill.css';
import { WaybillList } from './WaybillList';

type Props = {};

export const WaybillsPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const project = useSelector((state: RootState) =>
    selectActiveProject(state.projects)
  );

  const filteredCargoes = useSelector((state: RootState) =>
    selectFilteredCargoes(state.cargoes)
  );

  const waybill = useSelector((state: RootState) =>
    selectCargoWaybillData(state.cargoes)
  );

  const isLoading = useSelector((state: RootState) => isLoadingCargoes(state));

  const [cargo, setCargo] = useState<Nullable<CargoDto>>(null);
  const [cargoDataState, setCargoDataState] = useState<
    Nullable<DataSourceRequestState>
  >(null);

  const [pages] = useState<number>(3);
  const [showInstructions, setShowInstructions] = useState<boolean>(true);

  const waybillRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCargo(null);
    if (project) {
      setCargoDataState({
        skip: 0,
        take: 20,
        filter: {
          filters: [
            {
              field: 'projectId',
              operator: 'eq',
              value: project?.id,
            },
          ],
          logic: 'and',
        },
      });
    }
  }, [project]);

  const fetchCargoes = useCallback(
    (dataState: DataSourceRequestState) => {
      const queryStr = `${toDataSourceRequestString(dataState)}`;
      dispatch(fetchFilteredCargoes(queryStr));
    },
    [dispatch]
  );

  useEffect(() => {
    if (cargoDataState && project) {
      fetchCargoes(cargoDataState);
    }
  }, [fetchCargoes, cargoDataState, project]);

  const onCargoFilterChange = (event: ComboBoxFilterChangeEvent) => {
    if (event.nativeEvent instanceof FocusEvent) return;
    setCargoDataState(updateRequestFilter(cargoDataState!, event.filter, 'no'));
  };

  const onCargoChange = (event: ComboBoxChangeEvent) => {
    if (event.nativeEvent instanceof FocusEvent) return;
    const cargo: CargoDto = event.value;
    setCargo(cargo);
    if (cargo) dispatch(fetchCargoWaybillData(cargo.id));
  };

  const print = (waybillRef: RefObject<HTMLElement>, cargo: CargoDto) => {
    if (waybillRef.current && cargo) {
      savePDF(waybillRef.current, {
        scale: 0.51,
        paperSize: 'A4',
        forcePageBreak: '.page-break',
        keepTogether: 'table',
        fileName: `${t('waybill.plural')}-${cargo?.no}.pdf`,
      });
    }
  };

  const cargoItem = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const collection: CargoDto = itemProps.dataItem;
    return React.cloneElement(
      li,
      li.props,
      <span>
        {collection.no} {collection.comment}
      </span>
    );
  };

  return (
    <Auxilary>
      <Grid container className="cf-mb-4">
        <Grid item xs={6} md={12}>
          <Header1>{t('waybill.plural')}</Header1>
          <div className="flex">
            <BodyType>{t('waybill.text')}</BodyType>
            {!project && (
              <BodyType color="var(--red-status-color)" className="cf-ml-8">
                {t('select')} {t('project.singular')}
              </BodyType>
            )}
          </div>
        </Grid>
      </Grid>

      <Box
        className="cf-mb-8"
        display="flex"
        justifyContent="between"
        alignItems="flex-end"
      >
        <ComboBox
          placeholder={`${t('select')} ${t('cargo.singular')}`}
          dataItemKey="id"
          textField="no"
          suggest
          filterable
          data={filteredCargoes?.data}
          value={cargo}
          onChange={onCargoChange}
          onFilterChange={onCargoFilterChange}
          itemRender={cargoItem}
          className="cf-mr-8"
          style={{ width: '1200px' }}
          loading={isLoading}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showInstructions}
              onChange={() => {
                setShowInstructions(!showInstructions);
              }}
            />
          }
          label={t('waybill.showInstructions')}
        />
        <PrimaryButton
          disabled={!cargo}
          onClick={() => print(waybillRef, cargo!)}
        >
          {t('print')}
        </PrimaryButton>
      </Box>

      <WaybillList
        waybill={waybill}
        pages={pages}
        showInstructions={showInstructions}
        waybillRef={waybillRef}
      />
    </Auxilary>
  );
};
