import { useMediaQuery } from '@material-ui/core';
import { History } from 'history';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { breakpoints, media } from 'src/assets/_responsive-mixin';
import { RootState } from 'src/store';
import { isUserAdmin, selectCurrentUser } from 'src/store/auth/auth.selectors';
import styled from 'styled-components';

interface NavItem {
  name: string;
  route: string;
  icon: string;
  hidden?: boolean;
  disabled?: boolean;
}

type Props = {
  history: History;
};

export const Sidebar: React.FC<Props> = props => {
  const user = useSelector((state: RootState) => selectCurrentUser(state.auth));
  const admin = useSelector((state: RootState) => isUserAdmin(state.auth));

  const [navItems, setNavItems] = useState<NavItem[]>([]);

  const [itemHeight] = useState<number>(80);
  const [activeItem, setActiveItem] = useState<NavItem>();
  const [inkbarPosition, setInkbarPosition] = useState<number>(0);

  const mediumScreen = useMediaQuery(`(max-width: ${breakpoints.medium})`);

  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    setNavItems([
      {
        name: 'dashboard',
        route: '/',
        icon: 'la-tachometer-alt'
      },
      ...(user?.allLocations
        ? [
            {
              name: 'location.plural',
              route: '/locations',
              icon: 'la-globe'
            }
          ]
        : []),
      {
        name: 'project.plural',
        route: '/projects',
        icon: 'la-list'
      },
      {
        name: 'settlement.plural',
        route: '/settlements',
        icon: 'la-file-invoice-dollar'
      },
      {
        name: 'purchaseorder.plural',
        route: '/purchaseorders',
        icon: 'la-shopping-cart'
      },
      {
        name: 'supplier.plural',
        route: '/suppliers',
        icon: 'la-truck'
      },
      {
        name: 'label.plural',
        route: '/labels',
        icon: 'la-tags'
      },
      {
        name: 'waybill.plural',
        route: '/waybills',
        icon: 'la-file-alt'
      },
      {
        name: 'productionreport.plural',
        route: '/reports',
        icon: 'la-chart-bar',
        disabled: true
      },
      {
        name: 'file.plural',
        route: '/files',
        icon: 'la-file'
      },
      {
        name: 'fileType.plural',
        route: '/filetypes',
        icon: 'la-file-signature'
      },
      ...(admin
        ? [
            {
              name: 'user.plural',
              route: '/users',
              icon: 'la-users-cog'
            }
          ]
        : [])
    ]);
  }, [user, admin]);

  const initializeSidebar = useCallback(
    (path: string) => {
      navItems.forEach((item, index) => {
        if (path.includes(item.route)) {
          setActiveItem(item);
          setInkbarPosition(index * itemHeight);
        }
      });
    },
    [navItems, itemHeight]
  );

  useEffect(() => {
    initializeSidebar(location.pathname);
  }, [initializeSidebar, location, navItems]);

  const routeTo = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item: NavItem
  ) => {
    event.persist();
    props.history.push(item.route);
  };

  return (
    <SidebarContainer>
      <NavItemsContainer>
        {navItems
          .filter(item => !item.hidden)
          .map(item => (
            <SidebarItem
              key={item.name}
              active={activeItem?.name === item.name}
              disabled={item.disabled}
              onClick={event => !item.disabled && routeTo(event, item)}
            >
              <NavIcon className={`las ${item.icon}`} />
              <NavText>{t(`${item.name}`)}</NavText>
            </SidebarItem>
          ))}
        <InkBar
          style={{
            ...(!mediumScreen && { top: inkbarPosition }),
            ...(mediumScreen && { left: inkbarPosition })
          }}
        />
      </NavItemsContainer>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.aside`
  position: fixed;
  width: 200px;
  left: 0;
  bottom: 0;
  z-index: 1000;
  height: calc(100% - 60px);
  margin-top: 48px;
  border-right: 1.5px solid rgba(var(--green-rgb), 0.2);
  background: var(--content-background);
  overflow: auto;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.medium`
    height: 80px;
    width: 100%;
    min-width: 100%;
    left: 0;
    bottom: 0;
    margin: 0;
    background: #fff;
    box-shadow: #fff 0 0 5px 10px;
    border-left: none;
    border-top: 1px solid rgba(var(--green-rgb), 0.2);
  `}
`;

const NavItemsContainer = styled.ul`
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;

  ${media.medium`
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    width: auto;
  `}
`;

const SidebarItem = styled.li<{ active?: boolean; disabled?: boolean }>`
  position: relative;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  z-index: 2;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${media.medium`
    width: 100px;
    height: 100%;
    padding: 0 8px;
  `}
`;

const NavIcon = styled.i`
  display: block;
  font-size: 3rem;
  color: var(--green);
`;

const NavText = styled.span`
  display: block;
  font-size: 1rem;
  color: var(--green-darkest);
`;

const InkBar = styled.span`
  position: absolute;
  width: 1.5px;
  height: 80px;
  right: 0;
  background-color: var(--green-dark);
  transition: all 500ms cubic-bezier(0.35, 0, 0.25, 1);

  ${media.medium`
    display: none;
  `}
`;
