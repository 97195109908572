import styled from 'styled-components';
import { media } from 'src/assets/_responsive-mixin';

export default styled.section`
  position: relative;
  overflow-x: hidden;
  height: 100%;
  min-height: calc(100vh - var(--header-height));
  width: calc(100% - var(--sidebar-width));
  padding: 16px 124px;
  margin: 0 auto;
  margin-top: var(--header-height);
  margin-left: var(--sidebar-width);
  background: var(--content-background);

  ${media.large`
    padding: 16px 64px;
	`}

  ${media.medium`
    padding: 16px 32px;
    padding-bottom: 128px;
	`}

  ${media.small`
    padding: 16px;
    padding-bottom: 128px;
	`}
`;
