import i18n from 'i18next';
import { Dispatch } from 'redux';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'src/store/api-handler';
import {
  SupplierDetailDto,
  SupplierDto,
  SupplierEditDto
} from 'src/store/suppliers/supplier';
import { alertError, alertSuccess } from '../alerts/alerts.actions';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum SupplierTypes {
  Fetch = 'suppliers/Fetch',
  FetchAll = 'suppliers/FetchAll',
  Update = 'suppliers/Update',
  Delete = 'suppliers/Delete',
  Failure = 'suppliers/Failure'
}

export type SupplierAction =
  | { type: SupplierTypes.Fetch; supplier: Nullable<SupplierDetailDto> }
  | { type: SupplierTypes.FetchAll; suppliers: SupplierDto[] }
  | { type: SupplierTypes.Update; supplier: SupplierDto }
  | { type: SupplierTypes.Delete; supplier: SupplierDto }
  | { type: SupplierTypes.Failure; errors: any };

export const actionCreators = {
  fetchSupplier: (supplier: SupplierDetailDto): SupplierAction => ({
    type: SupplierTypes.Fetch,
    supplier: supplier
  }),
  fetchSuppliers: (suppliers: SupplierDto[]): SupplierAction => ({
    type: SupplierTypes.FetchAll,
    suppliers
  }),
  updateSupplier: (supplier: SupplierDto): SupplierAction => ({
    type: SupplierTypes.Update,
    supplier
  }),
  deleteSupplier: (supplier: SupplierDto): SupplierAction => ({
    type: SupplierTypes.Delete,
    supplier
  }),
  supplierRequestFailure: (errors: any): SupplierAction => ({
    type: SupplierTypes.Failure,
    errors
  })
};

export const fetchSupplier = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await getRequest(`/suppliers/${id}`);
    return ok
      ? dispatch(actionCreators.fetchSupplier(data))
      : dispatch(actionCreators.supplierRequestFailure(error));
  };
};

export const fetchSuppliers = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(SupplierTypes.FetchAll));
    const { data } = await getRequest('/suppliers');
    dispatch(requestEnd(SupplierTypes.FetchAll));
    return dispatch(actionCreators.fetchSuppliers(data));
  };
};

export const createSupplier = (supplier: SupplierEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await postRequest('/suppliers', supplier);
    supplier.id = data;
    return ok
      ? dispatch(actionCreators.fetchSupplier(supplier as SupplierDetailDto))
      : dispatch(actionCreators.supplierRequestFailure(error));
  };
};

export const editSupplier = (id: number, supplier: SupplierEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await putRequest(`/suppliers/${id}`, supplier);
    return ok
      ? dispatch(actionCreators.updateSupplier(supplier))
      : dispatch(actionCreators.supplierRequestFailure(error));
  };
};

export const deleteSupplier = (id: number, supplier: SupplierDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await deleteRequest(`/suppliers/${id}`, supplier);
    if (ok) {
      dispatch(alertSuccess(i18n.t('supplier.deleted')));
      return dispatch(actionCreators.deleteSupplier(supplier));
    } else {
      dispatch(alertError(i18n.t('supplier.deleteFailed')));
      return dispatch(actionCreators.supplierRequestFailure(error));
    }
  };
};
