import { Dispatch } from 'redux';
import { ElementTypeDto } from 'src/store/elements/element';
import { getRequest } from 'src/store/api-handler';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum ElementTypes {
  FetchElementTypes = 'elements/FetchElementTypes'
}

export type ElementAction = {
  type: ElementTypes.FetchElementTypes;
  elementTypes: ElementTypeDto[];
};

export const actionCreators = {
  fetchElementTypes: (elementTypes: ElementTypeDto[]): ElementAction => ({
    type: ElementTypes.FetchElementTypes,
    elementTypes
  })
};

export const fetchElementTypes = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(ElementTypes.FetchElementTypes));
    const { data } = await getRequest(`/elements/types`);
    dispatch(requestEnd(ElementTypes.FetchElementTypes));
    return dispatch(actionCreators.fetchElementTypes(data));
  };
};
