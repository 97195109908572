import { DataResult } from '@progress/kendo-data-query';
import {
  MouldDto,
  MouldLabelDto,
  MouldStatusDto,
  MouldTypeDto
} from 'src/store/moulds/mould';
import { MouldAction, MouldTypes } from './moulds.actions';

export type MouldsState = Readonly<{
  mould: Nullable<MouldDto>;
  moulds: MouldDto[];
  filteredMoulds: DataResult;
  mouldTypes: MouldTypeDto[];
  mouldStatuses: MouldStatusDto[];
  mouldLabelData: Nullable<MouldLabelDto>;
}>;

const initialState: MouldsState = {
  mould: null,
  moulds: [],
  filteredMoulds: { data: [], total: 0 },
  mouldTypes: [],
  mouldStatuses: [],
  mouldLabelData: null
};

const mouldsReducer = (
  state: MouldsState = initialState,
  action: MouldAction
): MouldsState => {
  switch (action.type) {
    case MouldTypes.Fetch:
      return { ...state, mould: action.mould };
    case MouldTypes.FetchAll:
      return { ...state, moulds: action.moulds };
    case MouldTypes.FetchFiltered:
      return { ...state, filteredMoulds: action.filteredMoulds };
    case MouldTypes.FetchTypes:
      return {
        ...state,
        mouldTypes: action.mouldTypes
      };
    case MouldTypes.FetchStatues:
      return { ...state, mouldStatuses: action.mouldStatuses };
    case MouldTypes.FetchLabelData:
      return { ...state, mouldLabelData: action.mouldLabelData };
    default:
      return state;
  }
};

export default mouldsReducer;
