import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { SecondaryButton, TertiaryButton } from '../../Button/Button';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  pdf: string;
}

export const PDFForm: React.FC<Props> = ({ pdf }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
  const { t } = useTranslation();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <Document
        file={pdf}
        options={{ workerSrc: '/pdf.worker.js' }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <Box display="flex" justifyContent="space-between">
        <SecondaryButton
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          {t('navigation.previous')}
        </SecondaryButton>
        <p>
          {t('navigation.page')} {pageNumber || (numPages ? 1 : '--')}{' '}
          {t('preposition.of')} {numPages || '--'}
        </p>
        <SecondaryButton
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          {t('navigation.next')}
        </SecondaryButton>
        <TertiaryButton>Download</TertiaryButton>
      </Box>
    </>
  );
};
