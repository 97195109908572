import { History } from 'history';
import React from 'react';
import Auxilary from 'src/components/hoc/Auxilary';
import { Header } from './Header';
import { Sidebar } from './Sidebar';

type Props = {
  history: History;
};

const Navigation: React.FC<Props> = props => {
  return (
    <Auxilary>
      <Header {...props} />
      <Sidebar {...props} />
    </Auxilary>
  );
};

export default Navigation;
