import { filterBy } from '@progress/kendo-data-query';
import {
  ComboBox,
  ComboBoxChangeEvent,
  ListItemProps
} from '@progress/kendo-react-dropdowns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useReduxDispatch } from 'src/store';
import { selectActiveLocation } from 'src/store/locations/locations.selectors';
import {
  fetchProjects,
  setActiveProject
} from 'src/store/projects/projects.actions';
import {
  selectActiveProject,
  selectProjects
} from 'src/store/projects/projects.selectors';

type Props = {
  className?: string;
};

export const ProjectSelector: React.FC<Props> = props => {
  const [projectsFilter, setProjectsFilter] = useState<any>(null);

  const { className } = props;

  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const activeLocation = useSelector((state: RootState) =>
    selectActiveLocation(state.locations)
  );

  const projects = useSelector((state: RootState) =>
    selectProjects(state.projects)
  );

  const activeProject = useSelector((state: RootState) =>
    selectActiveProject(state.projects)
  );

  useEffect(() => {
    if (activeLocation) dispatch(fetchProjects(activeLocation.id));
  }, [dispatch, activeLocation]);

  const onProjectChange = (event: ComboBoxChangeEvent) => {
    dispatch(setActiveProject(event.value));
  };

  const displayName = (li: any, itemProps: ListItemProps) => {
    const { no, name } = itemProps.dataItem;
    const itemChildren = (
      <strong style={{ color: 'var(--primary-color)' }}>
        {no} - {name}
      </strong>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <ComboBox
      placeholder={`${t('select')} ${t('project.singular')}`}
      dataItemKey="id"
      textField="name"
      suggest
      filterable
      onFilterChange={event => {
        setProjectsFilter(event.filter);
      }}
      data={filterBy(projects, projectsFilter)}
      value={activeProject}
      onChange={onProjectChange}
      itemRender={displayName}
      className={className ? className : ''}
    />
  );
};
