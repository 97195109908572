import { UserDetailDto, UserEditDto } from './user';

export const mapUserDetailToEdit = (dto: UserDetailDto): UserEditDto => ({
  id: dto.id,
  firstname: dto.firstname,
  lastname: dto.lastname,
  username: dto.username,
  email: dto.email,
  lastLogin: dto.lastLogin,
  allLocations: dto.allLocations,
  twoFactorEnabled: dto.twoFactorEnabled,
  locationId: dto.locationId,
  password: dto.password,
  roles: dto.roles,
});
