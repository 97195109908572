import { sortBy } from 'lodash';
import { FilesState } from './files.reducer';

export const selectFile = (state: FilesState) => state.file;
export const selectFiles = (state: FilesState) => state.files;
export const selectBase64pdf = (state: FilesState) => state.base64pdf;
export const selectFileErrors = (state: FilesState) => state.errors;
export const selectFilesByType = (fileTypeId: number, state: FilesState) =>
  sortBy(
    state.files.filter(f => f.fileTypeId == fileTypeId),
    'filename'
  );
