import { UserRole } from '../users/user';
import { AuthState } from './auth.reducer';

export const selectCurrentUser = (state: AuthState) => state.user;
export const selectOidcUser = (state: AuthState) => state.oidcUser;
export const isAuthenticating = (state: AuthState) => state.isAuthenticating;
export const isAuthenticated = (state: AuthState) => state.isAuthenticated;
export const isAuthValidated = (state: AuthState) => state.authValidated;
export const selectAuthError = (state: AuthState) => state.error;
export const isUserAdmin = (state: AuthState) =>
  state.user && state.user.roles.includes(UserRole.Admin);
