import React from 'react';
import { Route, Switch } from 'react-router';
import PrivateRoute from 'src/components/pages/Auth/PrivateRoute';
import SilentRefresh from 'src/components/pages/Auth/SilentRefresh';
import NotFound from 'src/components/pages/Error/NotFound';
import Unauthorized from 'src/components/pages/Error/Unauthorized';
import { DashboardPage } from './components/pages/Dashboard/DashboardPage';
import { FilesPage } from './components/pages/Files/FilesPage';
import { FileTypesPage } from './components/pages/FileTypes/FileTypesPage';
import { LabelsPage } from './components/pages/Labels/LabelsPage';
import { LocationsPage } from './components/pages/Locations/LocationsPage';
import { ProjectPage } from './components/pages/Projects/ProjectPage/ProjectPage';
import { ProjectsPage } from './components/pages/Projects/ProjectsPage';
import PurchaseOrderForm from './components/pages/PurchaseOrders/PurchaseOrderForm';
import { PurchaseOrdersPage } from './components/pages/PurchaseOrders/PurchaseOrdersPage';
import { SettlementPage } from './components/pages/Settlements/SettlementPage/SettlementPage';
import { SettlementsPage } from './components/pages/Settlements/SettlementsPage';
import { SuppliersPage } from './components/pages/Suppliers/SuppliersPage';
import { UsersPage } from './components/pages/Users/UsersPage';
import { WaybillsPage } from './components/pages/Waybills/WaybillsPage';
import { UserRole } from './store/users/user';

export default (
  <Switch>
    <PrivateRoute exact path="/" component={DashboardPage} />
    <PrivateRoute exact path="/locations" component={LocationsPage} />
    <PrivateRoute exact path="/projects" component={ProjectsPage} />
    <PrivateRoute exact path="/projects/:id" component={ProjectPage} />
    <PrivateRoute exact path="/settlements" component={SettlementsPage} />
    <PrivateRoute exact path="/settlements/:id" component={SettlementPage} />
    <PrivateRoute exact path="/labels" component={LabelsPage} />
    <PrivateRoute exact path="/waybills" component={WaybillsPage} />
    <PrivateRoute exact path="/purchaseorders" component={PurchaseOrdersPage} />
    <PrivateRoute
      exact
      path="/purchaseorders/:id"
      component={PurchaseOrderForm}
    />
    <PrivateRoute exact path="/suppliers" component={SuppliersPage} />
    <PrivateRoute exact path="/files" component={FilesPage} />
    <PrivateRoute exact path="/filetypes" component={FileTypesPage} />
    <PrivateRoute
      exact
      path="/users"
      roles={[UserRole.Admin]}
      component={UsersPage}
    />
    <PrivateRoute exact path="/notfound" component={NotFound} />
    <Route exact path="/unauthorized" component={Unauthorized} />
    <PrivateRoute exact path="/silent-refresh" component={SilentRefresh} />
  </Switch>
);
