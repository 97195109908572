import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { configureStore, persistState } from 'src/store/index';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from 'src/components/pages/Error/ErrorBoundary';
import throttle from 'lodash.throttle';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './assets/theme';
import { initializeI18n } from './i18n';

import 'izitoast/dist/css/iziToast.min.css';
import 'src/assets/styles/scss/font-face.scss';
import 'src/assets/styles/scss/index.scss';
import 'normalize.css/normalize.css';
import { Application } from './App';

import { initialState as initialLocationsState } from 'src/store/locations/locations.reducer';
import { initialState as initialProjectsState } from 'src/store/projects/projects.reducer';
import { initialState as initialUsersState } from 'src/store/users/users.reducer';

export const history = createBrowserHistory({ basename: '/' });
const store = configureStore(history);

initializeI18n();

store.subscribe(
  throttle(() => {
    persistState({
      locations: {
        ...initialLocationsState,
        activeLocation: store.getState().locations.activeLocation,
      },
      projects: {
        ...initialProjectsState,
        activeProject: store.getState().projects.activeProject,
      },
      users: {
        ...initialUsersState,
        language: store.getState().users.language,
      },
    });
  }, 10000)
);

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Application history={history} />
      </ThemeProvider>
    </ErrorBoundary>
  </Provider>,

  document.getElementById('conpro-container')
);
serviceWorker.unregister();
