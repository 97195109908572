import { Card, CardActions, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { media } from 'src/assets/_responsive-mixin';
import Auxilary from 'src/components/hoc/Auxilary';
import { PrimaryButton } from 'src/components/shared/Button/Button';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { Header3 } from 'src/components/shared/Typography/Header3';
import { Header4 } from 'src/components/shared/Typography/Header4';
import { RootState, useReduxDispatch } from 'src/store';
import { LocationDto } from 'src/store/locations/location';
import { setActiveLocation } from 'src/store/locations/locations.actions';
import {
  selectActiveLocation,
  selectLocations,
} from 'src/store/locations/locations.selectors';
import { setActiveProject } from 'src/store/projects/projects.actions';
import styled from 'styled-components';

type Props = {};

export const LocationsPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const locations = useSelector((state: RootState) =>
    selectLocations(state.locations)
  );

  const activeLocation = useSelector((state: RootState) =>
    selectActiveLocation(state.locations)
  );

  return (
    <Auxilary>
      <Grid container className="cf-mb-8">
        <Grid item xs={12}>
          <Header1>{t('location.plural')}</Header1>
          <BodyType>{`${t('crudtext')} ${t('location.plural')}`}</BodyType>
        </Grid>
      </Grid>

      <LocationsGrid>
        {locations.map((location: LocationDto) => (
          <CustomCard key={location.id}>
            <CardContent>
              <Header3>{location.name}</Header3>
              <Header4>
                {location.projectCount} {t('project.plural')}
              </Header4>
            </CardContent>
            <CardActions disableSpacing className="flex jc-end">
              <PrimaryButton
                disabled={location.id === activeLocation?.id}
                onClick={() => {
                  dispatch(setActiveProject(null));
                  dispatch(setActiveLocation(location));
                }}
              >
                {t('select')}
              </PrimaryButton>
            </CardActions>
          </CustomCard>
        ))}
      </LocationsGrid>
    </Auxilary>
  );
};

const CustomCard = styled(Card)`
  max-width: none;
`;

const LocationsGrid = styled.section`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 1.5rem;
  margin: 2rem 0;

  ${media.large`
    grid-template-columns: 33% 33% 33%;
  `}

  ${media.medium`
    grid-template-columns: 50% 50%;
  `}

  ${media.xsmall`
    grid-template-columns: 100%;
  `}
`;
