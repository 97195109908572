import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useDidMount } from 'src/hooks/useDidMount';
import { RootState } from 'src/store';
import { startAuthentication, validateAuth } from 'src/store/auth/auth.actions';
import { selectCurrentUser } from 'src/store/auth/auth.selectors';

type Props = {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  authValidated: boolean;
  component: React.ComponentType<any>;
  validateAuth: () => Promise<any>;
  startAuthentication: () => Promise<any>;
  roles?: string[];
} & RouteProps;

const PrivateRoute: React.FC<Props> = props => {
  const {
    component: Component,
    isAuthenticated,
    authValidated,
    validateAuth,
    startAuthentication,
    roles,
    ...rest
  } = props;

  useDidMount(() => {
    if (!isAuthenticated) {
      validateAuth().then(result => {
        if (!result.user) {
          startAuthentication();
        }
      });
    }
  }, []);

  const user = useSelector((state: RootState) => selectCurrentUser(state.auth));

  const hasAccess = useMemo(() => {
    return user?.roles.some(r => roles?.includes(r)) || !roles;
  }, [user, roles]);

  return (
    <Route
      {...rest}
      render={() => {
        if (authValidated) {
          if (isAuthenticated) {
            return hasAccess ? (
              <Route {...rest} component={Component} />
            ) : (
              <Redirect to="/" />
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  isAuthenticating: state.auth.isAuthenticating,
  isAuthenticated: state.auth.isAuthenticated,
  authValidated: state.auth.authValidated,
  user: state.auth.user
});

export default connect(mapStateToProps, { validateAuth, startAuthentication })(
  PrivateRoute
);
