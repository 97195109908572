import { PurchaseOrdersState } from './purchaseorders.reducer';

export const selectPurchaseOrders = (state: PurchaseOrdersState) =>
  state.purchaseOrders;

export const selectPurchaseOrder = (state: PurchaseOrdersState) =>
  state.purchaseOrder;

export const selectPurchaseOrderDepartments = (state: PurchaseOrdersState) =>
  state.purchaseOrderDepartments;

export const selectPurchaseOrderStatuses = (state: PurchaseOrdersState) =>
  state.purchaseOrderStatuses;

export const selectPurchaseOrderErrors = (state: PurchaseOrdersState) =>
  state.errors;
