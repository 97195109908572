import React, { ForwardRefRenderFunction } from 'react';
import { media } from 'src/assets/_responsive-mixin';
import { MouldLabelDto } from 'src/store/moulds/mould';
import styled from 'styled-components';
import MouldLabel from './MouldLabel';

type Props = {
  mouldLabelData: MouldLabelDto;
};

const MouldLabelList: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  props,
  ref
) => {
  const { mouldLabelData } = props;
  return (
    <LabelList ref={ref}>
      {mouldLabelData.elements.map((element) => (
        <MouldLabel key={element.id} mould={mouldLabelData} element={element} />
      ))}
    </LabelList>
  );
};

export default React.forwardRef(MouldLabelList);

export const LabelList = styled.section`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1.5rem;

  ${media.large`
    grid-template-columns: 100%;
  `}
`;
