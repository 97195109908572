import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Auxilary from 'src/components/hoc/Auxilary';
import { PrimaryButton } from 'src/components/shared/Button/Button';
import {
  HeaderSection,
  HeaderSectionLeft,
  HeaderSectionRight
} from 'src/components/shared/HeaderSection/HeaderSection';
import { Modal } from 'src/components/shared/Modal/Modal';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { useDidMount } from 'src/hooks/useDidMount';
import { RootState, useReduxDispatch } from 'src/store';
import { fetchLocations } from 'src/store/locations/locations.actions';
import { selectLocations } from 'src/store/locations/locations.selectors';
import { isLoadingUsers } from 'src/store/requests/requests.selectors';
import { UserDetailDto, UserDto } from 'src/store/users/user';
import { mapUserDetailToEdit } from 'src/store/users/user.map';
import {
  createUser,
  editUser,
  fetchRoles,
  fetchUser,
  fetchUsers
} from 'src/store/users/users.actions';
import {
  selectRoles,
  selectUser,
  selectUsers
} from 'src/store/users/users.selectors';
import { ArrayUtils } from 'src/utils/array';
import UserForm from './UserForm';
import { UsersList } from './UsersList';

type Props = {};

export const UsersPage: React.FC<Props> = () => {
  const [currentUser, setCurrentUser] = useState<UserDto | null>(null);
  const [user, setUser] = useState<Nullable<UserDetailDto>>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { t } = useTranslation();

  const dispatch = useReduxDispatch();

  const propUser = useSelector((state: RootState) => selectUser(state.users));

  const users = useSelector((state: RootState) =>
    ArrayUtils.sortByProperty(selectUsers(state.users), 'username')
  );

  const roles = useSelector((state: RootState) => selectRoles(state.users));

  const locations = useSelector((state: RootState) =>
    selectLocations(state.locations)
  );

  const loadingUsers = useSelector(isLoadingUsers);

  useDidMount(() => {
    dispatch(fetchUsers());
    dispatch(fetchRoles());
    dispatch(fetchLocations());
  }, []);

  useEffect(() => {
    if (currentUser) {
      setShowDeleteModal(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (propUser) setUser(propUser);
  }, [propUser]);

  const onCancel = () => {
    setShowDeleteModal(false);
    setShowEditModal(false);
    setCurrentUser(null);
    setUser(null);
  };

  const onCreate = () => {
    setUser(null);
    setShowEditModal(true);
  };

  const onEdit = async (user: UserDto) => {
    await dispatch(fetchUser(user.id!));
    setShowEditModal(true);
  };

  const onDelete = (user: UserDto) => {
    setCurrentUser(user);
  };

  const onConfirmDelete = () => {
    onCancel();
  };

  const onSubmit = async (user: UserDetailDto) => {
    const dto = mapUserDetailToEdit(user);
    if (user.id) {
      await dispatch(editUser(user.id, dto));
    } else {
      await dispatch(createUser(dto));
    }
    dispatch(fetchUsers());
    onCancel();
  };

  return (
    <Auxilary>
      <HeaderSection className="cf-mb-8">
        <HeaderSectionLeft>
          <Header1>{t('user.plural')}</Header1>
          <BodyType>{`${t('crudtext')} ${t('user.plural')}`}</BodyType>
        </HeaderSectionLeft>
        <HeaderSectionRight>
          <PrimaryButton onClick={onCreate}>
            {`${t('create')} ${t('user.singular')}`}
          </PrimaryButton>
        </HeaderSectionRight>
      </HeaderSection>

      <UsersList
        users={users}
        loading={loadingUsers}
        onEdit={onEdit}
        onDelete={onDelete}
      />

      <Modal
        header={`${t('create')} ${t('user.singular')}`}
        onClose={() => onCancel()}
        open={showEditModal}
      >
        <UserForm
          user={user}
          roles={roles}
          locations={locations}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Modal>

      <Modal
        width={500}
        header={`${t('delete')} ${t('user.singular')}`}
        confirmText="Slett"
        onClose={() => onCancel()}
        onConfirm={() => onConfirmDelete()}
        open={showDeleteModal}
        showActions
      >
        <BodyType>
          {t('user.deleteConfirm')} <q>{currentUser?.username}</q>?
        </BodyType>
      </Modal>
    </Auxilary>
  );
};
