import React from 'react';
import styled from 'styled-components';
import { rotator, dash } from './Loader';

type Props = {};

export const PageLoader: React.FC<Props> = () => {
  return (
    <Section>
      <Svg
        style={{
          width: 50,
          height: 50
        }}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Circle
          style={{
            stroke: 'var(--primary-color)',
            strokeWidth: 4
          }}
          fill="none"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </Svg>
    </Section>
  );
};

const Section = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(#fff, 0.8);
`;

const Svg = styled.svg`
  animation: ${rotator} 1.4s linear infinite;
`;

const Circle = styled.circle`
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} 1.4s ease-in-out infinite;
`;
