import styled from 'styled-components';

export const LeadType = styled.span<{ color?: string; block?: boolean }>`
  font-family: 'Roboto';
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: ${props => (props.color ? props.color : 'var(--primary-color)')};
  display: ${props => (props.block ? 'block' : 'inline')};
`;
