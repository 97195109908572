import styled from 'styled-components';

export const Header4 = styled.h4`
  font-family: 'Open Sans';
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: inherit;
  margin-bottom: 0rem;
`;
