import styled from 'styled-components';

export const FormField = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

export const FormLabel = styled.label`
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  left: 0;
  bottom: -16px;
  font-size: 12px;
  color: var(--red-status-color);
`;
