import React from 'react';
import { LeadType } from '../Typography/LeadType';
import { SmallType } from '../Typography/SmallType';

interface Props {
  count: number;
  label: string;
}

export const GridCountCell: React.FC<Props> = props => {
  const { count, label } = props;
  return (
    <td colSpan={100}>
      <LeadType className="mr-8">{count}</LeadType>
      <SmallType>{label}</SmallType>
    </td>
  );
};
