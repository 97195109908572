import { LocationDto } from 'src/store/locations/location';
import { LocationAction, LocationTypes } from './locations.actions';

export type LocationsState = Readonly<{
  locations: LocationDto[];
  activeLocation: Nullable<LocationDto>;
}>;

export const initialState: LocationsState = {
  locations: [],
  activeLocation: null
};

const locationsReducer = (
  state: LocationsState = initialState,
  action: LocationAction
) => {
  switch (action.type) {
    case LocationTypes.FetchAll:
      return { ...state, locations: action.locations };
    case LocationTypes.SetActive:
      return { ...state, activeLocation: action.location };
    default:
      return state;
  }
};

export default locationsReducer;
