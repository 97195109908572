import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel
} from '@material-ui/core';
import React from 'react';

interface Props {
  label?: string;
  errorMessage?: string;
  displayError?: boolean;
  disabled?: boolean;
}

export const Checkbox = React.forwardRef<
  HTMLInputElement,
  Props & CheckboxProps
>((props, ref) => {
  const { label, id, disabled, className, ...rest } = props;
  return (
    <FormControlLabel
      className={className ?? ''}
      disabled={disabled}
      id={id}
      label={label}
      ref={ref}
      control={<MuiCheckbox {...rest} />}
    />
  );
});

Checkbox.defaultProps = {
  disabled: false
};
