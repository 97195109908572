import React from 'react';
import styled from 'styled-components';
import { TextField, TextFieldProps, InputAdornment } from '@material-ui/core';

interface Props {
  icon?: string;
  unit?: string;
  errorMessage?: string;
  displayError?: boolean;
  disabled?: boolean;
}

const CFInput: React.FC<Props & TextFieldProps> = props => {
  const {
    id,
    type,
    name,
    label,
    value,
    unit,
    errorMessage,
    displayError,
    onChange,
    onBlur,
    disabled,
    icon
  } = props;
  return (
    <InputField
      id={id}
      name={name}
      type={type}
      disabled={disabled}
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      error={displayError && errorMessage !== undefined}
      helperText={displayError && errorMessage}
      InputProps={{
        ...(icon || unit
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  {icon && <Adornment className={`${icon} fa-lg`} />}
                  {unit && <Adornment>{unit}</Adornment>}
                </InputAdornment>
              )
            }
          : {})
      }}
    />
  );
};

export default CFInput;

CFInput.defaultProps = {
  type: 'text',
  disabled: false
};

const InputField = styled(TextField)`
  width: 100%;
`;

const Adornment = styled.i`
  color: #9098a9;
`;
