import { createMuiTheme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

export const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      light: '#6d9c99',
      main: '#38898c',
      dark: '#2b656a'
    }
  },
  props: {
    MuiGrid: {
      spacing: 4
    },
    MuiInputBase: {
      inputProps: {
        style: {
          padding: '8px 0 8px'
        }
      }
    },
    MuiCard: {
      style: {
        padding: '16px'
      }
    },
    MuiTabs: {
      style: {
        borderBottom: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#ddd'
      }
    },
    MuiTextField: {
      style: {
        marginBottom: '1rem'
      }
    },
    MuiFormHelperText: {
      style: {
        position: 'absolute',
        top: '48px'
      }
    },
    MuiFormControlLabel: {
      style: {
        whiteSpace: 'nowrap'
      }
    },
    MuiDrawer: {
      PaperProps: {
        style: {
          width: 500,
          padding: 24
        }
      }
    }
  }
});
