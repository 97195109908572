import { SupplierDetailDto, SupplierDto } from 'src/store/suppliers/supplier';
import { ArrayUtils } from 'src/utils/array';
import { SupplierAction, SupplierTypes } from './suppliers.actions';

export type SuppliersState = Readonly<{
  suppliers: SupplierDto[];
  supplier: Nullable<SupplierDetailDto>;
  errors: any;
}>;

const initialState: SuppliersState = {
  suppliers: [],
  supplier: null,
  errors: null,
};

const suppliersReducer = (
  state: SuppliersState = initialState,
  action: SupplierAction
) => {
  switch (action.type) {
    case SupplierTypes.Fetch:
      return { ...state, supplier: action.supplier };
    case SupplierTypes.FetchAll:
      return { ...state, suppliers: action.suppliers };
    case SupplierTypes.Update:
      return {
        ...state,
        suppliers: ArrayUtils.updateListById(state.suppliers, action.supplier),
      };
    case SupplierTypes.Delete:
      return {
        ...state,
        supplier: null,
        suppliers: ArrayUtils.deleteFromListById(
          state.suppliers,
          action.supplier
        ),
      };
    case SupplierTypes.Failure:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
};

export default suppliersReducer;
