import { useEffect, useRef } from 'react';

export const useDidMount = (func: any, deps: any[]) => {
  const didMount = useRef<boolean>(false);

  useEffect(() => {
    if (!didMount.current) {
      func();
    }
    didMount.current = true;
    // eslint-disable-next-line
  }, deps);
};
