import {
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
  SortDescriptor
} from '@progress/kendo-data-query';
import {
  Grid,
  GridItemChangeEvent,
  GridProps
} from '@progress/kendo-react-grid';
import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { LoadingPanel } from '../LoadingPanel';

type Props = {
  items: any[];
  onItemChange?: (event: GridItemChangeEvent) => void;
  onItemsChange?: (items: any[]) => void;
  fullEditMode?: boolean;
  loading?: boolean;
  children: ReactNode;
};

export const EditableGrid: React.FC<Props & GridProps> = props => {
  const {
    onItemsChange,
    onFilterChange,
    children,
    loading,
    sortable,
    filterable,
    fullEditMode,
    ...rest
  } = props;

  const [sort, setSort] = useState<SortDescriptor[]>([]);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>({
    logic: 'or',
    filters: []
  });

  const [editField, setEditField] = useState<any>(undefined);
  const [items, setItems] = useState<any[]>([]);

  const getItems = (items: any[]) => {
    return filterBy(orderBy(items, sort), filter);
  };

  const enterEdit = (dataItem: any, field: any) => {
    if (dataItem.inEdit && field === editField) {
      return;
    }
    if (!fullEditMode) {
      setItems(
        items.map(item => ({
          ...item,
          inEdit: item.id === dataItem.id ? true : undefined
        }))
      );
    }

    setEditField(field);
  };

  useEffect(() => {
    if (fullEditMode) {
      setItems(props.items.map(item => Object.assign({ inEdit: true }, item)));
    } else {
      setItems(props.items);
    }
  }, [props.items, fullEditMode]);

  const cellRender = (tdElement: any, cellProps: any) => {
    const dataItem = cellProps.dataItem;
    const field = cellProps.field;
    const additionalProps =
      cellProps.dataItem['inEdit'] &&
      cellProps.field === cellProps.dataItem['inEdit']
        ? {
            ref: (td: HTMLTableCellElement) => {
              const input = td && td.querySelector('input');
              if (!input || input === document.activeElement) {
                return;
              }
              input.select();
            }
          }
        : {
            onClick: () => {
              enterEdit(dataItem, field);
            }
          };
    return React.cloneElement(
      tdElement,
      { ...tdElement.props, ...additionalProps },
      tdElement.props.children
    );
  };

  const onItemChange = (event: GridItemChangeEvent) => {
    event.dataItem[event.field!] = event.value;
    const newItems = items.map(item => {
      if (event.dataItem.id === item.id) {
        return event.dataItem;
      }
      return item;
    });
    onItemsChange && onItemsChange(newItems);
    setItems(newItems);
  };

  return (
    <Fragment>
      <Grid
        data={getItems(items)}
        total={items.length}
        sortable={sortable}
        sort={sort}
        filterable={filterable}
        filter={filter}
        onSortChange={event => {
          setSort(event.sort);
        }}
        onFilterChange={event => {
          setFilter(event.filter);
          onFilterChange && onFilterChange(event);
        }}
        onItemChange={props.onItemChange ?? onItemChange}
        cellRender={props.cellRender ?? cellRender}
        rowRender={props.rowRender}
        rowHeight={50}
        editField="inEdit"
        {...rest}
      >
        {children}
      </Grid>
      {loading && <LoadingPanel />}
    </Fragment>
  );
};

EditableGrid.defaultProps = {
  sortable: true,
  filterable: false,
  fullEditMode: true
};
