import React from 'react';
import { userManager } from 'src/store/auth/auth.actions';

const SilentRefresh: React.FC = () => {
  React.useEffect(() => {
    userManager.signinSilentCallback().catch((err: Error) => {
      console.log(err);
    });
  }, []);

  return <div />;
};

export default SilentRefresh;
