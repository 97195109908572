import Grid from '@material-ui/core/Grid';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Auxilary from 'src/components/hoc/Auxilary';
import { PrimaryButton } from 'src/components/shared/Button/Button';
import {
  HeaderSection,
  HeaderSectionLeft,
  HeaderSectionRight,
} from 'src/components/shared/HeaderSection/HeaderSection';
import { Modal } from 'src/components/shared/Modal/Modal';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { RootState, useReduxDispatch } from 'src/store';
import { alertSuccess } from 'src/store/alerts/alerts.actions';
import { selectActiveLocation } from 'src/store/locations/locations.selectors';
import { PurchaseOrderDetailDto } from 'src/store/purchaseorders/purchaseorder';
import {
  deletePurchaseOrder,
  fetchPurchaseOrders,
  setPurchaseOrder,
} from 'src/store/purchaseorders/purchaseorders.actions';
import { selectPurchaseOrders } from 'src/store/purchaseorders/purchaseorders.selectors';
import { isLoadingPurchaseOrders } from 'src/store/requests/requests.selectors';
import { PurchaseOrdersList } from './PurchaseOrdersList';

type Props = {
  history: History;
};

export const PurchaseOrdersPage: React.FC<Props> = (props) => {
  const { history } = props;

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentPo, setCurrentPo] = useState<Nullable<PurchaseOrderDetailDto>>(
    null
  );

  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const purchaseOrders = useSelector((state: RootState) =>
    selectPurchaseOrders(state.purchaseOrders)
  );

  const location = useSelector((state: RootState) =>
    selectActiveLocation(state.locations)
  );

  const loadingPurchaseOrders = useSelector(isLoadingPurchaseOrders);

  useEffect(() => {
    location != null
      ? dispatch(fetchPurchaseOrders(location.id))
      : dispatch(fetchPurchaseOrders(0));
  }, [dispatch, location]);

  useEffect(() => {
    if (currentPo) {
      setShowDeleteModal(true);
    }
  }, [currentPo]);

  const onCreate = () => {
    history.push('/purchaseorders/new');
  };

  const onEdit = (po: PurchaseOrderDetailDto) => {
    dispatch(setPurchaseOrder(po));
    history.push(`/purchaseorders/${po.id}`);
  };

  const onCancel = () => {
    setShowDeleteModal(false);
    setCurrentPo(null);
  };

  const onDelete = (po: PurchaseOrderDetailDto) => {
    setCurrentPo(po);
  };

  const onConfirmDelete = async () => {
    if (currentPo) {
      await dispatch(deletePurchaseOrder(currentPo.id, currentPo));
      dispatch(alertSuccess(t('purchaseorder.deleted')));
      dispatch(fetchPurchaseOrders(location!.id));
      setCurrentPo(null);
      setShowDeleteModal(false);
    }
  };

  return (
    <Auxilary>
      {location ? (
        <Auxilary>
          <HeaderSection className="cf-mb-8">
            <HeaderSectionLeft>
              <Header1>{t('purchaseorder.plural')}</Header1>
              <BodyType>
                {`${t('crudtext')} ${t('purchaseorder.plural')}`}
              </BodyType>
            </HeaderSectionLeft>
            <HeaderSectionRight>
              <PrimaryButton onClick={onCreate}>
                {`${t('create')} ${t('purchaseorder.singular')}`}
              </PrimaryButton>
            </HeaderSectionRight>
          </HeaderSection>
          <PurchaseOrdersList
            purchaseOrders={purchaseOrders}
            loading={loadingPurchaseOrders}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Auxilary>
      ) : (
        <Grid container className="cf-mb-8">
          <Grid item xs={6}>
            <Header1>{`${t('select')} ${t('location.singular')}`}</Header1>
          </Grid>
        </Grid>
      )}
      <Modal
        width={500}
        header={`${t('delete')} ${t('purchaseorder.singular')}`}
        confirmText={t('delete')}
        onClose={() => onCancel()}
        onConfirm={() => onConfirmDelete()}
        open={showDeleteModal}
        showActions
      >
        <BodyType>
          {t('purchaseorder.deleteConfirm')} <q>{currentPo?.no}</q>?
        </BodyType>
      </Modal>
    </Auxilary>
  );
};
