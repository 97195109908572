import { Box, Card, CardContent, Grid } from '@material-ui/core';
import { History } from 'history';
import { groupBy } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { RootState, useReduxDispatch } from 'src/store';
import { selectActiveLocation } from 'src/store/locations/locations.selectors';
import { fetchPurchaseOrders } from 'src/store/purchaseorders/purchaseorders.actions';
import { selectPurchaseOrders } from 'src/store/purchaseorders/purchaseorders.selectors';
import styled from 'styled-components';

type Props = {
  history: History;
};

export const DashboardPage: React.FC<Props> = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const activeLocation = useSelector((state: RootState) =>
    selectActiveLocation(state.locations)
  );

  const purchaseOrders = useSelector((state: RootState) =>
    selectPurchaseOrders(state.purchaseOrders)
  );

  const poStatus = groupBy(purchaseOrders, po => po.purchaseOrderStatus.name);

  useEffect(() => {
    if (activeLocation) dispatch(fetchPurchaseOrders(activeLocation.id));
  }, [dispatch, activeLocation]);

  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={4}>
        <DashboardCard
          onClick={() => history.push('/purchaseorders')}
          className="cf-mt-12"
        >
          <CardHeader>
            <Header2>
              <i className="las la-shopping-cart cf-mr-4" />
              {t('purchaseorder.plural')}
            </Header2>
            <i className="las la-arrow-right" />
          </CardHeader>

          <CardContent className="cf-p-0">
            <Box display="flex">
              <CardDetails>
                <span>{poStatus.Bestilles?.length || 0}</span>
                <span>Bestilles</span>
              </CardDetails>
              <CardDetails>
                <span>{poStatus.Bestilt?.length || 0}</span>
                <span>Bestilt</span>
              </CardDetails>
              <CardDetails>
                <span>{poStatus.Levert?.length || 0}</span>
                <span>Levert</span>
              </CardDetails>
            </Box>
          </CardContent>
        </DashboardCard>
      </Grid>
    </Grid>
  );
};

const DashboardCard = styled(Card)`
  color: var(--green-dark);
  cursor: pointer;

  :hover {
    box-shadow: 0px 0px 40px rgb(209, 210, 212);
  }

  :hover i {
    margin-right: 0;
  }
`;

const CardHeader = styled.div`
  color: var(--green-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    margin-right: 8px;
    transition: margin-right 0.5s;
  }
`;

const CardDetails = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  padding: 20px;
  color: var(--green-darkest);

  :first-child {
    padding-left: 0;
  }

  :not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 1px;
    height: 40px;
    background: rgba(var(--green-rgb), 0.5);
  }
`;
