import { GridColumn as Column } from '@progress/kendo-react-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'src/components/shared/Button/Button';
import { DataGrid } from 'src/components/shared/DataGrid/DataGrid';
import { SettlementDto } from 'src/store/settlements/settlement';

type Props = {
  settlements: SettlementDto[];
  loadingSettlements: boolean;
  onEdit: (settlement: SettlementDto) => void;
  onDelete: (settlement: SettlementDto) => void;
};

export const SettlementsList: React.FC<Props> = props => {
  const { settlements, loadingSettlements, onEdit, onDelete } = props;

  const { t } = useTranslation();

  return (
    <DataGrid
      items={settlements}
      pageable
      pageSize={50}
      loading={loadingSettlements}
      onRowClick={({ dataItem }) => onEdit(dataItem)}
    >
      <Column field="no" title={t('props.number')} width="100px" />
      <Column field="name" title={t('props.name')} width="300px" />
      <Column
        field="transportArticleNo"
        title={t('article.number')}
        width="200px"
      />
      <Column
        field="transportArticleName"
        title={t('settlement.msgSettlementTransport')}
      />
      <Column field="weight" title={t('props.weight')} width="100px" />
      <Column
        field="weightDeviation"
        title={t('props.deviation')}
        width="100px"
      />
      <Column field="articleNo" title={t('article.number')} width="200px" />
      <Column
        field="articleName"
        title={t('settlement.msgSettlementArticles')}
      />
      <Column field="count" title={t('props.count')} width="100px" />
      <Column
        field="countDeviation"
        title={t('props.deviation')}
        width="100px"
      />
      <Column
        width="100px"
        filterable={false}
        cell={props => (
          <td>
            <ActionButton
              icon="la la-trash"
              onClick={() => onDelete(props.dataItem)}
            />
          </td>
        )}
      />
    </DataGrid>
  );
};
