import { SectionDto } from 'src/store/sections/section';
import { SectionAction, SectionTypes } from './sections.actions';

export type SectionsState = Readonly<{
  sections: SectionDto[];
}>;

const initialState: SectionsState = {
  sections: []
};

const sectionsReducer = (
  state: SectionsState = initialState,
  action: SectionAction
): SectionsState => {
  switch (action.type) {
    case SectionTypes.FetchAll:
      return { ...state, sections: action.sections };
    default:
      return state;
  }
};

export default sectionsReducer;
