import { Dispatch } from 'redux';
import { SectionDto } from 'src/store/sections/section';
import { getRequest } from 'src/store/api-handler';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum SectionTypes {
  FetchAll = 'sections/FetchAll'
}

export type SectionAction = {
  type: SectionTypes.FetchAll;
  sections: SectionDto[];
};

export const actionCreators = {
  fetchSections: (sections: SectionDto[]): SectionAction => ({
    type: SectionTypes.FetchAll,
    sections
  })
};

export const fetchSections = (floorId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(SectionTypes.FetchAll));
    const { data } = await getRequest(`/sections/floor/${floorId}`);
    dispatch(requestEnd(SectionTypes.FetchAll));
    return dispatch(actionCreators.fetchSections(data));
  };
};
