import izitoast from 'izitoast';
import { AlertAction, AlertTypes } from './alerts.actions';

export default function alertsReducer(state: any = {}, action: AlertAction) {
  switch (action.type) {
    case AlertTypes.Success:
      izitoast.success({ message: `${action.text}`, position: 'bottomRight' });
      return state;
    case AlertTypes.Error:
      izitoast.error({ message: `${action.text}`, position: 'bottomRight' });
      return state;
    case AlertTypes.Info:
      izitoast.info({ message: `${action.text}`, position: 'bottomRight' });
      return state;
    default:
      return state;
  }
}
