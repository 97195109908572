import {
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
  SortDescriptor
} from '@progress/kendo-data-query';
import { Grid, GridProps } from '@progress/kendo-react-grid';
import React, { Fragment, ReactNode, useState } from 'react';
import { LoadingPanel } from './LoadingPanel';

type Props = {
  items?: any[];
  loading?: boolean;
  children: ReactNode;
  pageSize?: number;
};

export const DataGrid: React.FC<Props & GridProps> = props => {
  const {
    items,
    data,
    onRowClick,
    onSortChange,
    onFilterChange,
    onPageChange,
    children,
    loading,
    sortable,
    filterable,
    pageable,
    pageSize,
    scrollable,
    ...rest
  } = props;

  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(pageSize || 20);
  const [sort, setSort] = useState<SortDescriptor[]>([]);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>({
    logic: 'or',
    filters: []
  });

  const getItems = (items: any[]) => {
    if (pageable || scrollable === 'virtual') {
      return filterBy(orderBy(items, sort), filter).slice(skip, take + skip);
    } else {
      return filterBy(orderBy(items, sort), filter);
    }
  };

  return (
    <Fragment>
      <Grid
        style={{
          maxHeight: '75vh'
        }}
        data={data ? data : getItems(items!)}
        total={items?.length}
        sortable={sortable}
        sort={sort}
        pageable={pageable}
        skip={skip}
        take={take}
        filterable={filterable}
        filter={filter}
        scrollable={scrollable}
        onSortChange={event => {
          setSort(event.sort);
          onSortChange && onSortChange(event);
        }}
        onPageChange={event => {
          setSkip(event.page.skip);
          setTake(event.page.take);
          onPageChange && onPageChange(event);
        }}
        onFilterChange={event => {
          setFilter(event.filter);
          onFilterChange && onFilterChange(event);
        }}
        onRowClick={onRowClick}
        {...rest}
      >
        {children}
      </Grid>
      {loading && <LoadingPanel />}
    </Fragment>
  );
};

DataGrid.defaultProps = {
  sortable: true,
  filterable: true,
  scrollable: 'scrollable'
};
