import { LocationDto } from '../locations/location';

export interface UserBaseDto {
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  lastLogin?: Date;
  allLocations: boolean;
  locationId: number;
}

export interface UserDto extends UserBaseDto {
  id: number;
  aspNetUserId: string;
  locationName: string;
}

export interface UserDetailDto extends UserBaseDto {
  id: number;
  aspNetUserId: string;
  twoFactorEnabled: boolean;
  location: LocationDto;
  password: string;
  roles: string[];
}

export interface UserEditDto extends UserBaseDto {
  id: number;
  twoFactorEnabled: boolean;
  password: string;
  roles: string[];
}

export interface Role {
  id: string;
  name: string;
}

export enum UserRole {
  Admin = 'admin',
  Basic = 'basic',
}
