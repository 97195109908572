import React from 'react';
import { format } from 'date-fns';

type CellProps = {
  dataItem: any;
  field?: string;
};

export const DateCell: React.FC<CellProps> = props => {
  const { dataItem, field } = props;
  return (
    <td>
      {field &&
        dataItem[field] &&
        format(new Date(dataItem[field]), 'dd.MM.yyyy')}
    </td>
  );
};

export const DateTimeCell: React.FC<CellProps> = props => {
  const { dataItem, field } = props;
  return (
    <td>
      {field &&
        dataItem[field] &&
        format(new Date(dataItem[field]), 'dd.MM.yyyy HH:mm:ss')}
    </td>
  );
};
