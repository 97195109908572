import { Grid } from '@material-ui/core';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Form, Formik } from 'formik';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  PrimaryButton,
  TertiaryButton
} from 'src/components/shared/Button/Button';
import {
  ErrorMessage,
  FormField,
  FormLabel
} from 'src/components/shared/Forms/FormHelpers';
import InputField from 'src/components/shared/Input/InputField';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { useDidMount } from 'src/hooks/useDidMount';
import { RootState } from 'src/store';
import { alertError, alertSuccess } from 'src/store/alerts/alerts.actions';
import { BuildingDto } from 'src/store/buildings/building';
import { fetchBuildings } from 'src/store/buildings/buildings.actions';
import { ElementTypeDto } from 'src/store/elements/element';
import { fetchElementTypes } from 'src/store/elements/elements.actions';
import { FloorDto } from 'src/store/floors/floor';
import { fetchFloors } from 'src/store/floors/floors.actions';
import { LocationDto } from 'src/store/locations/location';
import { ProductDto } from 'src/store/products/product';
import {
  fetchProduct,
  fetchProducts
} from 'src/store/products/products.actions';
import { ProjectDto } from 'src/store/projects/project';
import { fetchProjects } from 'src/store/projects/projects.actions';
import {
  PurchaseOrderDepartmentDto,
  PurchaseOrderDetailDto,
  PurchaseOrderEditDto,
  PurchaseOrderStatusDto
} from 'src/store/purchaseorders/purchaseorder';
import {
  createPurchaseOrder,
  editPurchaseOrder,
  fetchPurchaseOrder,
  fetchPurchaseOrderDepartments,
  fetchPurchaseOrderStatuses
} from 'src/store/purchaseorders/purchaseorders.actions';
import { SectionDto } from 'src/store/sections/section';
import { fetchSections } from 'src/store/sections/sections.actions';
import { SupplierDto } from 'src/store/suppliers/supplier';
import { fetchSuppliers } from 'src/store/suppliers/suppliers.actions';
import { UserDetailDto } from 'src/store/users/user';
import {
  required,
  runValidations,
  validationRunner
} from 'src/utils/validation';

type Props = {
  purchaseOrder: PurchaseOrderDetailDto;
  user: UserDetailDto;
  location: LocationDto;
  project: ProjectDto;
  projects: ProjectDto[];
  buildings: BuildingDto[];
  floors: FloorDto[];
  sections: SectionDto[];
  products: ProductDto[];
  product: ProductDto | null;
  elementTypes: ElementTypeDto[];
  purchaseOrderDepartments: PurchaseOrderDepartmentDto[];
  purchaseOrderStatuses: PurchaseOrderStatusDto[];
  suppliers: SupplierDto[];
  errors?: any;
  onCancel: () => any;
  onSubmit: (purchaseOrder: PurchaseOrderEditDto) => any;
  fetchPurchaseOrder: (id: number) => Promise<any>;
  fetchSuppliers: () => Promise<any>;
  fetchElementTypes: () => Promise<any>;
  fetchProducts: () => Promise<any>;
  fetchProduct: (id: number) => Promise<any>;
  fetchBuildings: (productId: number) => Promise<any>;
  fetchFloors: (buildingId: number) => Promise<any>;
  fetchSections: (floorId: number) => Promise<any>;
  fetchProjects: (locationId: number) => Promise<any>;
  fetchPurchaseOrderDepartments: (locationId: number) => Promise<any>;
  fetchPurchaseOrderStatuses: () => Promise<any>;
  createPurchaseOrder: (order: PurchaseOrderEditDto) => Promise<any>;
  editPurchaseOrder: (id: number, po: PurchaseOrderEditDto) => Promise<any>;
  alertSuccess: (text: string) => any;
  alertError: (text: string) => any;
  match: any;
  history: History;
};

const PurchaseOrderForm: React.FC<Props> = props => {
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(
    false
  );
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderDetailDto>(
    {} as PurchaseOrderDetailDto
  );

  const { t } = useTranslation();

  const {
    user,
    project,
    location,
    match,
    fetchPurchaseOrder,
    fetchSuppliers,
    fetchPurchaseOrderStatuses,
    fetchPurchaseOrderDepartments,
    fetchProduct,
    fetchProducts,
    fetchProjects,
    fetchBuildings,
    fetchFloors,
    fetchSections,
    fetchElementTypes
  } = props;

  useDidMount(() => {
    fetchSuppliers();
    fetchPurchaseOrderStatuses();
    fetchProducts();
    fetchElementTypes();
  }, []);

  useEffect(() => {
    const id = match.params.id;
    if (id !== 'new') {
      if (props.purchaseOrder) {
        setPurchaseOrder({
          ...props.purchaseOrder,
          supplyDate: new Date(props.purchaseOrder.supplyDate),
          deliveryDate: new Date(props.purchaseOrder.deliveryDate),
          deadline: new Date(props.purchaseOrder.deadline)
        });
      } else {
        fetchPurchaseOrder(id);
      }
    } else {
      if (project) {
        fetchBuildings(project.id);
        setPurchaseOrder({
          supplyDate: new Date(),
          deliveryDate: new Date(),
          deadline: new Date(),
          project
        } as PurchaseOrderDetailDto);
      }
    }
  }, [
    fetchPurchaseOrder,
    fetchBuildings,
    project,
    match.params.id,
    props.purchaseOrder
  ]);

  useEffect(() => {
    fetchProjects(location.id);
    fetchPurchaseOrderDepartments(location.id);
  }, [fetchProjects, fetchPurchaseOrderDepartments, location]);

  useEffect(() => {
    if (purchaseOrder.project?.id) fetchBuildings(purchaseOrder.project.id);
  }, [fetchBuildings, purchaseOrder.project]);

  useEffect(() => {
    if (purchaseOrder.building?.id) fetchFloors(purchaseOrder.building.id);
  }, [fetchFloors, purchaseOrder.building]);

  useEffect(() => {
    if (purchaseOrder.floor?.id) fetchSections(purchaseOrder.floor.id);
  }, [fetchSections, purchaseOrder.floor]);

  useEffect(() => {
    if (purchaseOrder.product?.id) fetchProduct(purchaseOrder.product.id);
  }, [fetchProduct, purchaseOrder.product]);

  const onProjectChanged = (
    event: ComboBoxChangeEvent,
    po: PurchaseOrderDetailDto
  ) => {
    setPurchaseOrder({
      ...po,
      project: event.value,
      building: null,
      floor: null,
      section: null
    });
  };

  const onBuildingChanged = (
    event: ComboBoxChangeEvent,
    po: PurchaseOrderDetailDto
  ) => {
    setPurchaseOrder({
      ...po,
      building: event.value,
      floor: null,
      section: null
    });
  };

  const onFloorChanged = (
    event: ComboBoxChangeEvent,
    po: PurchaseOrderDetailDto
  ) => {
    setPurchaseOrder({
      ...po,
      floor: event.value,
      section: null
    });
  };

  const onProductChanged = (
    event: ComboBoxChangeEvent,
    po: PurchaseOrderDetailDto
  ) => {
    setPurchaseOrder({
      ...po,
      product: event.value
    });
  };

  const onCancel = () => {
    props.history.push('/purchaseorders');
  };

  const onSubmit = async (order: PurchaseOrderDetailDto) => {
    const po: PurchaseOrderEditDto = {
      id: order.id,
      comment: order.comment,
      bendList: order.bendList,
      amount: order.amount,
      supplyDate: order.supplyDate,
      deliveryDate: order.deliveryDate,
      deadline: order.deadline,
      contactPerson: order.contactPerson,
      stockLocation: order.stockLocation,
      supplierId: order.supplier?.id,
      locationId: props.location.id,
      sectionId: order.section?.id as number,
      elementTypeId: order.elementType.id,
      productId: order.product.id,
      purchaseOrderDepartmentId: order.purchaseOrderDepartment.id,
      purchaseOrderStatusId: order.purchaseOrderStatus.id,
      userId: user.id!
    };

    try {
      if (po.id) {
        await props.editPurchaseOrder(po.id, po);
      } else {
        await props.createPurchaseOrder(po);
      }
      props.alertSuccess(
        `${po.id ? t('purchaseorder.updated') : t('purchaseorder.created')}`
      );
      props.history.push('/purchaseorders');
    } catch (err) {
      props.alertError(t('save.failed'));
    }
  };

  const validate = (values: PurchaseOrderDetailDto) => {
    const validationRunners = [
      validationRunner('supplier', t('supplier.singular'), required),
      validationRunner('section', t('section.singular'), required),
      validationRunner('elementType', t('elementtype.singular'), required),
      validationRunner('product', t('product.singular'), required),
      validationRunner('amount', t('props.amount'), required),
      validationRunner(
        'purchaseOrderDepartment',
        t('props.department'),
        required
      ),
      validationRunner('purchaseOrderStatus', t('props.status'), required)
    ];

    return runValidations(values, validationRunners);
  };

  const {
    elementTypes,
    projects,
    buildings,
    floors,
    sections,
    products,
    product,
    purchaseOrderDepartments,
    purchaseOrderStatuses,
    suppliers
  } = props;
  return (
    <Formik
      enableReinitialize
      initialValues={purchaseOrder}
      validate={validate}
      validateOnMount
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Header1 className="cf-mb-8">
                {purchaseOrder.id ? t('edit') : t('create')}{' '}
                {t('purchaseorder.singular')}
                {purchaseOrder.id && ` - ${purchaseOrder.no}`}
              </Header1>
            </Grid>
            <Grid item xs={12} md={6} className="flex align-center jc-end">
              <TertiaryButton
                onClick={onCancel}
                label={t('cancel')}
                className="mr-32"
              />
              <PrimaryButton
                type="submit"
                label={t('save.verb')}
                disabled={isSubmitting}
                onClick={() => {
                  setShowValidationErrors(true);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormField>
                <ComboBox
                  label={t('supplier.singular')}
                  name="supplier"
                  data={suppliers}
                  dataItemKey="id"
                  textField="name"
                  value={values.supplier}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.supplier && showValidationErrors && (
                  <ErrorMessage>{errors.supplier}</ErrorMessage>
                )}
              </FormField>

              <FormField>
                <ComboBox
                  label={t('project.singular')}
                  name="project"
                  data={projects}
                  dataItemKey="id"
                  textField="name"
                  value={values.project}
                  onChange={event => {
                    onProjectChanged(event, values);
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                />
              </FormField>

              <FormField>
                <ComboBox
                  disabled={!values.project}
                  label={t('building.singular')}
                  name="building"
                  data={buildings}
                  dataItemKey="id"
                  textField="name"
                  defaultValue={{ textField: 'Select building' }}
                  value={values.building}
                  onChange={event => {
                    onBuildingChanged(event, values);
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                />
              </FormField>

              <FormField>
                <ComboBox
                  disabled={!values.building}
                  label={t('floor.singular')}
                  name="floor"
                  data={floors}
                  dataItemKey="id"
                  textField="name"
                  value={values.floor}
                  onChange={event => {
                    onFloorChanged(event, values);
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <ComboBox
                  disabled={!values.floor}
                  label={t('section.singular')}
                  name="section"
                  data={sections}
                  dataItemKey="id"
                  textField="name"
                  value={values.section}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.section && showValidationErrors && (
                  <ErrorMessage>{errors.section}</ErrorMessage>
                )}
              </FormField>

              <FormField>
                <ComboBox
                  label={t('elementtype.singular')}
                  name="elementType"
                  data={elementTypes}
                  dataItemKey="id"
                  textField="name"
                  value={values.elementType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.elementType && showValidationErrors && (
                  <ErrorMessage>{errors.elementType}</ErrorMessage>
                )}
              </FormField>
              <FormField>
                <ComboBox
                  label={t('product.singular')}
                  name="product"
                  data={products}
                  dataItemKey="id"
                  textField="name"
                  value={values.product}
                  onChange={event => {
                    onProductChanged(event, values);
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                />
                {errors.product && showValidationErrors && (
                  <ErrorMessage>{errors.product}</ErrorMessage>
                )}
              </FormField>
              <InputField
                type="number"
                name="amount"
                unit={product?.unit}
                label={t('props.amount')}
                value={values.amount}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.amount}
                displayError={touched.amount}
              />
              <FormField>
                <ComboBox
                  label={t('props.department')}
                  name="purchaseOrderDepartment"
                  data={purchaseOrderDepartments}
                  dataItemKey="id"
                  textField="name"
                  value={values.purchaseOrderDepartment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.purchaseOrderDepartment && showValidationErrors && (
                  <ErrorMessage>{errors.purchaseOrderDepartment}</ErrorMessage>
                )}
              </FormField>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField>
                <ComboBox
                  label={t('props.status')}
                  name="purchaseOrderStatus"
                  data={purchaseOrderStatuses}
                  dataItemKey="id"
                  textField="name"
                  value={values.purchaseOrderStatus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.purchaseOrderStatus && showValidationErrors && (
                  <ErrorMessage>{errors.purchaseOrderStatus}</ErrorMessage>
                )}
              </FormField>
              <Grid container spacing={0} className="cf-mt-8">
                <Grid item xs={3}>
                  <FormLabel>{t('props.orderdate')}</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <DatePicker
                    name="supplyDate"
                    format="dd.MM.yyyy"
                    value={values.supplyDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} className="cf-mt-8">
                <Grid item xs={3}>
                  <FormLabel>{t('props.deliverydate')}</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <DatePicker
                    name="deliveryDate"
                    format="dd.MM.yyyy"
                    value={values.deliveryDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} className="cf-mt-8 cf-mb-4">
                <Grid item xs={3}>
                  <FormLabel>{t('props.deadline')}</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <DatePicker
                    name="deadline"
                    format="dd.MM.yyyy"
                    value={values.deadline}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              <InputField
                type="text"
                name="comment"
                label={t('props.comment')}
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.comment}
                displayError={touched.comment || showValidationErrors}
              />
              <InputField
                type="text"
                name="bendList"
                label={t('props.bendlist')}
                value={values.bendList}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.bendList}
                displayError={touched.bendList}
              />
              <InputField
                type="text"
                name="contactPerson"
                label={t('props.contactperson')}
                value={values.contactPerson}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.contactPerson}
                displayError={touched.contactPerson}
              />
              <InputField
                type="text"
                name="stockLocation"
                label={t('props.stockplacement')}
                value={values.stockLocation}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.stockLocation}
                displayError={touched.stockLocation}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  purchaseOrder: state.purchaseOrders.purchaseOrder,
  user: state.auth.user,
  location: state.locations.activeLocation,
  project: state.projects.activeProject,
  suppliers: state.suppliers.suppliers,
  projects: state.projects.projects,
  buildings: state.buildings.buildings,
  floors: state.floors.floors,
  sections: state.sections.sections,
  products: state.products.products,
  product: state.products.product,
  elementTypes: state.elements.elementTypes,
  purchaseOrderDepartments: state.purchaseOrders.purchaseOrderDepartments,
  purchaseOrderStatuses: state.purchaseOrders.purchaseOrderStatuses
});

export default connect(mapStateToProps, {
  fetchPurchaseOrder,
  fetchSuppliers,
  fetchProjects,
  fetchBuildings,
  fetchFloors,
  fetchSections,
  fetchProducts,
  fetchProduct,
  fetchElementTypes,
  fetchPurchaseOrderDepartments,
  fetchPurchaseOrderStatuses,
  createPurchaseOrder,
  editPurchaseOrder,
  alertSuccess,
  alertError
})(PurchaseOrderForm);
