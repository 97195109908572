import { History } from 'history';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { RootState, useReduxDispatch } from 'src/store';
import { selectActiveLocation } from 'src/store/locations/locations.selectors';
import {
  ProjectArticleDto,
  ProjectDetailDto
} from 'src/store/projects/project';
import { mapProjectDetailToEdit } from 'src/store/projects/project.map';
import {
  actionCreators,
  createProject,
  editProject,
  fetchProject,
  ProjectTypes
} from 'src/store/projects/projects.actions';
import { selectProject } from 'src/store/projects/projects.selectors';
import { ProjectDetailsForm } from './ProjectDetailsForm';

type Props = {
  history: History;
};

export const ProjectPage: React.FC<Props> = ({ history }) => {
  const [projectArticles, setProjectArticles] = useState<ProjectArticleDto[]>(
    []
  );

  const dispatch = useReduxDispatch();
  const route = useRouteMatch();

  const activeLocation = useSelector((state: RootState) =>
    selectActiveLocation(state.locations)
  );

  const project = useSelector((state: RootState) =>
    selectProject(state.projects)
  );

  useEffect(() => {
    const id = (route.params as { id: number | string }).id;
    if (id != 'create') {
      dispatch(fetchProject(id as number));
    }
  }, [route, dispatch]);

  const onArticlesChanged = useCallback((articles: ProjectArticleDto[]) => {
    setProjectArticles(articles);
  }, []);

  const onCancel = () => {
    dispatch(actionCreators.fetchProject(null));
    history.push('/projects');
  };

  const onSubmit = async (project: ProjectDetailDto) => {
    const dto = mapProjectDetailToEdit(project);
    dto.locationId = activeLocation!.id;
    dto.projectArticles = projectArticles;
    const result = dto.id
      ? await dispatch(editProject(dto.id, dto))
      : await dispatch(createProject(dto));
    if (result.type !== ProjectTypes.Failure) {
      onCancel();
    }
  };

  return (
    <Fragment>
      <ProjectDetailsForm
        project={project}
        onCancel={onCancel}
        onSubmit={onSubmit}
        onArticlesChanged={onArticlesChanged}
      />
    </Fragment>
  );
};
