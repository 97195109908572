import i18n from 'i18next';
import { Dispatch } from 'redux';
import {
  deleteRequest,
  getRequest,
  multipartPostRequest
} from 'src/store/api-handler';
import { FileDetailDto, FileDto, FileEditDto } from 'src/store/files/file';
import { alertError, alertSuccess } from '../alerts/alerts.actions';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum FileTypes {
  Fetch = 'files/Fetch',
  FetchAll = 'files/FetchAll',
  Show = 'files/Show',
  Update = 'files/Update',
  Delete = 'files/Delete',
  Failure = 'files/Failure'
}

export type FileAction =
  | { type: FileTypes.Fetch; file: Nullable<FileDetailDto> }
  | { type: FileTypes.FetchAll; files: FileDto[] }
  | { type: FileTypes.Show; base64pdf: string }
  | { type: FileTypes.Update; file: FileDto }
  | { type: FileTypes.Delete; file: FileDto }
  | { type: FileTypes.Failure; errors: any };

export const actionCreators = {
  fetchFile: (file: FileDetailDto): FileAction => ({
    type: FileTypes.Fetch,
    file: file
  }),
  fetchFiles: (files: FileDto[]): FileAction => ({
    type: FileTypes.FetchAll,
    files
  }),
  showFile: (base64pdf: string): FileAction => ({
    type: FileTypes.Show,
    base64pdf: base64pdf
  }),
  // updateFile: (file: FileDto): FileAction => ({
  //   type: FileTypes.Update,
  //   file
  // }),
  deleteFile: (file: FileDto): FileAction => ({
    type: FileTypes.Delete,
    file
  }),
  fileRequestFailure: (errors: any): FileAction => ({
    type: FileTypes.Failure,
    errors
  })
};

export const fetchFile = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await getRequest(`/files/${id}`);
    return ok
      ? dispatch(actionCreators.fetchFile(data))
      : dispatch(actionCreators.fileRequestFailure(error));
  };
};

export const fetchFiles = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FileTypes.FetchAll));
    const { data } = await getRequest('/files');
    dispatch(requestEnd(FileTypes.FetchAll));
    return dispatch(actionCreators.fetchFiles(data));
  };
};

export const showFile = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await getRequest(`/files/show/${id}`);
    return ok
      ? dispatch(actionCreators.showFile(data))
      : dispatch(actionCreators.fileRequestFailure(error));
  };
};

export const createFile = (file: FileEditDto, files: File[]) => {
  const formData = new FormData();
  formData.append('file', files[0]);
  return async (dispatch: Dispatch) => {
    const { ok, error } = await multipartPostRequest(
      `/files/${file.locationId}/${file.fileTypeId}`,
      formData
    );
    return ok
      ? dispatch(alertSuccess(i18n.t('file.created')))
      : dispatch(actionCreators.fileRequestFailure(error));
  };
};

export const deleteFile = (id: number, file: FileDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await deleteRequest(`/files/${id}`, file);
    if (ok) {
      dispatch(alertSuccess(i18n.t('file.deleted')));
      return dispatch(actionCreators.deleteFile(file));
    } else {
      dispatch(alertError(i18n.t('file.deleteFailed')));
      return dispatch(actionCreators.fileRequestFailure(error));
    }
  };
};
