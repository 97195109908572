import { Box, Grid } from '@material-ui/core';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ActionButton,
  SecondaryButton,
  TertiaryButton
} from 'src/components/shared/Button/Button';
import { DropdownCell } from 'src/components/shared/DataGrid/EditableGrid/DropdownCell';
import { EditableGrid } from 'src/components/shared/DataGrid/EditableGrid/EditableGrid';
import { RootState, useReduxDispatch } from 'src/store';
import { fetchArticlesByPricebook } from 'src/store/articles/articles.actions';
import { selectArticles } from 'src/store/articles/articles.selectors';
import {
  ProjectArticleDto,
  ProjectDetailDto
} from 'src/store/projects/project';

interface Props {
  project: Nullable<ProjectDetailDto>;
  onArticlesChanged: (parameters: ProjectArticleDto[]) => void;
}

let uniqueId = 0;

export const ProjectArticles: React.FC<Props> = ({
  project,
  onArticlesChanged
}) => {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const [articles, setArticles] = useState<ProjectArticleDto[]>(
    project?.projectArticles || []
  );

  const articles$ = useSelector((state: RootState) =>
    selectArticles(state.articles)
  );

  useEffect(() => {
    if (project) {
      setArticles(project.projectArticles ?? []);
      dispatch(fetchArticlesByPricebook(project.pricebookId));
    }
  }, [project, dispatch]);

  useEffect(() => {
    onArticlesChanged(articles);
  }, [articles, onArticlesChanged]);

  const onCancel = () => {
    setArticles([...(project?.projectArticles ?? [])]);
  };

  const onAddArticle = () => {
    const article: ProjectArticleDto = {
      id: --uniqueId,
      projectId: project?.id
    };
    setArticles([article, ...articles]);
  };

  const onDeleteArticle = (article: ProjectArticleDto) => {
    setArticles(articles.filter(a => a.id !== article.id));
  };

  return (
    <Fragment>
      <Grid container className="cf-mb-6">
        <Grid item>
          <Box display="flex" className="cf-mb-6">
            <SecondaryButton
              icon="la-plus"
              onClick={onAddArticle}
              className="cf-mr-6"
            >
              {t('add.verb')}
            </SecondaryButton>
            <TertiaryButton onClick={onCancel}>{t('undo')}</TertiaryButton>
          </Box>
          <EditableGrid
            items={articles}
            onItemsChange={items => {
              setArticles(items);
            }}
          >
            <Column
              field="articleId"
              title={t('article.number')}
              width="300px"
              cell={props => (
                <DropdownCell
                  {...props}
                  items={articles$}
                  uniqueField="id"
                  optionField="no"
                />
              )}
            />
            <Column
              field="articleId"
              title={t('article.singular')}
              width="700px"
              cell={props => (
                <DropdownCell
                  {...props}
                  items={articles$}
                  uniqueField="id"
                  optionField="name"
                />
              )}
            />
            <Column
              field="count"
              width={200}
              title={t('props.count')}
              editor="numeric"
            />
            <Column
              width="60px"
              filterable={false}
              editable={false}
              cell={props => (
                <td>
                  <ActionButton
                    icon="la la-trash"
                    onClick={() => {
                      onDeleteArticle(props.dataItem);
                    }}
                  />
                </td>
              )}
            />
          </EditableGrid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
