import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const slideUp = keyframes`
  0% {
    transform: translateY(-40%);
  }

  100% {
    transform: translateY(-50%);
  }
`;

export const shake = keyframes`
  0%,
  25%,
  83%,
  100% {
    transform: rotatez(0deg);
  }
  32.5%,
  62.5% {
    transform: rotatez(-5deg);
  }
  47.5%,
  75.5% {
    transform: rotatez(5deg);
  }
`;
