import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { useDispatch } from 'react-redux';
import {
  Action,
  applyMiddleware,
  combineReducers,
  compose,
  createStore
} from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import alertsReducer from 'src/store/alerts/alerts.reducer';
import authReducer from 'src/store/auth/auth.reducer';
import buildingsReducer from 'src/store/buildings/buildings.reducer';
import cargoesReducer from 'src/store/cargoes/cargoes.reducer';
import collectionsReducer from 'src/store/collections/collections.reducer';
import customersReducer from 'src/store/customers/customers.reducer';
import elementsReducer from 'src/store/elements/elements.reducer';
import floorsReducer from 'src/store/floors/floors.reducer';
import locationsReducer from 'src/store/locations/locations.reducer';
import mouldsReducer from 'src/store/moulds/moulds.reducer';
import productsReducer from 'src/store/products/products.reducer';
import projectsReducer from 'src/store/projects/projects.reducer';
import purchaseOrdersReducer from 'src/store/purchaseorders/purchaseorders.reducer';
import requestsReducer from 'src/store/requests/requests.reducer';
import sectionsReducer from 'src/store/sections/sections.reducer';
import suppliersReducer from 'src/store/suppliers/suppliers.reducer';
import usersReducer from 'src/store/users/users.reducer';
import articlesReducer from './articles/articles.reducer';
import filesReducer from './files/files.reducer';
import fileTypesReducer from './filetypes/filetypes.reducer';
import pricebooksReducer from './pricebooks/pricebooks.reducer';
import settlementsReducer from './settlements/settlements.reducer';

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    requests: requestsReducer,
    alerts: alertsReducer,
    users: usersReducer,
    locations: locationsReducer,
    projects: projectsReducer,
    buildings: buildingsReducer,
    floors: floorsReducer,
    sections: sectionsReducer,
    products: productsReducer,
    purchaseOrders: purchaseOrdersReducer,
    suppliers: suppliersReducer,
    collections: collectionsReducer,
    moulds: mouldsReducer,
    cargoes: cargoesReducer,
    elements: elementsReducer,
    customers: customersReducer,
    pricebooks: pricebooksReducer,
    articles: articlesReducer,
    settlements: settlementsReducer,
    fileTypes: fileTypesReducer,
    files: filesReducer
  });

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;

export type Store = { getState: () => RootState; dispatch: () => void };

export const configureStore = (history: History) => {
  // configure middlewares
  const middlewares = [routerMiddleware(history), thunkMiddleware];
  // compose enhancers
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  return createStore(rootReducer(history), recoverState(), enhancer);
};

export type ReduxDispatch = ThunkDispatch<Store, any, Action>;
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

const composeEnhancers =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

// systemjs-hot-reloader hook, rehydrating the state of redux store
export function __reload(exports: any) {
  console.log(exports.store.getState());
}

export const persistState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    console.log(error);
  }
};

const recoverState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return {} as RootState;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return {} as RootState;
  }
};
