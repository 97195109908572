import { FloorDto } from 'src/store/floors/floor';
import { FloorAction, FloorTypes } from './floors.actions';

export type FloorsState = Readonly<{
  floors: FloorDto[];
}>;

export const initialState: FloorsState = {
  floors: []
};

const floorsReducer = (
  state: FloorsState = initialState,
  action: FloorAction
) => {
  switch (action.type) {
    case FloorTypes.FetchAll:
      return { ...state, floors: action.floors };
    default:
      return state;
  }
};

export default floorsReducer;
