import {
  SettlementDetailDto,
  SettlementDto
} from 'src/store/settlements/settlement';
import { ArrayUtils } from 'src/utils/array';
import { SettlementAction, SettlementTypes } from './settlements.actions';

export type SettlementsState = Readonly<{
  activeSettlement: Nullable<SettlementDto>;
  settlement: Nullable<SettlementDetailDto>;
  settlements: SettlementDto[];
  errors: any;
}>;

export const initialState: SettlementsState = {
  activeSettlement: null,
  settlement: null,
  settlements: [],
  errors: null
};

const settlementsReducer = (
  state: SettlementsState = initialState,
  action: SettlementAction
): SettlementsState => {
  switch (action.type) {
    case SettlementTypes.Fetch:
      return { ...state, settlement: action.settlement };
    case SettlementTypes.FetchAll:
      return { ...state, settlements: action.settlements };
    case SettlementTypes.Delete:
      return {
        ...state,
        settlement: null,
        settlements: ArrayUtils.deleteFromListById(
          state.settlements,
          action.settlement
        )
      };
    case SettlementTypes.Failure:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
};

export default settlementsReducer;
