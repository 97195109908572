import { SupplierDetailDto, SupplierEditDto } from './supplier';

export const mapSupplierDetailToEdit = (
  dto: SupplierDetailDto
): SupplierEditDto => ({
  id: dto.id,
  no: dto.no,
  name: dto.name,
  phone: dto.phone,
  address1: dto.address1,
  address2: dto.address2,
  zip: dto.zip,
  city: dto.city,
  country: dto.country,
  comment: dto.comment,
});
