import { User } from 'oidc-client';
import React from 'react';

export type AuthContextProps = {
  user: Nullable<User>;
  isAuthenticated: boolean;
};

const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  isAuthenticated: false
});

export default AuthContext;
