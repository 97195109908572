import { Dispatch } from 'redux';
import { getRequest } from 'src/store/api-handler';
import { ArticleDto } from 'src/store/articles/article';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum ArticleTypes {
  FetchAll = 'articles/FetchAll',
  FetchByPricebook = 'articles/FetchByPricebook'
}

export type ArticleAction =
  | {
      type: ArticleTypes.FetchAll;
      articles: ArticleDto[];
    }
  | {
      type: ArticleTypes.FetchByPricebook;
      pricebookArticles: ArticleDto[];
    };

export const actionCreators = {
  fetchArticles: (articles: ArticleDto[]): ArticleAction => ({
    type: ArticleTypes.FetchAll,
    articles
  }),
  fetchArticlesByPricebook: (
    pricebookArticles: ArticleDto[]
  ): ArticleAction => ({
    type: ArticleTypes.FetchByPricebook,
    pricebookArticles
  })
};

export const fetchArticles = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(ArticleTypes.FetchAll));
    const { data } = await getRequest('/articles');
    dispatch(requestEnd(ArticleTypes.FetchAll));
    return dispatch(actionCreators.fetchArticles(data));
  };
};

export const fetchArticlesByPricebook = (pricebookId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(ArticleTypes.FetchByPricebook));
    const { data } = await getRequest(`/articles/pricebook/${pricebookId}`);
    dispatch(requestEnd(ArticleTypes.FetchByPricebook));
    return dispatch(actionCreators.fetchArticlesByPricebook(data));
  };
};
