import { DataResult } from '@progress/kendo-data-query';
import {
  CollectionDto,
  CollectionLabelDto,
  CollectionStatus
} from 'src/store/collections/collection';
import { CollectionAction, CollectionTypes } from './collections.actions';

export type CollectionsState = Readonly<{
  collection: Nullable<CollectionDto>;
  collections: CollectionDto[];
  filteredCollections: DataResult;
  collectionStatuses: CollectionStatus[];
  collectionLabelData: Nullable<CollectionLabelDto>;
}>;

export const initialState: CollectionsState = {
  collection: null,
  collections: [],
  filteredCollections: { data: [], total: 0 },
  collectionStatuses: [],
  collectionLabelData: null
};

const collectionsReducer = (
  state: CollectionsState = initialState,
  action: CollectionAction
): CollectionsState => {
  switch (action.type) {
    case CollectionTypes.Fetch:
      return { ...state, collection: action.collection };
    case CollectionTypes.FetchAll:
      return { ...state, collections: action.collections };
    case CollectionTypes.FetchFiltered:
      return { ...state, filteredCollections: action.filteredCollections };
    case CollectionTypes.FetchStatues:
      return { ...state, collectionStatuses: action.collectionStatuses };
    case CollectionTypes.FetchLabelData:
      return { ...state, collectionLabelData: action.collectionLabelData };
    default:
      return state;
  }
};

export default collectionsReducer;
