import { Dispatch } from 'redux';
import {
  CollectionDto,
  CollectionLabelDto,
  CollectionStatus
} from 'src/store/collections/collection';
import { getRequest } from 'src/store/api-handler';
import { requestBegin, requestEnd } from '../requests/requests.actions';
import { DataResult } from '@progress/kendo-data-query';

export enum CollectionTypes {
  Fetch = 'collections/Fetch',
  FetchAll = 'collections/FetchAll',
  FetchFiltered = 'collections/FetchFiltered',
  FetchStatues = 'collections/FetchStatues',
  FetchLabelData = 'collections/FetchLabelData',
  Failure = 'collections/Failure'
}

export type CollectionAction =
  | { type: CollectionTypes.Fetch; collection: Nullable<CollectionDto> }
  | { type: CollectionTypes.FetchAll; collections: CollectionDto[] }
  | { type: CollectionTypes.FetchFiltered; filteredCollections: DataResult }
  | {
      type: CollectionTypes.FetchStatues;
      collectionStatuses: CollectionStatus[];
    }
  | {
      type: CollectionTypes.FetchLabelData;
      collectionLabelData: CollectionLabelDto;
    }
  | { type: CollectionTypes.Failure; errors: any };

export const actionCreators = {
  fetchCollection: (collection: CollectionDto): CollectionAction => ({
    type: CollectionTypes.Fetch,
    collection
  }),
  fetchCollections: (collections: CollectionDto[]): CollectionAction => ({
    type: CollectionTypes.FetchAll,
    collections
  }),
  fetchFilteredCollections: (
    filteredCollections: DataResult
  ): CollectionAction => ({
    type: CollectionTypes.FetchFiltered,
    filteredCollections
  }),
  fetchCollectionStatuses: (
    collectionStatuses: CollectionStatus[]
  ): CollectionAction => ({
    type: CollectionTypes.FetchStatues,
    collectionStatuses
  }),
  fetchCollectionLabelData: (
    collectionLabelData: CollectionLabelDto
  ): CollectionAction => ({
    type: CollectionTypes.FetchLabelData,
    collectionLabelData
  }),
  collectionRequestFailure: (errors: any): CollectionAction => ({
    type: CollectionTypes.Failure,
    errors
  })
};

export const fetchCollection = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await getRequest(`/collections/${id}`);
    return ok
      ? dispatch(actionCreators.fetchCollection(data))
      : dispatch(actionCreators.collectionRequestFailure(error));
  };
};

export const fetchCollections = (locationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(CollectionTypes.FetchAll));
    const { data } = await getRequest(`/collections/location/${locationId}`);
    dispatch(requestEnd(CollectionTypes.FetchAll));
    return dispatch(actionCreators.fetchCollections(data));
  };
};

export function fetchFilteredCollections(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(CollectionTypes.FetchFiltered));
    const { data } = await getRequest(`/collections/filtered?${queryStr}`);
    dispatch(requestEnd(CollectionTypes.FetchFiltered));
    return dispatch(actionCreators.fetchFilteredCollections(data));
  };
}

export const fetchCollectionStatuses = () => {
  return async (dispatch: Dispatch) => {
    const { data } = await getRequest(`/Collectionstatuses`);
    return dispatch(actionCreators.fetchCollectionStatuses(data));
  };
};

export const fetchCollectionLabelData = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(CollectionTypes.FetchLabelData));
    const { data } = await getRequest(`/collections/${id}/labeldata`);
    dispatch(requestEnd(CollectionTypes.FetchLabelData));
    return dispatch(actionCreators.fetchCollectionLabelData(data));
  };
};
