import { User as OidcUser } from 'oidc-client';
import { UserDetailDto } from 'src/store/users/user';
import { AuthAction, AuthTypes } from './auth.actions';

export type AuthState = Readonly<{
  user: Nullable<UserDetailDto>;
  oidcUser: Nullable<OidcUser>;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  authValidated: boolean;
  error: Nullable<object>;
}>;

const initialState: AuthState = {
  user: null,
  oidcUser: null,
  isAuthenticating: false,
  isAuthenticated: false,
  authValidated: false,
  error: null,
};

export default function authReducer(
  state: AuthState = initialState,
  action: AuthAction
) {
  switch (action.type) {
    case AuthTypes.LoginRequest:
      return {
        ...state,
        isAuthenticating: true,
      };
    case AuthTypes.LoginSuccess:
      return {
        ...state,
        oidcUser: action.oidcUser,
        isAuthenticating: false,
        isAuthenticated: true,
        authValidated: true,
        error: null,
      };
    case AuthTypes.LoginFailure:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        authValidated: true,
        error: action.error,
      };
    case AuthTypes.LogoutSuccess:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        authValidated: true,
        user: null,
        oidcUser: null,
      };
    case AuthTypes.UpdateCurrentUser:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
}
