import { Box, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import flagGB from 'src/assets/images/flags/gb.png';
import flagNO from 'src/assets/images/flags/no.png';
import flagSE from 'src/assets/images/flags/se.png';
import Auxilary from 'src/components/hoc/Auxilary';
import {
  PrimaryButton,
  TertiaryButton,
} from 'src/components/shared/Button/Button';
import InputField from 'src/components/shared/Input/InputField';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { Header3 } from 'src/components/shared/Typography/Header3';
import { useReduxDispatch } from 'src/store';
import { alertSuccess } from 'src/store/alerts/alerts.actions';
import { actionCreators as authActionCreators } from 'src/store/auth/auth.actions';
import { UserDetailDto } from 'src/store/users/user';
import { editUser, setUserLanguage } from 'src/store/users/users.actions';
import { Language } from 'src/store/users/users.reducer';
import {
  required,
  runValidations,
  validationRunner,
  validEmail,
  validPassword,
} from 'src/utils/validation';
import styled from 'styled-components';

type Props = {
  user: Nullable<UserDetailDto>;
  onCancel: () => void;
};

export const ProfileForm: React.FC<Props> = (props) => {
  const { onCancel } = props;

  const [user] = useState<UserDetailDto>(props.user || ({} as UserDetailDto));

  const { t, i18n } = useTranslation();
  const dispatch = useReduxDispatch();

  const onSubmit = async (user: UserDetailDto) => {
    await dispatch(editUser(user.id!, user));
    dispatch(authActionCreators.updateCurrentUser(user));
    dispatch(alertSuccess(t('user.updated')));
  };

  const onChangeLanguage = (language: Language) => {
    dispatch(setUserLanguage(language));
    i18n.changeLanguage(language);
    dispatch(alertSuccess(`${t('language.changed')} ${language}`));
    onCancel();
  };

  const validate = (values: UserDetailDto) => {
    const validationRunners = [
      validationRunner('firstname', t('props.firstname'), required),
      validationRunner('lastname', t('props.lastname'), required),
      validationRunner('email', t('props.email'), required, validEmail),
      validationRunner('username', t('props.username'), required),
      validationRunner('password', t('props.password'), validPassword),
    ];
    return runValidations(values, validationRunners);
  };

  return (
    <Auxilary>
      <Formik
        initialValues={user}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Header2 className="cf-mb-8">{t('profile')}</Header2>
                <InputField
                  name="firstname"
                  label={t('props.firstname')}
                  icon="las la-user"
                  value={values.firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={errors.firstname}
                  displayError={touched.firstname}
                />

                <InputField
                  name="lastname"
                  label={t('props.lastname')}
                  icon="las la-user"
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={errors.lastname}
                  displayError={touched.lastname}
                />
                <InputField
                  name="email"
                  label={t('props.email')}
                  icon="las la-at"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={errors.email}
                  displayError={touched.email}
                />
                <InputField
                  name="username"
                  label={t('props.username')}
                  icon="las la-user"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={errors.username}
                  displayError={touched.username}
                />
                <InputField
                  type="password"
                  name="password"
                  label={t('props.password')}
                  icon="las la-lock"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={errors.password}
                  displayError={touched.password}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" marginTop="2rem">
              <TertiaryButton
                onClick={onCancel}
                label={t('cancel')}
                className="cf-mr-8"
              />
              <PrimaryButton
                type="submit"
                label={t('save.verb')}
                disabled={!dirty || isSubmitting}
              />
            </Box>
          </Form>
        )}
      </Formik>
      <Languages>
        <Header3 className="cf-mb-4">{t('language.singular')}</Header3>
        <img
          onClick={() => onChangeLanguage(Language.Norwegian)}
          src={flagNO}
          alt="Norwegian flag"
          title={t('props.language')}
        />
        <img
          onClick={() => onChangeLanguage(Language.Swedish)}
          src={flagSE}
          alt="Swedish flag"
          title={t('props.language')}
        />
        <img
          onClick={() => onChangeLanguage(Language.English)}
          src={flagGB}
          alt="Great Britain flag"
          title={t('props.language')}
        />
      </Languages>
    </Auxilary>
  );
};

const Languages = styled.section`
  position: absolute;
  bottom: 2rem;

  & img {
    cursor: pointer;
    margin-right: 1rem;
    height: 16px;
    width: 24px;
    transform: scale(1);
    transition: all 0.2s;

    &:hover {
      transform: scale(1.2);
    }
  }
`;
