import { Box, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PrimaryButton,
  TertiaryButton
} from 'src/components/shared/Button/Button';
import InputField from 'src/components/shared/Input/InputField';
import { FileTypeDetailDto, FileTypeDto } from 'src/store/filetypes/filetype';
import {
  required,
  runValidations,
  validationRunner
} from 'src/utils/validation';

type Props = {
  fileType: FileTypeDetailDto;
  errors?: any;
  onCancel: () => any;
  onSubmit: (fileType: FileTypeDto) => any;
};

export const FileTypeForm: React.FC<Props> = props => {
  const { fileType, onSubmit, onCancel } = props;

  const { t } = useTranslation();

  const validate = (fileType: FileTypeDto) => {
    const validationRunners = [
      validationRunner('name', t('props.name'), required)
    ];
    return runValidations(fileType, validationRunners);
  };
  return (
    <Formik
      initialValues={fileType}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        submitCount
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container className="cf-mb-4">
            <Grid item xs={12} md={12}>
              <InputField
                name="name"
                label={t('props.name')}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.name}
                displayError={touched.name || submitCount > 0}
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="center" className="cf-mt-8">
            <TertiaryButton
              onClick={onCancel}
              label={t('cancel')}
              className="cf-mr-8"
            />
            <PrimaryButton
              type="submit"
              label={t('save.verb')}
              disabled={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
