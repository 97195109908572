import { Dispatch } from 'redux';
import { ProductDto } from 'src/store/products/product';
import { getRequest } from 'src/store/api-handler';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum ProductTypes {
  Fetch = 'products/Fetch',
  FetchAll = 'products/FetchAll',
  Failure = 'products/Failure'
}

export type ProductAction =
  | { type: ProductTypes.Fetch; product: Nullable<ProductDto> }
  | { type: ProductTypes.FetchAll; products: ProductDto[] }
  | { type: ProductTypes.Failure; errors: any };

export const actionCreators = {
  fetchProduct: (product: Nullable<ProductDto>): ProductAction => ({
    type: ProductTypes.Fetch,
    product
  }),
  fetchProducts: (products: ProductDto[]): ProductAction => ({
    type: ProductTypes.FetchAll,
    products
  }),
  cargoRequestFailure: (errors: any): ProductAction => ({
    type: ProductTypes.Failure,
    errors
  })
};

export const fetchProduct = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(ProductTypes.Fetch));
    const { ok, data, error } = await getRequest(`/products/${id}`);
    dispatch(requestEnd(ProductTypes.Fetch));
    return ok
      ? dispatch(actionCreators.fetchProduct(data))
      : dispatch(actionCreators.cargoRequestFailure(error));
  };
};

export const fetchProducts = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(ProductTypes.FetchAll));
    const { data } = await getRequest('/products');
    dispatch(requestEnd(ProductTypes.FetchAll));
    return dispatch(actionCreators.fetchProducts(data));
  };
};
