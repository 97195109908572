import { TextField } from '@material-ui/core';
import { Autocomplete as MatAutocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { BodyType } from '../../Typography/BodyType';

interface Props {
  label: string;
  options: any[];
  optionField?: string;
  outputField?: string;
  defaultValue?: any;
  errorMessage?: string;
  displayError?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  loading?: boolean;
  id?: string;
  onChange: (value: any) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  renderOption?: (option: any) => React.ReactNode;
  className?: string;
  style?: any;
}

export const Autocomplete: React.FC<Props> = props => {
  const {
    options,
    optionField,
    outputField,
    defaultValue,
    label,
    errorMessage,
    displayError,
    loading,
    disabled,
    multiple,
    id,
    onBlur,
    onFocus,
    renderOption,
    style,
    className
  } = props;

  const [value, setValue] = useState<any>(multiple ? [] : null);

  useEffect(() => {
    if (outputField) {
      const value = options?.find(o => o[outputField] === defaultValue) ?? null;
      setValue(value);
    } else if (defaultValue?.id !== value?.id) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, options, outputField]);

  const onChange = (_event: React.ChangeEvent<{}>, value: any) => {
    const outputValue = value && outputField ? value[outputField] : value;
    props.onChange(outputValue);
    setValue(value);
  };

  return (
    <MatAutocomplete
      id={id}
      multiple={multiple}
      options={options}
      loading={loading}
      value={value}
      disabled={disabled}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      getOptionLabel={option =>
        String(optionField ? option[optionField] : option)
      }
      getOptionSelected={option => option.id === value?.id}
      renderOption={option =>
        renderOption ? (
          renderOption(option)
        ) : (
          <BodyType>{optionField ? option[optionField] : option}</BodyType>
        )
      }
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
          label={label}
          error={displayError && errorMessage !== undefined}
          helperText={displayError && errorMessage}
          style={{ width: '100%' }}
        />
      )}
      className={className ?? ''}
      style={style ?? { marginBottom: '24px' }}
    />
  );
};

Autocomplete.defaultProps = {};
