import { Grid } from '@material-ui/core';
import React from 'react';
import { media } from 'src/assets/_responsive-mixin';
import styled from 'styled-components';

type Props = {
  className?: string;
};

export const HeaderSection: React.FC<Props> = ({ className, children }) => {
  return (
    <Grid container className={`${className ? className : null}`}>
      {children}
    </Grid>
  );
};

type ChildProps = {
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
};

export const HeaderSectionLeft: React.FC<ChildProps> = ({
  width,
  children,
}) => {
  return (
    <Grid item xs={12} md={width}>
      {children}
    </Grid>
  );
};

HeaderSectionLeft.defaultProps = {
  width: 7,
};

export const HeaderSectionRight: React.FC<ChildProps> = ({
  width,
  children,
}) => {
  return (
    <Grid item xs={12} md={width}>
      <RightContent>{children}</RightContent>
    </Grid>
  );
};

HeaderSectionRight.defaultProps = {
  width: 5,
};

const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;

  ${media.medium`
    justify-content: flex-start;
  `}
`;
