import styled from 'styled-components';

export const SmallType = styled.span<{ color?: string; block?: boolean }>`
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.125rem;
  color: ${props => (props.color ? props.color : 'var(--primary-color)')};
  display: ${props => (props.block ? 'block' : 'inline')};
`;
