import { GridColumn as Column } from '@progress/kendo-react-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'src/components/shared/Button/Button';
import { DataGrid } from 'src/components/shared/DataGrid/DataGrid';
import { GridCountCell } from 'src/components/shared/DataGrid/GridCountCell';
import { Icon } from 'src/components/shared/Icon/Icon';
import { UserDto } from 'src/store/users/user';
import styled from 'styled-components';

type Props = {
  users: UserDto[];
  loading: boolean;
  onEdit: (user: UserDto) => void;
  onDelete: (user: UserDto) => void;
};

export const UsersList: React.FC<Props> = (props) => {
  const { users, loading, onEdit, onDelete } = props;

  const { t } = useTranslation();

  return (
    <DataGrid
      items={users}
      loading={loading}
      onRowClick={({ dataItem }) => onEdit(dataItem)}
    >
      <Column
        width="200px"
        field="firstname"
        title={t('props.firstname')}
        footerCell={() => (
          <GridCountCell count={users?.length} label={t('user.plural')} />
        )}
      />
      <Column width="200px" field="lastname" title={t('props.lastname')} />
      <Column field="username" title={t('props.username')} />
      <Column field="email" title={t('props.email')} />
      <Column field="locationName" title={t('location.singular')} />
      <Column
        width="120px"
        filterable={false}
        title="Conpro"
        cell={({ dataItem }: { dataItem: UserDto }) => (
          <td>
            <UserIcon type={dataItem?.aspNetUserId ? 'la-check' : 'la-times'} />
          </td>
        )}
      />
      <Column
        width="100px"
        filterable={false}
        cell={(props) => (
          <td>
            <ActionButton
              onClick={() => onDelete(props.dataItem)}
              icon="la la-trash"
            />
          </td>
        )}
      />
    </DataGrid>
  );
};

const UserIcon = styled(Icon)`
  &.la-check {
    color: var(--green-status-color);
  }

  &.la-times {
    color: var(--red-status-color);
  }
`;
