import { ProductDto } from 'src/store/products/product';
import { ProductAction, ProductTypes } from './products.actions';

export type ProductsState = Readonly<{
  product: Nullable<ProductDto>;
  products: ProductDto[];
}>;

export const initialState: ProductsState = {
  product: null,
  products: []
};

const productsReducer = (
  state: ProductsState = initialState,
  action: ProductAction
): ProductsState => {
  switch (action.type) {
    case ProductTypes.Fetch:
      return { ...state, product: action.product };
    case ProductTypes.FetchAll:
      return { ...state, products: action.products };
    default:
      return state;
  }
};

export default productsReducer;
