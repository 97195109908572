import React from 'react';
import { useTranslation } from 'react-i18next';
import Auxilary from 'src/components/hoc/Auxilary';
import { Header1 } from 'src/components/shared/Typography/Header1';

const Unauthorized: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Auxilary>
      <Header1>{t('error.unauthorizedHeader')}</Header1>
      <p>{t('error.unauthorizedMessage')}.</p>
    </Auxilary>
  );
};

export default Unauthorized;
