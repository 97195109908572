import { GridColumn as Column } from '@progress/kendo-react-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'src/components/shared/Button/Button';
import { DataGrid } from 'src/components/shared/DataGrid/DataGrid';
import { ProjectDto } from 'src/store/projects/project';

type Props = {
  projects: ProjectDto[];
  loadingProjects: boolean;
  onEdit: (project: ProjectDto) => void;
  onDelete: (project: ProjectDto) => void;
};

export const ProjectsList: React.FC<Props> = props => {
  const { projects, loadingProjects, onEdit, onDelete } = props;

  const { t } = useTranslation();

  return (
    <DataGrid
      items={projects}
      pageable
      pageSize={50}
      loading={loadingProjects}
      onRowClick={({ dataItem }) => onEdit(dataItem)}
    >
      <Column field="no" title={t('props.number')} />
      <Column field="name" title={t('props.name')} />
      <Column field="locationName" title={t('location.singular')} />
      <Column field="customerName" title={t('customer.singular')} />
      <Column field="pricebookName" title={t('pricebook.singular')} />
      <Column
        width="100px"
        filterable={false}
        cell={props => (
          <td>
            <ActionButton
              icon="la la-trash"
              onClick={() => onDelete(props.dataItem)}
            />
          </td>
        )}
      />
    </DataGrid>
  );
};
