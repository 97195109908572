import { Dispatch } from 'redux';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'src/store/api-handler';
import {
  PurchaseOrderDepartmentDto,
  PurchaseOrderDetailDto,
  PurchaseOrderEditDto,
  PurchaseOrderStatusDto
} from 'src/store/purchaseorders/purchaseorder';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum PurchaseOrderTypes {
  Fetch = 'purchaseorder/Fetch',
  FetchAll = 'purchaseorder/FetchAll',
  Delete = 'purchaseorder/Delete',
  Failure = 'purchaseorder/Failure',
  FetchDepartments = 'purchaseorder/FetchDepartments',
  FetchStatuses = 'purchaseorder/FetchStatuses'
}

export type PurchaseOrderAction =
  | {
      type: PurchaseOrderTypes.Fetch;
      purchaseOrder: Nullable<PurchaseOrderDetailDto>;
    }
  | {
      type: PurchaseOrderTypes.FetchAll;
      purchaseOrders: PurchaseOrderDetailDto[];
    }
  | { type: PurchaseOrderTypes.Delete; purchaseOrder: PurchaseOrderDetailDto }
  | { type: PurchaseOrderTypes.Failure; errors: any }
  | {
      type: PurchaseOrderTypes.FetchDepartments;
      purchaseOrderDepartments: PurchaseOrderDepartmentDto[];
    }
  | {
      type: PurchaseOrderTypes.FetchStatuses;
      purchaseOrderStatuses: PurchaseOrderStatusDto[];
    };

export const actionCreators = {
  fetchPurchaseOrder: (po: PurchaseOrderDetailDto): PurchaseOrderAction => ({
    type: PurchaseOrderTypes.Fetch,
    purchaseOrder: po
  }),
  fetchPurchaseOrders: (
    purchaseOrders: PurchaseOrderDetailDto[]
  ): PurchaseOrderAction => ({
    type: PurchaseOrderTypes.FetchAll,
    purchaseOrders
  }),
  fetchPurchaseOrderDepartments: (
    purchaseOrderDepartments: PurchaseOrderDepartmentDto[]
  ): PurchaseOrderAction => ({
    type: PurchaseOrderTypes.FetchDepartments,
    purchaseOrderDepartments
  }),
  fetchPurchaseOrderStatuses: (
    purchaseOrderStatuses: PurchaseOrderStatusDto[]
  ): PurchaseOrderAction => ({
    type: PurchaseOrderTypes.FetchStatuses,
    purchaseOrderStatuses
  }),
  deletePurchaseOrder: (po: PurchaseOrderDetailDto): PurchaseOrderAction => ({
    type: PurchaseOrderTypes.Delete,
    purchaseOrder: po
  }),
  purchaseOrderRequestFailure: (errors: any): PurchaseOrderAction => ({
    type: PurchaseOrderTypes.Failure,
    errors: errors
  })
};

export const setPurchaseOrder = (order: PurchaseOrderDetailDto) => {
  return async (dispatch: Dispatch) => {
    return dispatch(actionCreators.fetchPurchaseOrder(order));
  };
};

export const fetchPurchaseOrder = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await getRequest(`/purchaseorders/${id}`);
    return ok
      ? dispatch(actionCreators.fetchPurchaseOrder(data))
      : dispatch(actionCreators.purchaseOrderRequestFailure(error));
  };
};

export const fetchPurchaseOrders = (locationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(PurchaseOrderTypes.FetchAll));
    const { data } = await getRequest(`/purchaseorders/location/${locationId}`);
    dispatch(requestEnd(PurchaseOrderTypes.FetchAll));
    return dispatch(actionCreators.fetchPurchaseOrders(data));
  };
};

export const createPurchaseOrder = (order: PurchaseOrderEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await postRequest('/purchaseorders', order);
    return ok
      ? dispatch(actionCreators.fetchPurchaseOrder(data))
      : dispatch(actionCreators.purchaseOrderRequestFailure(error));
  };
};

export const editPurchaseOrder = (id: number, order: PurchaseOrderEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await putRequest(
      `/purchaseorders/${id}`,
      order
    );
    return ok
      ? dispatch(actionCreators.fetchPurchaseOrder(data))
      : dispatch(actionCreators.purchaseOrderRequestFailure(error));
  };
};

export const deletePurchaseOrder = (id: number, po: PurchaseOrderDetailDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await deleteRequest(`/purchaseorders/${id}`, po);
    return ok
      ? dispatch(actionCreators.deletePurchaseOrder(po))
      : dispatch(actionCreators.purchaseOrderRequestFailure(error));
  };
};

export const fetchPurchaseOrderDepartments = (locationId: number) => {
  return async (dispatch: Dispatch) => {
    const { data } = await getRequest(
      `/purchaseorders/departments?locationId=${locationId}`
    );
    return dispatch(actionCreators.fetchPurchaseOrderDepartments(data));
  };
};

export const fetchPurchaseOrderStatuses = () => {
  return async (dispatch: Dispatch) => {
    const { data } = await getRequest(`/purchaseorders/statuses`);
    return dispatch(actionCreators.fetchPurchaseOrderStatuses(data));
  };
};
