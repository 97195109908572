import { Box } from '@material-ui/core';
import {
  DataSourceRequestState,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps
} from '@progress/kendo-react-dropdowns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Auxilary from 'src/components/hoc/Auxilary';
import { PrimaryButton } from 'src/components/shared/Button/Button';
import { RootState } from 'src/store';
import { LocationDto } from 'src/store/locations/location';
import { MouldDto, MouldLabelDto } from 'src/store/moulds/mould';
import {
  fetchFilteredMoulds,
  fetchMouldLabelData
} from 'src/store/moulds/moulds.actions';
import './Labels.css';
import { updateRequestFilter } from './LabelsPage';
import MouldLabelList from './MouldLabelList';
import { format } from 'date-fns';

type Props = {
  location: LocationDto;
  moulds: MouldDto[];
  mouldLabelData: MouldLabelDto;
  loading: boolean;
  fetchMouldLabelData: (id: number) => void;
  fetchFilteredMoulds: (queryStr: string) => void;
  printPDF: (element: HTMLElement, fileName: string) => void;
};

const MouldsSection: React.FC<Props> = props => {
  const {
    location,
    loading,
    mouldLabelData,
    fetchMouldLabelData,
    fetchFilteredMoulds,
    printPDF
  } = props;

  const [moulds, setMoulds] = useState<MouldDto[]>([]);
  const [mould, setMould] = useState<MouldDto | null>(null);
  const [mouldDataState, setMouldDataState] = useState<
    Nullable<DataSourceRequestState>
  >(null);

  const { t } = useTranslation();
  const mouldList = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMoulds(props.moulds);
  }, [props.moulds]);

  useEffect(() => {
    setMould(null);
    setMouldDataState({
      skip: 0,
      take: 20,
      filter: {
        filters: [
          {
            field: 'locationId',
            operator: 'eq',
            value: location.id
          }
        ],
        logic: 'and'
      }
    });
  }, [location]);

  const fetchMoulds = useCallback(
    (dataState: DataSourceRequestState) => {
      const queryStr = `${toDataSourceRequestString(dataState)}`;
      fetchFilteredMoulds(queryStr);
    },
    [fetchFilteredMoulds]
  );

  useEffect(() => {
    if (mouldDataState) {
      fetchMoulds(mouldDataState);
    }
  }, [fetchMoulds, mouldDataState]);

  const onMouldFilterChange = (event: ComboBoxFilterChangeEvent) => {
    if (event.nativeEvent instanceof FocusEvent) return;
    setMouldDataState(updateRequestFilter(mouldDataState!, event.filter, 'no'));
  };

  const onMouldChange = (event: ComboBoxChangeEvent) => {
    if (event.nativeEvent instanceof FocusEvent) return;
    const mould: MouldDto = event.value;
    setMould(mould);
    if (mould) fetchMouldLabelData(mould.id);
  };

  const printMoulds = () => {
    if (mouldList.current && mould) {
      printPDF(mouldList.current, `${t('label.plural')}-${mould?.no}.pdf`);
    }
  };

  const mouldItem = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const mould: MouldDto = itemProps.dataItem;
    return React.cloneElement(
      li,
      li.props,
      <span>
        {mould.no} ({format(new Date(mould.regDate), 'dd.MM.yyyy')}){' '}
        {mould.comment}
      </span>
    );
  };

  return (
    <Auxilary>
      <Box
        className="cf-mb-8"
        display="flex"
        justifyContent="between"
        alignItems="flex-end"
      >
        <ComboBox
          placeholder={`${t('select')} ${t('mould.singular')}`}
          dataItemKey="id"
          textField="no"
          suggest
          filterable
          data={moulds}
          value={mould}
          onChange={onMouldChange}
          onFilterChange={onMouldFilterChange}
          className="cf-mr-8"
          loading={loading}
          itemRender={mouldItem}
        />
        <PrimaryButton disabled={!mould} onClick={printMoulds}>
          {t('print')}
        </PrimaryButton>
      </Box>

      {mould && mouldLabelData && mouldLabelData.elements?.length !== 0 && (
        <MouldLabelList mouldLabelData={mouldLabelData} ref={mouldList} />
      )}
    </Auxilary>
  );
};

const mapStateToProps = (state: RootState) => ({
  location: state.locations.activeLocation,
  moulds: state.moulds.filteredMoulds.data,
  mouldLabelData: state.moulds.mouldLabelData,
  loading: false
});

export default connect(mapStateToProps, {
  fetchFilteredMoulds,
  fetchMouldLabelData
})(MouldsSection);
