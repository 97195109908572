import { GridColumn as Column } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Auxilary from 'src/components/hoc/Auxilary';
import {
  ActionButton,
  PrimaryButton
} from 'src/components/shared/Button/Button';
import { DataGrid } from 'src/components/shared/DataGrid/DataGrid';
import {
  HeaderSection,
  HeaderSectionLeft,
  HeaderSectionRight
} from 'src/components/shared/HeaderSection/HeaderSection';
import { Modal } from 'src/components/shared/Modal/Modal';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { RootState, useReduxDispatch } from 'src/store';
import { alertSuccess } from 'src/store/alerts/alerts.actions';
import { FileTypeDetailDto, FileTypeDto } from 'src/store/filetypes/filetype';
import { mapFileTypeDetailToEdit } from 'src/store/filetypes/filetype.map';
import {
  createFileType,
  deleteFileType,
  editFileType,
  fetchFileType,
  fetchFileTypes
} from 'src/store/filetypes/filetypes.actions';
import {
  selectFileType,
  selectFileTypes
} from 'src/store/filetypes/filetypes.selectors';
import { isLoadingFileTypes } from 'src/store/requests/requests.selectors';
import { FileTypeForm } from './FileTypeForm';

type Props = {};

export const FileTypesPage: React.FC<Props> = () => {
  const [currentFileType, setCurrentFileType] = useState<FileTypeDto | null>(
    null
  );
  const [fileType, setFileType] = useState<FileTypeDetailDto>(
    {} as FileTypeDetailDto
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { t } = useTranslation();

  const dispatch = useReduxDispatch();

  const fileType$ = useSelector((state: RootState) =>
    selectFileType(state.fileTypes)
  );

  const fileTypes = useSelector((state: RootState) =>
    selectFileTypes(state.fileTypes)
  );

  const loadingFileTypes = useSelector(isLoadingFileTypes);

  useEffect(() => {
    dispatch(fetchFileTypes());
  }, [dispatch]);

  useEffect(() => {
    if (currentFileType) {
      setShowDeleteModal(true);
    }
  }, [currentFileType]);

  useEffect(() => {
    if (fileType$) setFileType(fileType$);
  }, [fileType$]);

  const onCancel = () => {
    setCurrentFileType(null);
    setFileType({} as FileTypeDetailDto);
    setShowDeleteModal(false);
    setShowEditModal(false);
  };

  const onCreate = () => {
    setShowEditModal(true);
  };

  const onEdit = async (fileType: FileTypeDto) => {
    await dispatch(fetchFileType(fileType.id!));
    setShowEditModal(true);
  };

  const onDelete = (fileType: FileTypeDto) => {
    setCurrentFileType(fileType);
  };

  const onConfirmDelete = async () => {
    if (currentFileType) {
      await dispatch(deleteFileType(currentFileType.id, currentFileType));
      setCurrentFileType(null);
      setShowDeleteModal(false);
    }
  };

  const onSubmit = async (fileType: FileTypeDetailDto) => {
    const dto = mapFileTypeDetailToEdit(fileType);
    if (dto.id) {
      await dispatch(editFileType(dto.id, dto));
      onCancel();
      dispatch(alertSuccess(t('fileType.updated')));
    } else {
      await dispatch(createFileType(dto));
      onCancel();
      dispatch(alertSuccess(t('fileType.created')));
      dispatch(fetchFileTypes());
    }
  };

  return (
    <Auxilary>
      <HeaderSection className="cf-mb-8">
        <HeaderSectionLeft>
          <Header1>{t('fileType.plural')}</Header1>
          <BodyType>{`${t('crudtext')} ${t('fileType.plural')}`}</BodyType>
        </HeaderSectionLeft>
        <HeaderSectionRight>
          <PrimaryButton onClick={onCreate}>
            {`${t('create')} ${t('fileType.singular')}`}
          </PrimaryButton>
        </HeaderSectionRight>
      </HeaderSection>
      <DataGrid
        items={fileTypes}
        loading={loadingFileTypes}
        onRowClick={({ dataItem }) => onEdit(dataItem)}
      >
        <Column field="name" title={t('props.name')} />
        <Column
          width="100px"
          filterable={false}
          cell={props => (
            <td>
              <ActionButton
                icon="la la-trash"
                onClick={() => onDelete(props.dataItem)}
              />
            </td>
          )}
        />
      </DataGrid>

      <Modal
        header={`${t('create')} ${t('fileType.singular')}`}
        confirmText={t('save.verb')}
        onClose={() => onCancel()}
        open={showEditModal}
      >
        <FileTypeForm
          fileType={fileType}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Modal>

      <Modal
        width={500}
        header={`${t('delete')} ${t('fileType.singular')}`}
        confirmText={t('delete')}
        onClose={() => onCancel()}
        onConfirm={() => onConfirmDelete()}
        open={showDeleteModal}
        showActions
      >
        <BodyType>
          {t('fileType.deleteConfirm')} <q>{currentFileType?.name}</q>?
        </BodyType>
      </Modal>
    </Auxilary>
  );
};
