import { RequestAction, RequestTypes } from './requests.actions';

export type RequestsState = Readonly<{
  inProgress: string[];
}>;

export const initialState: RequestsState = {
  inProgress: []
};

const requestsReducer = (
  state: RequestsState = initialState,
  action: RequestAction
) => {
  switch (action.type) {
    case RequestTypes.Begin:
      return {
        ...state,
        inProgress: [...state.inProgress, action.request]
      };
    case RequestTypes.End:
      return {
        ...state,
        inProgress: state.inProgress.filter(
          request => request !== action.request
        )
      };
    default:
      return state;
  }
};

export default requestsReducer;
