import { Dispatch } from 'redux';
import { getRequest } from 'src/store/api-handler';
import { CustomerDto } from 'src/store/customers/customer';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum CustomerTypes {
  FetchAll = 'customers/FetchAll'
}

export type CustomerAction = {
  type: CustomerTypes.FetchAll;
  customers: CustomerDto[];
};

export const actionCreators = {
  fetchCustomers: (customers: CustomerDto[]): CustomerAction => ({
    type: CustomerTypes.FetchAll,
    customers
  })
};

export const fetchCustomers = (locationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(CustomerTypes.FetchAll));
    const { data } = await getRequest(`/customers/location/${locationId}`);
    dispatch(requestEnd(CustomerTypes.FetchAll));
    return dispatch(actionCreators.fetchCustomers(data));
  };
};
