import { History } from 'history';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PrimaryButton } from 'src/components/shared/Button/Button';
import {
  HeaderSection,
  HeaderSectionLeft,
  HeaderSectionRight
} from 'src/components/shared/HeaderSection/HeaderSection';
import { Modal } from 'src/components/shared/Modal/Modal';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { RootState, useReduxDispatch } from 'src/store';
import { fetchCustomers } from 'src/store/customers/customers.actions';
import { fetchFiles } from 'src/store/files/files.actions';
import { fetchFileTypes } from 'src/store/filetypes/filetypes.actions';
import { selectActiveLocation } from 'src/store/locations/locations.selectors';
import { fetchPricebooks } from 'src/store/pricebooks/pricebooks.actions';
import { ProjectDto } from 'src/store/projects/project';
import { deleteProject } from 'src/store/projects/projects.actions';
import { selectProjects } from 'src/store/projects/projects.selectors';
import { isLoadingProjects } from 'src/store/requests/requests.selectors';
import { ProjectsList } from './ProjectsList';

type Props = { history: History };

export const ProjectsPage: React.FC<Props> = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const activeLocation = useSelector((state: RootState) =>
    selectActiveLocation(state.locations)
  );

  const [project, setProject] = useState<Nullable<ProjectDto>>(null);

  const projects = useSelector((state: RootState) =>
    selectProjects(state.projects)
  );

  const loadingProjects = useSelector(isLoadingProjects);

  useEffect(() => {
    if (activeLocation != null) {
      dispatch(fetchCustomers(activeLocation.id));
      dispatch(fetchPricebooks(activeLocation.id));
    }
    dispatch(fetchFiles());
    dispatch(fetchFileTypes());
  }, [dispatch, activeLocation]);

  const onCreate = () => {
    history.push('projects/create');
  };

  const onEdit = (project: ProjectDto) => {
    history.push(`projects/${project.id}`);
  };

  const onCancel = () => {
    setProject(null);
  };

  const onDelete = (project: ProjectDto) => {
    setProject(project);
  };

  const onConfirmDelete = async () => {
    if (project) {
      await dispatch(deleteProject(project.id!, project));
      setProject(null);
    }
  };

  return (
    <Fragment>
      <HeaderSection className="cf-mb-8">
        <HeaderSectionLeft>
          <Header1>{t('project.plural')}</Header1>
          <BodyType>{`${t('crudtext')} ${t('project.plural')}`}</BodyType>
        </HeaderSectionLeft>
        <HeaderSectionRight>
          <PrimaryButton onClick={onCreate}>
            {`${t('create')} ${t('project.singular')}`}
          </PrimaryButton>
        </HeaderSectionRight>
      </HeaderSection>
      {activeLocation ? (
        <ProjectsList
          projects={projects}
          loadingProjects={loadingProjects}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ) : (
        <Header2>{`${t('select')} ${t('location.singular')}`}</Header2>
      )}
      <Modal
        width={500}
        header={`${t('delete.verb')} ${t('project.singular')}`}
        confirmText={t('delete.verb')}
        onClose={() => onCancel()}
        onConfirm={() => onConfirmDelete()}
        open={project !== null}
        showActions
      >
        <BodyType>
          {t('project.deleteConfirm')} <q>{project?.name}</q>?
        </BodyType>
      </Modal>
    </Fragment>
  );
};
