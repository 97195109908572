import { Grid, Tab, Tabs } from '@material-ui/core';
import {
  DataSourceRequestState,
  FilterDescriptor
} from '@progress/kendo-data-query';
import { savePDF } from '@progress/kendo-react-pdf';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Auxilary from 'src/components/hoc/Auxilary';
import { TabPanel } from 'src/components/shared/Tabs/TabPanel';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { RootState } from 'src/store';
import { selectActiveLocation } from 'src/store/locations/locations.selectors';
import CollectionsSection from './CollectionsSection';
import './Labels.css';
import MouldsSection from './MouldsSection';

type Props = {};

export const LabelsPage: React.FC<Props> = () => {
  const [tab, setTab] = useState(0);

  const { t } = useTranslation();

  const activeLocation = useSelector((state: RootState) =>
    selectActiveLocation(state.locations)
  );

  const printPDF = (element: HTMLElement, fileName: string) => {
    savePDF(element, {
      paperSize: ['250mm', '35mm'],
      fileName: fileName
    });
  };

  return (
    <Auxilary>
      <Grid container className="cf-mb-8">
        <Grid item xs={6} md={12}>
          <Header1>{t('label.plural')}</Header1>
          <BodyType>{t('label.text')}</BodyType>
        </Grid>
      </Grid>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={(_, tab) => setTab(tab)}
        className="cf-mb-6"
      >
        <Tab label={t('mould.plural')} />
        <Tab label={t('collection.plural')} />
      </Tabs>

      {activeLocation ? (
        <Fragment>
          <TabPanel index={0} value={tab}>
            <MouldsSection printPDF={printPDF} />
          </TabPanel>
          <TabPanel index={1} value={tab}>
            <CollectionsSection printPDF={printPDF} />
          </TabPanel>
        </Fragment>
      ) : (
        <Header2>{`${t('select')} ${t('location.singular')}`}</Header2>
      )}
    </Auxilary>
  );
};

export const updateRequestFilter = (
  request: DataSourceRequestState,
  filter: FilterDescriptor,
  field: string
): DataSourceRequestState => {
  return {
    ...request,
    filter: {
      filters: request.filter
        ? [
            ...request.filter.filters.filter(
              (f: FilterDescriptor) => f.field !== field
            ),
            filter
          ]
        : [],
      logic: 'and'
    }
  };
};
