import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  Switch
} from '@material-ui/core';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  PrimaryButton,
  TertiaryButton
} from 'src/components/shared/Button/Button';
import { Autocomplete } from 'src/components/shared/Forms/Autocomplete/Autocomplete';
import {
  ErrorMessage,
  FormField,
  FormLabel
} from 'src/components/shared/Forms/FormHelpers';
import { Icon } from 'src/components/shared/Icon/Icon';
import InputField from 'src/components/shared/Input/InputField';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { Header3 } from 'src/components/shared/Typography/Header3';
import { RootState } from 'src/store';
import { selectCustomers } from 'src/store/customers/customers.selectors';
import { selectPricebooks } from 'src/store/pricebooks/pricebooks.selectors';
import {
  ProjectArticleDto,
  ProjectDetailDto
} from 'src/store/projects/project';
import {
  maxLength,
  required,
  runValidations,
  validationRunner
} from 'src/utils/validation';
import { ProjectArticles } from './ProjectArticles';
import { ProjectStartupMeeting } from './ProjectStartupMeeting';

type Props = {
  project: Nullable<ProjectDetailDto>;
  errors?: any;
  onCancel: () => any;
  onSubmit: (project: ProjectDetailDto) => any;
  onArticlesChanged: (articles: ProjectArticleDto[]) => void;
};

export const ProjectDetailsForm: React.FC<Props> = props => {
  const { onSubmit, onCancel, onArticlesChanged } = props;
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(
    false
  );
  const [project, setProject] = useState<ProjectDetailDto>({
    active: true,
    beginDate: new Date(),
    endDate: new Date()
  } as ProjectDetailDto);

  const { t } = useTranslation();

  const customers$ = useSelector((state: RootState) =>
    selectCustomers(state.customers)
  );

  const pricebooks$ = useSelector((state: RootState) =>
    selectPricebooks(state.pricebooks)
  );

  useEffect(() => {
    if (props.project) {
      setProject({
        ...props.project,
        beginDate: new Date(props.project.beginDate!),
        endDate: new Date(props.project.endDate!)
      });
    }
  }, [props.project]);

  const validate = (project: ProjectDetailDto) => {
    const validationRunners = [
      validationRunner('name', t('props.name'), required, maxLength(50))
    ];
    return runValidations(project, validationRunners);
  };

  return (
    <Formik
      initialValues={project}
      validate={validate}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        submitCount
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Header1 className="cf-mb-8">
                {project.id ? t('edit') : t('create')} {t('project.singular')}
                {project.id && ` - ${project.no}`}
              </Header1>
            </Grid>
            <Grid item xs={12} md={6} className="flex align-center jc-end">
              <FormControlLabel
                className="cf-mr-32"
                control={
                  <Switch
                    checked={values.active}
                    onChange={handleChange}
                    name="active"
                  />
                }
                label={t('props.active')}
              />
              <TertiaryButton
                onClick={onCancel}
                label={t('cancel')}
                className="mr-32"
              />
              <PrimaryButton
                type="submit"
                label={t('save.verb')}
                disabled={isSubmitting}
                onClick={() => {
                  setShowValidationErrors(true);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <InputField
                name="no"
                label={t('props.number')}
                value={values.no}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.no}
                displayError={touched.no || submitCount > 0}
              />
              <InputField
                name="name"
                label={t('props.name')}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.name}
                displayError={touched.name || submitCount > 0}
              />
              <InputField
                name="address"
                label={t('props.address')}
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.address}
                displayError={touched.address || submitCount > 0}
              />
              <InputField
                name="zip"
                label={t('props.zip')}
                value={values.zip}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.zip}
                displayError={touched.zip || submitCount > 0}
              />
              <InputField
                name="city"
                label={t('props.city')}
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.city}
                displayError={touched.city || submitCount > 0}
              />
              <InputField
                name="country"
                label={t('props.country')}
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.country}
                displayError={touched.country || submitCount > 0}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField>
                <Autocomplete
                  options={customers$}
                  label={t('customer.singular')}
                  outputField="id"
                  optionField="name"
                  defaultValue={values.customerId}
                  onChange={value => setFieldValue('customerId', value)}
                  onBlur={handleBlur}
                />
                {errors.customerId && showValidationErrors && (
                  <ErrorMessage>{errors.customerId}</ErrorMessage>
                )}
              </FormField>
              <InputField
                name="customerProjectNo"
                label={t('project.customerProjectNo')}
                value={values.customerProjectNo}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.customerProjectNo}
                displayError={touched.customerProjectNo || submitCount > 0}
              />
              <FormField>
                <Autocomplete
                  options={pricebooks$}
                  label={t('pricebook.singular')}
                  outputField="id"
                  optionField="name"
                  defaultValue={values.pricebookId}
                  onChange={value => setFieldValue('pricebookId', value)}
                  onBlur={handleBlur}
                />
                {errors.pricebookId && showValidationErrors && (
                  <ErrorMessage>{errors.pricebookId}</ErrorMessage>
                )}
              </FormField>
              <Grid container spacing={0} className="cf-mt-8">
                <Grid item xs={3}>
                  <FormLabel>{t('project.beginDate')}</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <DatePicker
                    name="beginDate"
                    format="dd.MM.yyyy"
                    value={values.beginDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} className="cf-mt-8">
                <Grid item xs={3}>
                  <FormLabel>{t('project.endDate')}</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <DatePicker
                    name="endDate"
                    format="dd.MM.yyyy"
                    value={values.endDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<Icon type="la-angle-down" />}
              id="panel1-header"
            >
              <Header3>{t('project.msgProjectArticles')}</Header3>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectArticles
                project={values}
                onArticlesChanged={onArticlesChanged}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<Icon type="la-angle-down" />}
              id="panel2-header"
            >
              <Header3>{t('contacts.plural')}</Header3>
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<Icon type="la-angle-down" />}
              id="panel2-header"
            >
              <Header3>{t('project.startupMeeting')}</Header3>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectStartupMeeting
                project={values}
                setFieldValue={setFieldValue}
              />
            </AccordionDetails>
          </Accordion>
        </Form>
      )}
    </Formik>
  );
};
