import { filterBy } from '@progress/kendo-data-query';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDidMount } from 'src/hooks/useDidMount';
import { RootState, useReduxDispatch } from 'src/store';
import {
  fetchLocations,
  setActiveLocation
} from 'src/store/locations/locations.actions';
import {
  selectActiveLocation,
  selectLocations
} from 'src/store/locations/locations.selectors';
import { setActiveProject } from 'src/store/projects/projects.actions';

type Props = {
  className?: string;
};

export const LocationSelector: React.FC<Props> = props => {
  const [locationsFilter, setLocationsFilter] = useState<any>(null);

  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const { className } = props;

  const locations = useSelector((state: RootState) =>
    selectLocations(state.locations)
  );

  const activeLocation = useSelector((state: RootState) =>
    selectActiveLocation(state.locations)
  );

  useDidMount(() => {
    dispatch(fetchLocations());
  }, []);

  const onLocationChange = (event: ComboBoxChangeEvent) => {
    dispatch(setActiveLocation(event.value));
    dispatch(setActiveProject(null));
  };

  return (
    <ComboBox
      placeholder={`${t('select')} ${t('location.singular')}`}
      dataItemKey="id"
      textField="name"
      suggest
      filterable
      onFilterChange={event => {
        setLocationsFilter(event.filter);
      }}
      data={filterBy(locations, locationsFilter)}
      value={activeLocation}
      onChange={onLocationChange}
      className={className ? className : ''}
    />
  );
};
