import React from 'react';

type Props = {
  value: number;
  index: number;
};

export const TabPanel: React.FC<Props> = props => {
  const { children, value, index, ...rest } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...rest}>
      <div className="py-20">{children}</div>
    </div>
  );
};
