import { Dispatch } from 'redux';
import { BuildingDto } from 'src/store/buildings/building';
import { getRequest } from 'src/store/api-handler';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum BuildingTypes {
  FetchAll = 'buildings/FetchAll'
}

export type BuildingAction = {
  type: BuildingTypes.FetchAll;
  buildings: BuildingDto[];
};

export const actionCreators = {
  fetchBuildings: (buildings: BuildingDto[]): BuildingAction => ({
    type: BuildingTypes.FetchAll,
    buildings
  })
};

export const fetchBuildings = (projectId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(BuildingTypes.FetchAll));
    const { data } = await getRequest(`/buildings/project/${projectId}`);
    dispatch(requestEnd(BuildingTypes.FetchAll));
    return dispatch(actionCreators.fetchBuildings(data));
  };
};
