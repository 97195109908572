import axios, { AxiosError, AxiosResponse } from 'axios';
import i18n from 'i18next';
import izitoast from 'izitoast';
import { history } from '../index';

export interface ApiError {
  traceId?: string;
  title?: string;
  status?: number;
  type?: string;
  errors?: { [key: string]: string };
}

interface Response {
  ok: boolean;
  data?: any;
  error?: ApiError;
}

axios.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    const status = error?.response?.status;

    if (status === 401 || status === 403) {
      history.push('/unauthorized');
      sessionStorage.removeItem(
        `oidc.user:${process.env.REACT_APP_AUTH}:lims-spa`
      );
      izitoast.error({
        message: i18n.t('error.unauthorizedMessage'),
        position: 'bottomRight'
      });
    }

    if (status === 404) {
      history.push('/notfound');
      izitoast.error({
        message: i18n.t('error.notFoundMessage'),
        position: 'bottomRight'
      });
    }

    if (status === 500) {
      izitoast.error({
        message: i18n.t('error.serverError'),
        position: 'bottomRight'
      });
    }
    return error?.response;
  }
);

function handleResponse(response: AxiosResponse): Response {
  const { status, data } = response;
  if (status < 400) {
    return {
      ok: true,
      data: data
    };
  } else {
    return {
      ok: false,
      error: data
    };
  }
}

const acceptJsonHeader = {
  Accept: 'application/json'
};

const contentTypeJsonHeader = {
  'Content-Type': 'application/json'
};

const acceptMultiPartFormData = {
  Accept: 'multipart/form-data'
};

const contentTypeMultiPartFormData = {
  'Content-Type': 'multipart/form-data'
};

const authorizationHeader = () => {
  return {
    Authorization: `Bearer ${
      JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTH}:conpro-spa`
        ) as string
      ).access_token
    }`
  };
};

axios.defaults.baseURL = process.env.REACT_APP_API + '/api';

async function tryAjax(func: () => any): Promise<Response> {
  try {
    const response = await func();
    return handleResponse(response);
  } catch (error) {
    return {
      ok: false,
      error: error
    };
  }
}

export const getRequest = (url: string) =>
  tryAjax(() => {
    return axios({
      method: 'GET',
      url: url,
      headers: {
        ...acceptJsonHeader,
        ...authorizationHeader()
      }
    });
  });

export const postRequest = (url: string, data: any) =>
  tryAjax(() =>
    axios({
      method: 'POST',
      url: url,
      headers: {
        ...acceptJsonHeader,
        ...contentTypeJsonHeader,
        ...authorizationHeader()
      },
      data: data
    })
  );

export const multipartPostRequest = (url: string, formData: FormData) =>
  tryAjax(() =>
    axios({
      method: 'POST',
      url: url,
      headers: {
        ...acceptMultiPartFormData,
        ...contentTypeMultiPartFormData,
        ...authorizationHeader()
      },
      data: formData
    })
  );

export const putRequest = (url: string, data: any) =>
  tryAjax(() =>
    axios({
      method: 'PUT',
      url: url,
      headers: {
        ...acceptJsonHeader,
        ...contentTypeJsonHeader,
        ...authorizationHeader()
      },
      data: data
    })
  );

export const deleteRequest = (url: string, data?: any) =>
  tryAjax(() =>
    axios({
      method: 'DELETE',
      url: url,
      headers: {
        ...acceptJsonHeader,
        ...authorizationHeader()
      },
      data: data
    })
  );
