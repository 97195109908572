import { filterBy } from '@progress/kendo-data-query';
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent
} from '@progress/kendo-react-dropdowns';
import { GridCellProps } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';

interface Props {
  items: any[];
  uniqueField?: string;
  optionField: string;
  onChange?: any;
  onItemChange?: any;
}

export const DropdownCell: React.FC<React.PropsWithChildren<
  Props & GridCellProps
>> = props => {
  const { dataItem, optionField, uniqueField, field } = props;

  const [items, setItems] = useState<any[]>([]);
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    setItems([...props.items]);
  }, [props.items]);

  useEffect(() => {
    setValue(items.find(item => item.id === dataItem[field!]));
  }, [items, dataItem, field]);

  const onFilterChange = (event: ComboBoxFilterChangeEvent) => {
    return setItems(filterBy(props.items, event.filter));
  };

  const handleChange = (event: ComboBoxChangeEvent) => {
    setValue(event.value);
    props.onChange({
      field: props.field,
      dataItem: props.dataItem,
      syntheticEvent: event.syntheticEvent,
      value: uniqueField && event.value ? event.value[uniqueField] : event.value
    });
    props.onItemChange && props.onItemChange(props.dataItem);
  };

  return (
    <td>
      {dataItem.inEdit ? (
        <ComboBox
          onChange={handleChange}
          value={value}
          data={items}
          dataItemKey={uniqueField}
          textField={optionField}
          filterable={true}
          onFilterChange={onFilterChange}
        />
      ) : (
        value && value[field!]
      )}
    </td>
  );
};
