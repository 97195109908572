import { History } from 'history';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PrimaryButton } from 'src/components/shared/Button/Button';
import {
  HeaderSection,
  HeaderSectionLeft,
  HeaderSectionRight
} from 'src/components/shared/HeaderSection/HeaderSection';
import { Modal } from 'src/components/shared/Modal/Modal';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { RootState, useReduxDispatch } from 'src/store';
import { fetchArticlesByPricebook } from 'src/store/articles/articles.actions';
import { selectActiveProject } from 'src/store/projects/projects.selectors';
import { isLoadingSettlements } from 'src/store/requests/requests.selectors';
import { SettlementDto } from 'src/store/settlements/settlement';
import {
  deleteSettlement,
  fetchSettlements
} from 'src/store/settlements/settlements.actions';
import { selectSettlements } from 'src/store/settlements/settlements.selectors';
import { SettlementsList } from './SettlementsList';

type Props = { history: History };

export const SettlementsPage: React.FC<Props> = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const [settlement, setSettlement] = useState<Nullable<SettlementDto>>(null);

  const settlements = useSelector((state: RootState) =>
    selectSettlements(state.settlements)
  );

  const activeProject = useSelector((state: RootState) =>
    selectActiveProject(state.projects)
  );

  const loadingSettlements = useSelector(isLoadingSettlements);

  useEffect(() => {
    if (activeProject != null) {
      dispatch(fetchArticlesByPricebook(activeProject.pricebookId));
      dispatch(fetchSettlements(activeProject.id));
    }
  }, [dispatch, activeProject]);

  const onCreate = () => {
    history.push('settlements/create');
  };

  const onEdit = (settlement: SettlementDto) => {
    history.push(`settlements/${settlement.id}`);
  };

  const onCancel = () => {
    setSettlement(null);
  };

  const onDelete = (settlement: SettlementDto) => {
    setSettlement(settlement);
  };

  const onConfirmDelete = async () => {
    if (settlement) {
      await dispatch(deleteSettlement(settlement.id!, settlement));
      setSettlement(null);
    }
  };

  return (
    <Fragment>
      <HeaderSection className="cf-mb-8">
        <HeaderSectionLeft>
          <Header1>{t('settlement.plural')}</Header1>
          <BodyType>{`${t('crudtext')} ${t('settlement.plural')}`}</BodyType>
        </HeaderSectionLeft>
        <HeaderSectionRight>
          <PrimaryButton onClick={onCreate}>
            {`${t('create')} ${t('settlement.singular')}`}
          </PrimaryButton>
        </HeaderSectionRight>
      </HeaderSection>
      {activeProject ? (
        <SettlementsList
          settlements={settlements}
          loadingSettlements={loadingSettlements}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ) : (
        <Header2>{`${t('select')} ${t('project.singular')}`}</Header2>
      )}
      <Modal
        width={500}
        header={`${t('delete.verb')} ${t('settlement.singular')}`}
        confirmText={t('delete.verb')}
        onClose={() => onCancel()}
        onConfirm={() => onConfirmDelete()}
        open={settlement !== null}
        showActions
      >
        <BodyType>
          {t('settlement.deleteConfirm')} <q>{settlement?.name}</q>?
        </BodyType>
      </Modal>
    </Fragment>
  );
};
