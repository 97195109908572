import { Box, FormControlLabel, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PrimaryButton,
  TertiaryButton
} from 'src/components/shared/Button/Button';
import {
  ErrorMessage,
  FormField
} from 'src/components/shared/Forms/FormHelpers';
import InputField from 'src/components/shared/Input/InputField';
import { LocationDto } from 'src/store/locations/location';
import { UserDetailDto } from 'src/store/users/user';
import {
  required,
  runValidations,
  validationRunner,
  validEmail,
  validPassword
} from 'src/utils/validation';

type Props = {
  user: Nullable<UserDetailDto>;
  roles: string[];
  locations: LocationDto[];
  errors?: any;
  onCancel: () => any;
  onSubmit: (user: UserDetailDto) => any;
};

const UserForm: React.FC<Props> = props => {
  const { roles, locations, onSubmit, onCancel } = props;

  const [user] = useState<UserDetailDto>(
    props.user ??
      ({
        allLocations: false
      } as UserDetailDto)
  );
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(
    false
  );

  const { t } = useTranslation();

  const validate = (values: UserDetailDto) => {
    const validationRunners = [
      validationRunner('firstname', t('props.firstname'), required),
      validationRunner('lastname', t('props.lastname'), required),
      validationRunner('email', t('props.email'), required, validEmail),
      validationRunner('username', t('props.username'), required),
      validationRunner('password', t('props.password'), validPassword),
      validationRunner('roles', t('props.roles'), required),
      validationRunner('location', t('location.singular'), required)
    ];
    // console.log(runValidations(values, validationRunners));
    return runValidations(values, validationRunners);
  };
  return (
    <Formik
      initialValues={user}
      enableReinitialize={true}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container className="cf-mb-4">
            <Grid item xs={12} md={6}>
              <InputField
                name="firstname"
                label={t('props.firstname')}
                icon="las la-user"
                value={values.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.firstname}
                displayError={touched.firstname || showValidationErrors}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                name="lastname"
                label={t('props.lastname')}
                icon="las la-user"
                value={values.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.lastname}
                displayError={touched.lastname || showValidationErrors}
              />
            </Grid>
          </Grid>

          <Grid container className="cf-mb-4">
            <Grid item xs={12} md={6}>
              <InputField
                name="email"
                label={t('props.email')}
                icon="las la-at"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.email}
                displayError={touched.email || showValidationErrors}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                name="username"
                label={t('props.username')}
                icon="las la-user"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.username}
                displayError={touched.username || showValidationErrors}
              />
            </Grid>
          </Grid>

          <Grid container className="cf-mb-4">
            <Grid item xs={12} md={6}>
              <InputField
                type="password"
                name="password"
                label={t('props.password')}
                icon="las la-lock"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.password}
                displayError={touched.password || showValidationErrors}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MultiSelect
                label={t('props.roles')}
                name="roles"
                data={roles}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.roles}
              />
            </Grid>
          </Grid>

          <Grid container className="cf-mb-4">
            <Grid item xs={12} md={6}>
              <FormField>
                <ComboBox
                  label={t('location.singular')}
                  name="location"
                  data={locations}
                  dataItemKey="id"
                  textField="name"
                  value={values.location}
                  onChange={({ value }) => {
                    setFieldValue('locationId', value?.id ?? null);
                    setFieldValue('location', value);
                  }}
                  onBlur={handleBlur}
                />
                {errors.location && showValidationErrors && (
                  <ErrorMessage>{errors.location}</ErrorMessage>
                )}
              </FormField>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={user.allLocations}
                    color="primary"
                    name="allLocations"
                    value={values.allLocations}
                    onChange={() =>
                      setFieldValue('allLocations', !values.allLocations)
                    }
                  />
                }
                label={t('props.allLocations')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={user.twoFactorEnabled}
                    color="primary"
                    name="twoFactorEnabled"
                    value={values.twoFactorEnabled}
                    onChange={() =>
                      setFieldValue(
                        'twoFactorEnabled',
                        !values.twoFactorEnabled
                      )
                    }
                  />
                }
                label={t('props.twoFactorEnabled')}
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="center" className="cf-mt-8">
            <TertiaryButton
              onClick={onCancel}
              label={t('cancel')}
              className="cf-mr-8"
            />
            <PrimaryButton
              type="submit"
              label={t('save.verb')}
              onClick={() => setShowValidationErrors(true)}
              disabled={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
