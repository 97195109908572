import { DataResult } from '@progress/kendo-data-query';
import { Dispatch } from 'redux';
import {
  MouldDto,
  MouldLabelDto,
  MouldStatusDto,
  MouldTypeDto
} from 'src/store/moulds/mould';
import { getRequest } from 'src/store/api-handler';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum MouldTypes {
  Fetch = 'moulds/Fetch',
  FetchAll = 'moulds/FetchAll',
  FetchFiltered = 'moulds/FetchFiltered',
  FetchTypes = 'moulds/FetchTypes',
  FetchStatues = 'moulds/FetchStatues',
  FetchLabelData = 'moulds/FetchLabelData',
  Failure = 'moulds/Failure'
}

export type MouldAction =
  | { type: MouldTypes.Fetch; mould: Nullable<MouldDto> }
  | { type: MouldTypes.FetchAll; moulds: MouldDto[] }
  | { type: MouldTypes.FetchFiltered; filteredMoulds: DataResult }
  | { type: MouldTypes.FetchTypes; mouldTypes: MouldTypeDto[] }
  | { type: MouldTypes.FetchStatues; mouldStatuses: MouldStatusDto[] }
  | { type: MouldTypes.FetchLabelData; mouldLabelData: MouldLabelDto }
  | { type: MouldTypes.Failure; errors: any };

export const actionCreators = {
  fetchMould: (mould: MouldDto): MouldAction => ({
    type: MouldTypes.Fetch,
    mould
  }),
  fetchMoulds: (moulds: MouldDto[]): MouldAction => ({
    type: MouldTypes.FetchAll,
    moulds
  }),
  fetchFilteredMoulds: (filteredMoulds: DataResult): MouldAction => ({
    type: MouldTypes.FetchFiltered,
    filteredMoulds
  }),
  fetchMouldTypes: (mouldTypes: MouldTypeDto[]): MouldAction => ({
    type: MouldTypes.FetchTypes,
    mouldTypes
  }),
  fetchMouldStatuses: (mouldStatuses: MouldStatusDto[]): MouldAction => ({
    type: MouldTypes.FetchStatues,
    mouldStatuses
  }),
  fetchMouldLabelData: (mouldLabelData: MouldLabelDto): MouldAction => ({
    type: MouldTypes.FetchLabelData,
    mouldLabelData
  }),
  mouldRequestFailure: (errors: any): MouldAction => ({
    type: MouldTypes.Failure,
    errors
  })
};

export const fetchMould = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(MouldTypes.Fetch));
    const { ok, data, error } = await getRequest(`/moulds/${id}`);
    dispatch(requestEnd(MouldTypes.Fetch));
    return ok
      ? dispatch(actionCreators.fetchMould(data))
      : dispatch(actionCreators.mouldRequestFailure(error));
  };
};

export const fetchMoulds = (locationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(MouldTypes.FetchAll));
    const { data } = await getRequest(`/moulds/location/${locationId}`);
    dispatch(requestEnd(MouldTypes.FetchAll));
    return dispatch(actionCreators.fetchMoulds(data));
  };
};

export function fetchFilteredMoulds(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(MouldTypes.FetchFiltered));
    const { data } = await getRequest(`/moulds/filtered?${queryStr}`);
    dispatch(requestEnd(MouldTypes.FetchFiltered));
    return dispatch(actionCreators.fetchFilteredMoulds(data));
  };
}

export const fetchMouldTypes = () => {
  return async (dispatch: Dispatch) => {
    const { data } = await getRequest(`/mouldtypes`);
    return dispatch(actionCreators.fetchMouldTypes(data));
  };
};

export const fetchMouldStatuses = () => {
  return async (dispatch: Dispatch) => {
    const { data } = await getRequest(`/mouldstatuses`);
    return dispatch(actionCreators.fetchMouldStatuses(data));
  };
};

export const fetchMouldLabelData = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(MouldTypes.FetchLabelData));
    const { data } = await getRequest(`/moulds/${id}/labeldata`);
    dispatch(requestEnd(MouldTypes.FetchLabelData));
    return dispatch(actionCreators.fetchMouldLabelData(data));
  };
};
