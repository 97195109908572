import React, { ForwardRefRenderFunction } from 'react';
import { CollectionLabelDto } from 'src/store/collections/collection';
import CollectionLabel from './CollectionLabel';
import { LabelList } from './MouldLabelList';

type Props = {
  collectionLabelData: CollectionLabelDto;
  ref: React.RefObject<HTMLElement>;
};

const CollectionLabelList: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  props,
  ref
) => {
  const { collectionLabelData } = props;
  return (
    <LabelList ref={ref}>
      {collectionLabelData.elements.map((element) => (
        <CollectionLabel
          key={element.id}
          collection={collectionLabelData}
          element={element}
        />
      ))}
    </LabelList>
  );
};

export default React.forwardRef(CollectionLabelList);
