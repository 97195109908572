import { Grid } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { CargoWaybillDto } from 'src/store/cargoes/cargo';
import './Waybill.css';
import images from './Images';

type Props = {
  waybill: CargoWaybillDto;
  showInstructions: boolean;
  page: number;
};

export const Waybill: React.FC<Props> = (props) => {
  const { page, showInstructions } = props;

  const [waybill, setWaybill] = useState<CargoWaybillDto>({
    ...props.waybill,
    paidBySender: true,
    paidByReceiver: false,
  });

  useEffect(() => {
    setWaybill(props.waybill);
  }, [props.waybill]);

  // Stacks
  const createStackRows = () => {
    const stacks = waybill.stacks;
    const rows = [];
    const rowCount = stacks.length > 7 ? stacks.length : 7;

    for (let i = 0; i < rowCount; i++) {
      const key = `stack-${i}`;
      if (stacks[i]) {
        //Format props
        const elementWeight = new Intl.NumberFormat('nb-NO').format(
          stacks[i].elementWeight
        );
        rows.push(
          <tr key={key}>
            <td className="text-right">{stacks[i].no}</td>
            <td className="text-right">{stacks[i].elementCount}</td>
            <td className="text-right">{elementWeight}</td>
            <td className="text-right">{stacks[i].length}</td>
            <td className="text-right">{stacks[i].width}</td>
            <td className="text-right">{stacks[i].maxElementHeight}</td>
          </tr>
        );
      } else {
        rows.push(
          <tr key={key}>
            {[...Array(6)].map((_, j) => (
              <td key={`stack-cell-${i}-${j}`}>&nbsp;</td>
            ))}
          </tr>
        );
      }
    }
    return rows;
  };

  // // Articles
  const createArticleRows = () => {
    const articles = waybill.articles;
    const rows = [];
    const rowCount = articles.length > 7 ? articles.length : 7;

    for (let i = 0; i < rowCount; i++) {
      const key = `article-${i}`;
      if (articles[i]) {
        const article = articles[i];
        //Format props
        const qty = new Intl.NumberFormat('nb-NO').format(article.amount);
        if (article.articleNo) {
          rows.push(
            <tr key={key}>
              <td className="text-right">{qty}</td>
              <td colSpan={4} style={{ whiteSpace: 'nowrap' }}>
                {article.articleName}
              </td>
            </tr>
          );
        } else {
          rows.push(
            <tr key={key}>
              <td>
                <input
                  type="text"
                  name="amount"
                  onChange={(e) => handleChildChange(e, i, 'articles')}
                  className="text-right"
                  defaultValue={qty}
                />
              </td>
              <td colSpan={4}>
                <input
                  type="text"
                  name="articleName"
                  onChange={(e) => handleChildChange(e, i, 'articles')}
                  defaultValue={article.articleName}
                />
              </td>
            </tr>
          );
        }
      } else {
        rows.push(
          <tr key={key}>
            <td>
              <input
                type="text"
                name="amount"
                onChange={(e) => handleChildChange(e, i, 'articles')}
                className="text-right"
              />
            </td>
            <td colSpan={4}>
              <input
                type="text"
                name="articleName"
                onChange={(e) => handleChildChange(e, i, 'articles')}
              />
            </td>
          </tr>
        );
      }
    }
    return rows;
  };

  // Elements
  const createElementRows = () => {
    const elements = waybill.elements;
    const rows = [];
    const rowCount = elements.length > 24 ? elements.length : 24;

    for (let i = 0; i < rowCount; i++) {
      const key = `element-${i}`;
      if (elements[i]) {
        //Format props
        const length = new Intl.NumberFormat('nb-NO').format(
          elements[i].length
        );
        const width = new Intl.NumberFormat('nb-NO').format(elements[i].width);
        const weight = new Intl.NumberFormat('nb-NO').format(
          elements[i].weight
        );
        rows.push(
          <tr key={key}>
            <td>{elements[i].no}</td>
            <td className="text-right">{elements[i].noInStack}</td>
            <td className="text-right">{length}</td>
            <td className="text-right">{width}</td>
            <td className="text-right">{weight}</td>
            <td>
              <input
                type="text"
                name="note"
                onChange={(e) => handleChildChange(e, i, 'elements')}
                defaultValue={elements[i].note}
              />
            </td>
            <td />
          </tr>
        );
      } else {
        rows.push(
          <tr key={key}>
            {[...Array(7)].map((_, j) => (
              <td key={`element-cell-${i}-${j}`}>&nbsp;</td>
            ))}
          </tr>
        );
      }
    }
    return rows;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWaybill({
      ...waybill,
      [event.target.name]: event.target.value,
    });
  };

  const handleBoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWaybill({
      ...waybill,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChildChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    prop: string
  ) => {
    const newWaybill = waybill;
    if (newWaybill[prop]) {
      const next = newWaybill[prop].length;
      const last = next - 1;
      if (index > last) {
        for (let i = next; i <= index; i++) {
          newWaybill[prop].push({});
        }
      }
      newWaybill[prop][index][event.target.name] = event.target.value;
      setWaybill(newWaybill);
    }
  };

  const findPlacement = () => {
    const elements = waybill.elements;
    if (elements.length > 0) {
      const firstElement = elements[0];
      return (
        <span>
          {firstElement.buildingName} / {firstElement.floorName} /{' '}
          {firstElement.sectionName}
        </span>
      );
    } else {
      return <span />;
    }
  };

  // Format props
  const totalWeight = new Intl.NumberFormat('nb-NO').format(
    waybill.totalWeight
  );

  const totalArea = new Intl.NumberFormat('nb-NO').format(waybill.totalArea);

  // Setup dates
  const loadDate = new Intl.DateTimeFormat('nb-NO').format(
    new Date(waybill.loadDate)
  );

  const loadTime = new Intl.DateTimeFormat('nb-NO', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(waybill.loadDate));

  const unloadDate = new Intl.DateTimeFormat('nb-NO').format(
    new Date(waybill.unloadDate)
  );

  const unloadTime = new Intl.DateTimeFormat('nb-NO', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(waybill.unloadDate));

  // Setup images
  const logo = waybill.locationId === 10 ? images.logoBE : images.logo;
  const signature = images[`signature-${waybill.locationId}`];

  return (
    <div>
      {page !== 1 && <div className="page-break" />}
      <div className="waybill">
        <h4 className="text-center">
          <strong>Fraktbrev</strong>
          <img className="logo" src={logo} alt="logo" />
        </h4>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Prosjektnr</strong>
                <br />
                {waybill.projectNo}
              </td>
              <td>
                <strong>Part</strong>
                <br />
                {page}
              </td>
              <td>
                <strong>Leveringsdato</strong>
                <br />
                {unloadDate}
              </td>
              <td>
                <strong>Lassnr</strong>
                <br />
                {waybill.no}
              </td>
              <td>
                <strong>Bygg/Etasje/Del</strong>
                <br />
                {findPlacement()}
              </td>
            </tr>
            <tr>
              <td colSpan={3} valign="top">
                <strong>Avsender</strong>
                <br />
                {waybill.senderName}
                <br />
                {waybill.senderAddress1}
                <br />
                {waybill.senderAddress2 && (
                  <span>
                    {waybill.senderAddress2} <br />
                  </span>
                )}
                {waybill.senderZip} {waybill.senderCity}
              </td>
              <td colSpan={2} valign="top">
                <strong>Leveringsadresse</strong>
                <br />
                {waybill.projectNo} {waybill.projectName}
                <br />
                {waybill.recipientAddress}
                <br />
                {waybill.recipientZip} {waybill.recipientCity}
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <strong>Vår ref.</strong>
                <br />
                {waybill.transportLeader}
              </td>
              <td colSpan={2} rowSpan={3} valign="top">
                <strong>Byggplasskontakt</strong>
                <br />
                {waybill.contactName}
                <br />
                {waybill.recipientName}
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <strong>Biltype</strong>
                <br />
                <input
                  name="transportType"
                  onChange={handleChange}
                  type="text"
                  defaultValue={waybill.transportType}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Grid container>
          <Grid item sm={6}>
            <table>
              <tbody>
                <tr>
                  <th colSpan={6} className="text-center">
                    Pakkespesifikasjon
                  </th>
                </tr>
                <tr>
                  <th>Pakke</th>
                  <th>Elementer</th>
                  <th>Vekt</th>
                  <th>Lengde</th>
                  <th>Bredde</th>
                  <th>Høyde</th>
                </tr>
                {createStackRows()}
              </tbody>
            </table>
          </Grid>
          <Grid item sm={6}>
            <table>
              <tbody>
                <tr>
                  <th colSpan={5} className="text-center">
                    Tilleggsartikler
                  </th>
                </tr>
                <tr>
                  <th style={{ width: '20%' }}>Antall</th>
                  <th style={{ width: '80%' }} colSpan={4}>
                    Produkt
                  </th>
                </tr>
                {createArticleRows()}
              </tbody>
            </table>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item sm={6}>
            <table>
              <tbody>
                <tr>
                  <th colSpan={2}>Totalvekt</th>
                  <th colSpan={2}>Antall elementer</th>
                  <th colSpan={2}>
                    Total m<sup>2</sup>
                  </th>
                </tr>
                <tr>
                  <td colSpan={2} className="text-right">
                    {totalWeight}
                  </td>
                  <td colSpan={2} className="text-right">
                    {waybill.totalCount}
                  </td>
                  <td colSpan={2} className="text-right">
                    {totalArea}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item sm={6}>
            <Grid container>
              <Grid item sm={4}>
                <strong>Frakt belastes</strong>
              </Grid>
              <Grid item sm={4}>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="paidBySender"
                    onChange={handleBoolChange}
                    defaultChecked={waybill.paidBySender}
                  />{' '}
                  Avsender
                </label>
              </Grid>
              <Grid item sm={4}>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="paidByReceiver"
                    onChange={handleBoolChange}
                    defaultChecked={waybill.paidByReceiver}
                  />{' '}
                  Mottaker
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <table>
          <tbody>
            <tr>
              <th style={{ width: '12%' }}>Elementnr</th>
              <th style={{ width: '12%' }}>Nr i pk</th>
              <th style={{ width: '12%' }}>Lengde</th>
              <th style={{ width: '12%' }}>Bredde</th>
              <th style={{ width: '12%' }}>Vekt</th>
              <th style={{ width: '30%' }}>Anmerkninger</th>
              <th style={{ width: '10%' }}>Kvittering</th>
            </tr>
            {createElementRows()}
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>
                <strong>Bilnummer</strong>
                <br />
                <input
                  type="text"
                  name="carNo"
                  onChange={handleChange}
                  defaultValue={waybill.carNo}
                />
              </td>
              <td colSpan={4}>
                <strong>Anmerkninger</strong>
                <br />
                <input
                  type="text"
                  name="note"
                  onChange={handleChange}
                  defaultValue={waybill.note}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Lastedato</strong>
                <br />
                {loadDate}
              </td>
              <td>
                <strong>Lastetid</strong>
                <br />
                {loadTime}
              </td>
              <td>
                <strong>Avvik</strong>
                <br />
                &nbsp;
              </td>
              <td>
                <strong>Leveringsdato</strong>
                <br />
                {unloadDate}
              </td>
              <td>
                <strong>Leveringstid</strong>
                <br />
                {unloadTime}
              </td>
              <td>
                <strong>Avvik</strong>
                <br />
                &nbsp;
              </td>
            </tr>
          </tbody>
        </table>
        <Grid container>
          <Grid item sm={9}>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: '15%' }}>Ankomst</th>
                  <td style={{ width: '30%', borderBottomColor: 'black' }} />
                  <td rowSpan={3} style={{ width: '15%' }} valign="top">
                    <strong>Ventetid</strong>
                    <br />
                  </td>
                  <td
                    rowSpan={3}
                    style={{ width: '40%', borderBottomColor: 'black' }}
                    valign="top"
                  >
                    <strong>Kvittering</strong>
                    <br />
                  </td>
                </tr>
                <tr>
                  <th>Start lev.</th>
                  <td style={{ borderBottomColor: 'black' }} />
                </tr>
                <tr>
                  <th>Slutt lev.</th>
                  <td style={{ borderBottomColor: 'black' }} />
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <th
                    style={{
                      width: '40%',
                      color: 'white',
                      backgroundColor: 'black',
                      textAlign: 'center',
                    }}
                  >
                    Sendersted
                  </th>
                  <th
                    style={{
                      width: '30%',
                      color: 'white',
                      backgroundColor: 'black',
                      textAlign: 'center',
                    }}
                  >
                    Utleveringssted
                  </th>
                  <th
                    style={{
                      width: '30%',
                      color: 'white',
                      backgroundColor: 'black',
                      textAlign: 'center',
                    }}
                  >
                    Godset mottatt
                  </th>
                </tr>
                <tr>
                  <td className="text-center">
                    {signature && (
                      <img src={signature} alt="signature" height="100" />
                    )}
                  </td>
                  <td />
                  <td className="text-center">
                    <br />
                    <br />
                    <br />
                    {[...Array(11)].map((_, i) => (
                      <span key={`hellip-${i}`}>&hellip;</span>
                    ))}
                    <br />
                    Kvittering (fullt navn)
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item sm={3}>
            <table>
              <tbody>
                <tr>
                  <th colSpan={2} className="text-center">
                    Fraktberegning
                  </th>
                </tr>
                <tr>
                  <th style={{ width: '50%' }}>Tekst</th>
                  <th style={{ width: '50%' }}>Beløp</th>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td />
                </tr>
                <tr>
                  <td>Frakt</td>
                  <td />
                </tr>
                <tr>
                  <td>Venting</td>
                  <td />
                </tr>
                <tr>
                  <td style={{ whiteSpace: 'nowrap' }}>Lev. e. 14:00</td>
                  <td />
                </tr>
                <tr>
                  <td>Sum</td>
                  <td />
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td />
                </tr>
                <tr>
                  <th>TOTAL</th>
                  <td />
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>

        <p className="flex jc-sb">
          {showInstructions && (
            <span style={{ color: 'var(--red-status-color)' }}>
              NB! LES BAKSIDEN!
            </span>
          )}
          <span>NB! STRØ LEGGES IGJEN PÅ LOSSEPLASS</span>
        </p>
      </div>
      {showInstructions && (
        <Fragment>
          <div className="page-break" />
          <div className="waybill text-center">
            <img
              src={images.instructions}
              alt="instructions"
              style={{ width: '100%' }}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};
