import { PricebookDto } from 'src/store/pricebooks/pricebook';
import {
  PricebookAction,
  PricebookTypes,
} from 'src/store/pricebooks/pricebooks.actions';

export type PricebooksState = Readonly<{
  pricebooks: PricebookDto[];
}>;

const initialState: PricebooksState = {
  pricebooks: [],
};

const pricebooksReducer = (
  state: PricebooksState = initialState,
  action: PricebookAction
): PricebooksState => {
  switch (action.type) {
    case PricebookTypes.FetchAll:
      return { ...state, pricebooks: action.pricebooks };
    default:
      return state;
  }
};

export default pricebooksReducer;
