import i18n from 'i18next';

const ErrorMessages = {
  isRequired: (fieldName: string) =>
    `${fieldName} ${i18n.t('validation.required')}`,
  notValidEmail: (email: string) =>
    `${email} ${i18n.t('validation.notValidEmail')}`,
  minLength: (length: number) => (fieldName: string) =>
    `${fieldName} ${i18n.t('validation.minLengthMessage')} ${length} ${i18n.t(
      'validation.characters'
    )}`,
  maxLength: (length: number) => (fieldName: string) =>
    `${fieldName} ${i18n.t('validation.maxLengthMessage')} ${length} ${i18n.t(
      'validation.characters'
    )}`,
  notValidPassword: (fieldName: string) =>
    `${fieldName} ${i18n.t('validation.notValidPassword')}`
};

export const required = (value: any) => {
  return value ? null : ErrorMessages.isRequired;
};

export const validPassword = (password: string) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  return regex.test(password) || !password
    ? null
    : ErrorMessages.notValidPassword;
};

export const validEmail = (email: string) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email) ? null : ErrorMessages.notValidEmail;
};

export const minLength = (length: number) => (text: string) =>
  (text?.length ?? 0) >= length ? null : ErrorMessages.minLength(length);

export const maxLength = (length: number) => (text: string) =>
  (text?.length ?? 0) <= length ? null : ErrorMessages.maxLength(length);

export const validationRunner = (
  field: string,
  name: string,
  ...validations: any[]
) => {
  return (state: any) => {
    for (const val of validations) {
      const errorMessageFunc = val(state[field], state);
      if (errorMessageFunc) {
        return { [field]: errorMessageFunc(name) };
      }
    }
    return null;
  };
};

export const runValidations = (state: any, runners: any) => {
  return runners.reduce((prev: any, runner: any) => {
    return { ...prev, ...runner(state) };
  }, {});
};
