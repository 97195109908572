import { Box, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SupplierDto } from 'src/store/suppliers/supplier';
import {
  required,
  runValidations,
  validationRunner
} from 'src/utils/validation';
import {
  PrimaryButton,
  TertiaryButton
} from 'src/components/shared/Button/Button';
import InputField from 'src/components/shared/Input/InputField';

type Props = {
  supplier: SupplierDto;
  errors?: any;
  onCancel: () => any;
  onSubmit: (supplier: SupplierDto) => any;
};

export const SupplierForm: React.FC<Props> = props => {
  const { supplier, onSubmit, onCancel } = props;

  const { t } = useTranslation();

  const validate = (supplier: SupplierDto) => {
    const validationRunners = [
      validationRunner('no', t('props.number'), required),
      validationRunner('name', t('props.name'), required)
    ];
    return runValidations(supplier, validationRunners);
  };
  return (
    <Formik
      initialValues={supplier}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        submitCount
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container className="cf-mb-4">
            <Grid item xs={12} md={6}>
              <InputField
                name="no"
                label={t('props.number')}
                value={values.no}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.no}
                displayError={touched.no || submitCount > 0}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                name="name"
                label={t('props.name')}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.name}
                displayError={touched.name || submitCount > 0}
              />
            </Grid>
          </Grid>

          <Grid container className="cf-mb-4">
            <Grid item xs={12} md={6}>
              <InputField
                name="phone"
                label={t('props.phone')}
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.phone}
                displayError={touched.phone}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                name="address1"
                label={t('props.address')}
                value={values.address1}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.address1}
                displayError={touched.address1}
              />
            </Grid>
          </Grid>

          <Grid container className="cf-mb-4">
            <Grid item xs={12} md={6}>
              <InputField
                name="zip"
                label={t('props.zip')}
                value={values.zip}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.zip}
                displayError={touched.zip}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                name="city"
                label={t('props.city')}
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.city}
                displayError={touched.city}
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="center" className="cf-mt-8">
            <TertiaryButton
              onClick={onCancel}
              label={t('cancel')}
              className="cf-mr-8"
            />
            <PrimaryButton
              type="submit"
              label={t('save.verb')}
              disabled={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
