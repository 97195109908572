import { Box, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  PrimaryButton,
  TertiaryButton
} from 'src/components/shared/Button/Button';
import { Autocomplete } from 'src/components/shared/Forms/Autocomplete/Autocomplete';
import {
  ErrorMessage,
  FormField
} from 'src/components/shared/Forms/FormHelpers';
import { Icon } from 'src/components/shared/Icon/Icon';
import { RootState } from 'src/store';
import { FileDetailDto } from 'src/store/files/file';
import { selectFileTypes } from 'src/store/filetypes/filetypes.selectors';
import {
  required,
  runValidations,
  validationRunner
} from 'src/utils/validation';
import styled from 'styled-components';

type Props = {
  file: FileDetailDto;
  errors?: any;
  onCancel: () => any;
  onSubmit: (file: FileDetailDto, files: File[]) => any;
};

export const FileForm: React.FC<Props> = props => {
  const { file, onSubmit, onCancel } = props;

  const { t } = useTranslation();
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(
    false
  );

  const fileTypes$ = useSelector((state: RootState) =>
    selectFileTypes(state.fileTypes)
  );

  const [fileNames, setFileNames] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const onFileDropped = (acceptedFiles: any) => {
    setFiles(acceptedFiles);
    setFileNames(acceptedFiles.map((file: any) => file.name));
  };

  const validate = (file: FileDetailDto) => {
    const validationRunners = [
      validationRunner('fileTypeId', t('fileType.singular'), required)
    ];
    return runValidations(file, validationRunners);
  };

  return (
    <Formik
      initialValues={file}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, files);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container className="cf-mb-4">
            <Grid item xs={12} md={12}>
              <FormField>
                <Autocomplete
                  options={fileTypes$}
                  label={t('fileType.singular')}
                  outputField="id"
                  optionField="name"
                  defaultValue={values.fileTypeId}
                  onChange={value => setFieldValue('fileTypeId', value)}
                  onBlur={handleBlur}
                />
                {errors.fileTypeId && showValidationErrors && (
                  <ErrorMessage>{errors.fileTypeId}</ErrorMessage>
                )}
              </FormField>
              <FormField>
                <Dropzone
                  onDrop={files => {
                    onFileDropped(files);
                  }}
                  maxFiles={1}
                  disabled={values.fileTypeId == null}
                >
                  {({ getRootProps, getInputProps }) => (
                    <FileDropContainer
                      {...getRootProps({ className: 'dropzone' })}
                    >
                      <input {...getInputProps()} />
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Icon
                          type="la-cloud-upload-alt"
                          size="2.5rem"
                          className="cf-mr-4"
                        />
                        {t('file.dragdrop')}
                      </Box>
                    </FileDropContainer>
                  )}
                </Dropzone>
                {errors.files && showValidationErrors && (
                  <ErrorMessage>{errors.files}</ErrorMessage>
                )}
              </FormField>
              <div>
                <strong>{t('file.singular')}:</strong>
                <ul>
                  {fileNames.map(fileName => (
                    <li key={fileName}>{fileName}</li>
                  ))}
                </ul>
              </div>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="center" className="cf-mt-8">
            <TertiaryButton
              onClick={onCancel}
              label={t('cancel')}
              className="cf-mr-8"
            />
            <PrimaryButton
              type="submit"
              label={t('save.verb')}
              disabled={isSubmitting}
              onClick={() => {
                setShowValidationErrors(true);
              }}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const FileDropContainer = styled.div`
  padding: 1.5rem;
  text-align: center;
  border: 1px dashed var(--primary-color);
  margin-bottom: 1rem;
  cursor: pointer;
`;
