import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import { History } from 'history';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Icon } from 'src/components/shared/Icon/Icon';
import { Header3 } from 'src/components/shared/Typography/Header3';
import { RootState, useReduxDispatch } from 'src/store';
import { selectActiveProject } from 'src/store/projects/projects.selectors';
import {
  SettlementArticleDto,
  SettlementDetailDto
} from 'src/store/settlements/settlement';
import { mapSettlementDetailToEdit } from 'src/store/settlements/settlement.map';
import {
  actionCreators,
  createSettlement,
  editSettlement,
  fetchSettlement,
  SettlementTypes
} from 'src/store/settlements/settlements.actions';
import { selectSettlement } from 'src/store/settlements/settlements.selectors';
import { SettlementArticles } from './SettlementArticles';
import { SettlementDetailsForm } from './SettlementDetailsForm';

type Props = {
  history: History;
};

export const SettlementPage: React.FC<Props> = ({ history }) => {
  const [settlementArticles, setSettlementArticles] = useState<
    SettlementArticleDto[]
  >([]);

  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const route = useRouteMatch();

  const project = useSelector((state: RootState) =>
    selectActiveProject(state.projects)
  );

  const settlement = useSelector((state: RootState) =>
    selectSettlement(state.settlements)
  );

  useEffect(() => {
    const id = (route.params as { id: number | string }).id;
    if (id != 'create') {
      dispatch(fetchSettlement(id as number));
    }
  }, [route, dispatch]);

  const onArticlesChanged = useCallback((articles: SettlementArticleDto[]) => {
    setSettlementArticles(articles);
  }, []);

  const onCancel = () => {
    dispatch(actionCreators.fetchSettlement(null));
    history.push('/settlements');
  };

  const onSubmit = async (settlement: SettlementDetailDto) => {
    settlement.projectId = project!.id;
    const dto = mapSettlementDetailToEdit(settlement);
    dto.settlementArticles = settlementArticles;
    const result = dto.id
      ? await dispatch(editSettlement(dto.id, dto))
      : await dispatch(createSettlement(dto));
    if (result.type !== SettlementTypes.Failure) {
      onCancel();
    }
  };

  return (
    <Fragment>
      <SettlementDetailsForm
        settlement={settlement}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<Icon type="la-angle-down" />}
          id="panel1-header"
        >
          <Header3>{t('settlement.msgSettlementArticles')}</Header3>
        </AccordionSummary>
        <AccordionDetails>
          <SettlementArticles
            settlement={settlement}
            onArticlesChanged={onArticlesChanged}
          />
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
};
