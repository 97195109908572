import { ProjectDetailDto, ProjectDto } from 'src/store/projects/project';
import { ArrayUtils } from 'src/utils/array';
import { ProjectAction, ProjectTypes } from './projects.actions';

export type ProjectsState = Readonly<{
  activeProject: Nullable<ProjectDto>;
  project: Nullable<ProjectDetailDto>;
  projects: ProjectDto[];
  errors: any;
}>;

export const initialState: ProjectsState = {
  activeProject: null,
  project: null,
  projects: [],
  errors: null
};

const projectsReducer = (
  state: ProjectsState = initialState,
  action: ProjectAction
): ProjectsState => {
  switch (action.type) {
    case ProjectTypes.SetActive:
      return { ...state, activeProject: action.project };
    case ProjectTypes.Fetch:
      return { ...state, project: action.project };
    case ProjectTypes.FetchAll:
      return { ...state, projects: action.projects };
    case ProjectTypes.Delete:
      return {
        ...state,
        project: null,
        projects: ArrayUtils.deleteFromListById(state.projects, action.project)
      };
    case ProjectTypes.Failure:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
};

export default projectsReducer;
