import { Box, Grid } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SecondaryButton } from 'src/components/shared/Button/Button';
import { Autocomplete } from 'src/components/shared/Forms/Autocomplete/Autocomplete';
import { FormField } from 'src/components/shared/Forms/FormHelpers';
import { PDFForm } from 'src/components/shared/Forms/Pdf/PDFForm';
import { Modal } from 'src/components/shared/Modal/Modal';
import { RootState, useReduxDispatch } from 'src/store';
import { showFile } from 'src/store/files/files.actions';
import {
  selectBase64pdf,
  selectFiles,
  selectFilesByType
} from 'src/store/files/files.selectors';
import { selectFileTypes } from 'src/store/filetypes/filetypes.selectors';
import { ProjectDetailDto } from 'src/store/projects/project';

interface Props {
  project: ProjectDetailDto;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const ProjectStartupMeeting: React.FC<Props> = ({
  project,
  setFieldValue
}) => {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const [showPDF, setShowPDF] = useState<boolean>(false);

  const [activeFileTypeId, setActiveFileTypeId] = useState<Nullable<number>>(
    null
  );

  const fileTypes$ = useSelector((state: RootState) =>
    selectFileTypes(state.fileTypes)
  );

  const files$ = useSelector((state: RootState) =>
    activeFileTypeId
      ? selectFilesByType(activeFileTypeId, state.files)
      : selectFiles(state.files)
  );

  const base64pdf = useSelector((state: RootState) =>
    selectBase64pdf(state.files)
  );

  const onFileTypeChanged = (fileTypeId: number) => {
    setActiveFileTypeId(fileTypeId);
  };

  const onShowPdf = () => {
    if (project.fileId) {
      dispatch(showFile(project.fileId));
      setShowPDF(true);
    }
  };

  const onHidePdf = () => {
    setShowPDF(false);
  };

  return (
    <Fragment>
      <Box width="100%">
        <Grid container className="cf-mb-6">
          <Grid item xs={12} md={9}>
            <FormField>
              <Autocomplete
                options={files$}
                label={t('file.singular')}
                outputField="id"
                optionField="filename"
                defaultValue={project.fileId}
                onChange={value => setFieldValue('fileId', value)}
              />
            </FormField>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField>
              <Autocomplete
                options={fileTypes$}
                label={t('fileType.singular')}
                outputField="id"
                optionField="name"
                onChange={value => onFileTypeChanged(value)}
              />
            </FormField>
          </Grid>
        </Grid>
        <SecondaryButton onClick={onShowPdf} icon="la la-file-pdf">{`${t(
          'view.verb'
        )} ${t('view.pdf')}`}</SecondaryButton>
      </Box>
      <Modal
        header={`${t('view.verb')} ${t('view.pdf')}`}
        onClose={() => onHidePdf()}
        open={showPDF}
      >
        <PDFForm pdf={base64pdf} />
      </Modal>
    </Fragment>
  );
};
