import React, { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Auxilary from 'src/components/hoc/Auxilary';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { reportError } from 'src/utils/logger';

type ErrorBoundaryProps = {
  children: ReactNode;
};

export type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: object | null;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps & WithTranslation,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error | null, errorInfo: object) {
    this.setState({ error, errorInfo });
    reportError(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <Auxilary>
          <Header1>{this.props.t('error.500header')}</Header1>
          <p>{this.props.t('error.500message')}.</p>
        </Auxilary>
      );
    } else {
      return this.props.children;
    }
  }
}

export default withTranslation()(ErrorBoundary);
