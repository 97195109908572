import React from 'react';
import Auxilary from 'src/components/hoc/Auxilary';
import styled, { keyframes } from 'styled-components';

type Props = {
  size: number;
  width: number;
  color: string;
  active?: boolean;
};

const Loader: React.FC<Props> = props => {
  const { color, size, width, active } = props;
  return (
    <Auxilary>
      {active ? (
        <Svg
          style={{
            width: size,
            height: size
          }}
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Circle
            style={{
              stroke: color,
              strokeWidth: width
            }}
            fill="none"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          />
        </Svg>
      ) : null}
    </Auxilary>
  );
};

export default Loader;

export const rotator = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
`;

export const dash = keyframes`
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 47;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
`;

const Svg = styled.svg`
  animation: ${rotator} 1.4s linear infinite;
`;

const Circle = styled.circle`
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} 1.4s ease-in-out infinite;
`;
