import { SettlementDetailDto, SettlementEditDto } from './settlement';

export const mapSettlementDetailToEdit = (
  dto: SettlementDetailDto
): SettlementEditDto => ({
  id: dto.id,
  no: dto.no,
  name: dto.name,
  projectId: dto.projectId,
  transportArticleId: dto.transportArticleId,
  weight: dto.weight,
  weightDeviation: dto.weightDeviation,
  weightDeviationAbove: dto.weightDeviationAbove,
  weightDeviationBelow: dto.weightDeviationBelow,
  weightDeviationAbs: dto.weightDeviationAbs,
  articleId: dto.articleId,
  count: dto.count,
  countDeviation: dto.countDeviation,
  countDeviationAbove: dto.countDeviationAbove,
  countDeviationBelow: dto.countDeviationBelow,
  countDeviationAbs: dto.countDeviationAbs,
  settlementArticles: dto.settlementArticles
});
