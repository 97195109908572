import { ArticleDto } from 'src/store/articles/article';
import {
  ArticleAction,
  ArticleTypes
} from 'src/store/articles/articles.actions';

export type ArticlesState = Readonly<{
  articles: ArticleDto[];
  pricebookArticles: ArticleDto[];
}>;

const initialState: ArticlesState = {
  articles: [],
  pricebookArticles: []
};

const articlesReducer = (
  state: ArticlesState = initialState,
  action: ArticleAction
): ArticlesState => {
  switch (action.type) {
    case ArticleTypes.FetchAll:
      return { ...state, articles: action.articles };
    case ArticleTypes.FetchByPricebook:
      return { ...state, pricebookArticles: action.pricebookArticles };
    default:
      return state;
  }
};

export default articlesReducer;
