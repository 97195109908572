import { GridColumn as Column } from '@progress/kendo-react-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'src/components/shared/Button/Button';
import { DataGrid } from 'src/components/shared/DataGrid/DataGrid';
import { DateCell } from 'src/components/shared/DataGrid/DateCell';
import { PurchaseOrderDetailDto } from 'src/store/purchaseorders/purchaseorder';

type Props = {
  purchaseOrders: PurchaseOrderDetailDto[];
  loading: boolean;
  onEdit: (po: PurchaseOrderDetailDto) => void;
  onDelete: (po: PurchaseOrderDetailDto) => void;
};

export const PurchaseOrdersList: React.FC<Props> = props => {
  const { purchaseOrders, loading, onEdit, onDelete } = props;

  const { t } = useTranslation();

  return (
    <DataGrid
      items={purchaseOrders}
      loading={loading}
      onRowClick={({ dataItem }) => onEdit(dataItem)}
    >
      <Column field="no" width="80px" title={t('props.number')} />
      <Column
        field="purchaseOrderStatus.name"
        width="150px"
        title={t('props.status')}
        filterable={false}
      />
      <Column
        field="supplier.name"
        width="180px"
        title={t('supplier.singular')}
      />
      <Column
        field="location.name"
        width="180px"
        title={t('location.singular')}
      />
      <Column
        field="purchaseOrderDepartment.name"
        width="180px"
        title={t('props.department')}
      />
      <Column
        field="project.name"
        width="180px"
        title={t('project.singular')}
      />
      <Column
        field="building.name"
        width="100px"
        title={t('building.singular')}
        filterable={false}
      />
      <Column
        field="floor.name"
        width="100px"
        title={t('floor.singular')}
        filterable={false}
      />
      <Column
        field="section.name"
        width="100px"
        title={t('section.singular')}
        filterable={false}
      />
      <Column
        field="product.no"
        width="100px"
        title={t('props.itemno')}
        filterable={false}
      />
      <Column
        field="product.name"
        width="250px"
        title={t('props.itemname')}
        filterable={false}
      />
      <Column
        field="amount"
        width="100px"
        title={t('props.amount')}
        filterable={false}
      />
      <Column
        field="product.unit"
        width="100px"
        title={t('props.unit')}
        filterable={false}
      />
      <Column
        field="supplyDate"
        width="140px"
        title={t('props.orderdate')}
        filterable={false}
        cell={props => <DateCell {...props} />}
      />
      <Column
        field="deliveryDate"
        width="140px"
        title={t('props.deliverydate')}
        filterable={false}
        cell={props => <DateCell {...props} />}
      />
      <Column
        field="deadline"
        width="140px"
        title={t('props.deadline')}
        filterable={false}
        cell={props => <DateCell {...props} />}
      />
      <Column
        width="100px"
        filterable={false}
        cell={props => (
          <td>
            <ActionButton
              onClick={() => onDelete(props.dataItem)}
              icon="la la-trash"
            />
          </td>
        )}
      />
    </DataGrid>
  );
};
