import { ProjectDetailDto, ProjectEditDto } from './project';

export const mapProjectDetailToEdit = (
  dto: ProjectDetailDto
): ProjectEditDto => ({
  id: dto.id,
  no: dto.no,
  name: dto.name,
  address: dto.address,
  zip: dto.zip,
  city: dto.city,
  country: dto.country,
  active: dto.active,
  beginDate: dto.beginDate,
  endDate: dto.endDate,
  comment: dto.comment,
  locationId: dto.locationId,
  customerId: dto.customerId,
  pricebookId: dto.pricebookId,
  customerProjectNo: dto.customerProjectNo,
  projectArticles: dto.projectArticles,
  fileId: dto.fileId
});
