export enum RequestTypes {
  Begin = 'requests/Begin',
  End = 'requests/End'
}
export type RequestAction =
  | { type: RequestTypes.Begin; request: string }
  | { type: RequestTypes.End; request: string };

export const requestBegin = (request: string): RequestAction => ({
  type: RequestTypes.Begin,
  request
});

export const requestEnd = (request: string): RequestAction => ({
  type: RequestTypes.End,
  request
});
