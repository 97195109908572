import { Dispatch } from 'redux';
import { LocationDto } from 'src/store/locations/location';
import { getRequest } from 'src/store/api-handler';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum LocationTypes {
  FetchAll = 'locations/Fetch',
  SetActive = 'locations/SetActive'
}

export type LocationAction =
  | { type: LocationTypes.FetchAll; locations: LocationDto[] }
  | { type: LocationTypes.SetActive; location: Nullable<LocationDto> };

export const actionCreators = {
  fetchLocations: (locations: LocationDto[]): LocationAction => ({
    type: LocationTypes.FetchAll,
    locations
  }),
  setActiveLocation: (location: LocationDto): LocationAction => ({
    type: LocationTypes.SetActive,
    location
  })
};

export const fetchLocations = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(LocationTypes.FetchAll));
    const { data } = await getRequest('/locations');
    dispatch(requestEnd(LocationTypes.FetchAll));
    return dispatch(actionCreators.fetchLocations(data));
  };
};

export const setActiveLocation = (location: LocationDto) => {
  return async (dispatch: Dispatch) => {
    dispatch(actionCreators.setActiveLocation(location));
  };
};
