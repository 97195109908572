import {
  PurchaseOrderDepartmentDto,
  PurchaseOrderDetailDto,
  PurchaseOrderStatusDto
} from 'src/store/purchaseorders/purchaseorder';
import { ArrayUtils } from 'src/utils/array';
import {
  PurchaseOrderAction,
  PurchaseOrderTypes
} from './purchaseorders.actions';

export type PurchaseOrdersState = Readonly<{
  purchaseOrder: Nullable<PurchaseOrderDetailDto>;
  purchaseOrders: PurchaseOrderDetailDto[];
  purchaseOrderDepartments: PurchaseOrderDepartmentDto[];
  purchaseOrderStatuses: PurchaseOrderStatusDto[];
  errors: any;
}>;

export const initialState: PurchaseOrdersState = {
  purchaseOrder: null,
  purchaseOrders: [],
  purchaseOrderDepartments: [],
  purchaseOrderStatuses: [],
  errors: null
};

const purchaseOrdersReducer = (
  state: PurchaseOrdersState = initialState,
  action: PurchaseOrderAction
) => {
  switch (action.type) {
    case PurchaseOrderTypes.Fetch:
      return { ...state, purchaseOrder: action.purchaseOrder };
    case PurchaseOrderTypes.FetchAll:
      return { ...state, purchaseOrders: action.purchaseOrders };
    case PurchaseOrderTypes.Delete:
      return {
        ...state,
        purchaseOrder: null,
        purchaseOrders: ArrayUtils.deleteFromListById(
          state.purchaseOrders,
          action.purchaseOrder
        )
      };
    case PurchaseOrderTypes.Failure:
      return { ...state, errors: action.errors };
    case PurchaseOrderTypes.FetchDepartments:
      return {
        ...state,
        purchaseOrderDepartments: action.purchaseOrderDepartments
      };
    case PurchaseOrderTypes.FetchStatuses:
      return { ...state, purchaseOrderStatuses: action.purchaseOrderStatuses };
    default:
      return state;
  }
};

export default purchaseOrdersReducer;
