import { Box, Grid } from '@material-ui/core';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ActionButton,
  SecondaryButton,
  TertiaryButton
} from 'src/components/shared/Button/Button';
import { DropdownCell } from 'src/components/shared/DataGrid/EditableGrid/DropdownCell';
import { EditableGrid } from 'src/components/shared/DataGrid/EditableGrid/EditableGrid';
import { RootState, useReduxDispatch } from 'src/store';
import { fetchArticlesByPricebook } from 'src/store/articles/articles.actions';
import { selectPricebookArticles } from 'src/store/articles/articles.selectors';
import { selectActiveProject } from 'src/store/projects/projects.selectors';
import {
  SettlementArticleDto,
  SettlementDetailDto
} from 'src/store/settlements/settlement';

interface Props {
  settlement: Nullable<SettlementDetailDto>;
  onArticlesChanged: (parameters: SettlementArticleDto[]) => void;
}

let uniqueId = 0;

export const SettlementArticles: React.FC<Props> = ({
  settlement,
  onArticlesChanged
}) => {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const [articles, setArticles] = useState<SettlementArticleDto[]>(
    settlement?.settlementArticles || []
  );

  const project = useSelector((state: RootState) =>
    selectActiveProject(state.projects)
  );

  const articles$ = useSelector((state: RootState) =>
    selectPricebookArticles(state.articles)
  );

  useEffect(() => {
    if (settlement) {
      setArticles(settlement.settlementArticles);
      dispatch(fetchArticlesByPricebook(project!.pricebookId));
    }
  }, [settlement, project, dispatch]);

  useEffect(() => {
    onArticlesChanged(articles);
  }, [articles, onArticlesChanged]);

  const onCancel = () => {
    setArticles([...(settlement?.settlementArticles ?? [])]);
  };

  const onAddArticle = () => {
    const article: SettlementArticleDto = {
      id: --uniqueId,
      settlementId: settlement?.id
    };
    setArticles([article, ...articles]);
  };

  const onDeleteArticle = (article: SettlementArticleDto) => {
    setArticles(articles.filter(a => a.id !== article.id));
  };

  // const onArticleChange = (article: ArticleDto) => {
  //   setArticles(
  //     articles.map(a => {
  //       if (a.id == article.id) {
  //         return { ...a, no: article.no };
  //       }
  //       return a;
  //     })
  //   );
  // };

  return (
    <Fragment>
      <Grid container className="cf-mb-6">
        <Grid item>
          <Box display="flex" className="cf-mb-6">
            <SecondaryButton
              icon="la-plus"
              onClick={onAddArticle}
              className="cf-mr-6"
            >
              {t('add.verb')}
            </SecondaryButton>
            <TertiaryButton onClick={onCancel}>{t('undo')}</TertiaryButton>
          </Box>
          <EditableGrid
            items={articles}
            onItemsChange={items => {
              setArticles(items);
            }}
          >
            <Column
              field="articleId"
              title={t('article.number')}
              width="300px"
              cell={props => (
                <DropdownCell
                  {...props}
                  items={articles$}
                  uniqueField="id"
                  optionField="no"
                />
              )}
            />
            <Column
              field="articleId"
              title={t('article.singular')}
              width="700px"
              cell={props => (
                <DropdownCell
                  {...props}
                  items={articles$}
                  uniqueField="id"
                  optionField="name"
                />
              )}
            />
            <Column
              width="60px"
              filterable={false}
              editable={false}
              cell={props => (
                <td>
                  <ActionButton
                    icon="la la-trash"
                    onClick={() => {
                      onDeleteArticle(props.dataItem);
                    }}
                  />
                </td>
              )}
            />
          </EditableGrid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
