import { GridColumn as Column } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Auxilary from 'src/components/hoc/Auxilary';
import {
  ActionButton,
  PrimaryButton
} from 'src/components/shared/Button/Button';
import { DataGrid } from 'src/components/shared/DataGrid/DataGrid';
import {
  HeaderSection,
  HeaderSectionLeft,
  HeaderSectionRight
} from 'src/components/shared/HeaderSection/HeaderSection';
import { Modal } from 'src/components/shared/Modal/Modal';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { RootState, useReduxDispatch } from 'src/store';
import { alertSuccess } from 'src/store/alerts/alerts.actions';
import { isLoadingSuppliers } from 'src/store/requests/requests.selectors';
import { SupplierDetailDto, SupplierDto } from 'src/store/suppliers/supplier';
import { mapSupplierDetailToEdit } from 'src/store/suppliers/supplier.map';
import {
  createSupplier,
  deleteSupplier,
  editSupplier,
  fetchSupplier,
  fetchSuppliers
} from 'src/store/suppliers/suppliers.actions';
import {
  selectSupplier,
  selectSuppliers
} from 'src/store/suppliers/suppliers.selectors';
import { SupplierForm } from './SupplierForm';

type Props = {};

export const SuppliersPage: React.FC<Props> = () => {
  const [currentSupplier, setCurrentSupplier] = useState<SupplierDto | null>(
    null
  );
  const [supplier, setSupplier] = useState<SupplierDetailDto>(
    {} as SupplierDetailDto
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { t } = useTranslation();

  const dispatch = useReduxDispatch();

  const supplier$ = useSelector((state: RootState) =>
    selectSupplier(state.suppliers)
  );

  const suppliers = useSelector((state: RootState) =>
    selectSuppliers(state.suppliers)
  );

  const loadingSuppliers = useSelector(isLoadingSuppliers);

  useEffect(() => {
    dispatch(fetchSuppliers());
  }, [dispatch]);

  useEffect(() => {
    if (currentSupplier) {
      setShowDeleteModal(true);
    }
  }, [currentSupplier]);

  useEffect(() => {
    if (supplier$) setSupplier(supplier$);
  }, [supplier$]);

  const onCancel = () => {
    setCurrentSupplier(null);
    setSupplier({} as SupplierDetailDto);
    setShowDeleteModal(false);
    setShowEditModal(false);
  };

  const onCreate = () => {
    setShowEditModal(true);
  };

  const onEdit = async (supplier: SupplierDto) => {
    await dispatch(fetchSupplier(supplier.id!));
    setShowEditModal(true);
  };

  const onDelete = (supplier: SupplierDto) => {
    setCurrentSupplier(supplier);
  };

  const onConfirmDelete = async () => {
    if (currentSupplier) {
      await dispatch(deleteSupplier(currentSupplier.id, currentSupplier));
      setCurrentSupplier(null);
      setShowDeleteModal(false);
    }
  };

  const onSubmit = async (supplier: SupplierDetailDto) => {
    const dto = mapSupplierDetailToEdit(supplier);
    if (dto.id) {
      await dispatch(editSupplier(dto.id, dto));
      onCancel();
      dispatch(alertSuccess(t('supplier.updated')));
    } else {
      await dispatch(createSupplier(dto));
      onCancel();
      dispatch(alertSuccess(t('supplier.created')));
      dispatch(fetchSuppliers());
    }
  };

  return (
    <Auxilary>
      <HeaderSection className="cf-mb-8">
        <HeaderSectionLeft>
          <Header1>{t('supplier.plural')}</Header1>
          <BodyType>{`${t('crudtext')} ${t('supplier.plural')}`}</BodyType>
        </HeaderSectionLeft>
        <HeaderSectionRight>
          <PrimaryButton onClick={onCreate}>
            {`${t('create')} ${t('supplier.singular')}`}
          </PrimaryButton>
        </HeaderSectionRight>
      </HeaderSection>
      <DataGrid
        items={suppliers}
        loading={loadingSuppliers}
        onRowClick={({ dataItem }) => onEdit(dataItem)}
      >
        <Column field="no" title={t('props.number')} />
        <Column field="name" title={t('props.name')} />
        <Column field="phone" title={t('props.phone')} filterable={false} />
        <Column
          field="address1"
          title={t('props.address')}
          filterable={false}
        />
        <Column field="zip" title={t('props.zip')} filterable={false} />
        <Column field="city" title={t('props.city')} filterable={false} />
        <Column
          width="100px"
          filterable={false}
          cell={(props) => (
            <td>
              <ActionButton
                icon="la la-trash"
                onClick={() => onDelete(props.dataItem)}
              />
            </td>
          )}
        />
      </DataGrid>

      <Modal
        header={`${t('create')} ${t('supplier.singular')}`}
        confirmText={t('save.verb')}
        onClose={() => onCancel()}
        open={showEditModal}
      >
        <SupplierForm
          supplier={supplier}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Modal>

      <Modal
        width={500}
        header={`${t('delete')} ${t('supplier.singular')}`}
        confirmText={t('delete')}
        onClose={() => onCancel()}
        onConfirm={() => onConfirmDelete()}
        open={showDeleteModal}
        showActions
      >
        <BodyType>
          {t('supplier.deleteConfirm')} <q>{currentSupplier?.name}</q>?
        </BodyType>
      </Modal>
    </Auxilary>
  );
};
