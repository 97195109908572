import { RootState } from '..';
import { ArticleTypes } from '../articles/articles.actions';
import { CargoTypes } from '../cargoes/cargoes.actions';
import { FileTypes } from '../files/files.actions';
import { FileTypeTypes } from '../filetypes/filetypes.actions';
import { LocationTypes } from '../locations/locations.actions';
import { ProjectTypes } from '../projects/projects.actions';
import { PurchaseOrderTypes } from '../purchaseorders/purchaseorders.actions';
import { SettlementTypes } from '../settlements/settlements.actions';
import { SupplierTypes } from '../suppliers/suppliers.actions';
import { UserTypes } from '../users/users.actions';

export const isLoadingUsers = (state: RootState) =>
  state.requests.inProgress.includes(UserTypes.FetchAll) &&
  state.users.users.length > 0;

export const isLoadingSuppliers = (state: RootState) =>
  state.requests.inProgress.includes(SupplierTypes.FetchAll) &&
  state.suppliers.suppliers.length > 0;

export const isLoadingProjects = (state: RootState) =>
  state.requests.inProgress.includes(ProjectTypes.FetchAll) &&
  state.projects.projects.length > 0;

export const isLoadingLocations = (state: RootState) =>
  state.requests.inProgress.includes(LocationTypes.FetchAll) &&
  state.locations.locations.length > 0;

export const isLoadingPurchaseOrders = (state: RootState) =>
  state.requests.inProgress.includes(PurchaseOrderTypes.FetchAll) &&
  state.purchaseOrders.purchaseOrders.length > 0;

export const isLoadingCargoes = (state: RootState) =>
  state.requests.inProgress.includes(CargoTypes.FetchFiltered);

export const isLoadingArticles = (state: RootState) =>
  state.requests.inProgress.includes(ArticleTypes.FetchAll);

export const isLoadingSettlements = (state: RootState) =>
  state.requests.inProgress.includes(SettlementTypes.FetchAll);

export const isLoadingFiles = (state: RootState) =>
  state.requests.inProgress.includes(FileTypes.FetchAll);

export const isLoadingFileTypes = (state: RootState) =>
  state.requests.inProgress.includes(FileTypeTypes.FetchAll);
