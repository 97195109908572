import { ArrayUtils } from 'src/utils/array';
import { UserDetailDto, UserDto } from './user';
import { UserAction, UserTypes } from './users.actions';

export enum Language {
  Norwegian = 'nb',
  Swedish = 'se',
  English = 'gb',
}

export type UsersState = Readonly<{
  user: Nullable<UserDetailDto>;
  users: UserDto[];
  roles: string[];
  errors: any;
  language: Language;
}>;

export const initialState: UsersState = {
  users: [],
  roles: [],
  user: null,
  errors: null,
  language: Language.Norwegian,
};

const usersReducer = (state: UsersState = initialState, action: UserAction) => {
  switch (action.type) {
    case UserTypes.Fetch:
      return { ...state, user: action.user };
    case UserTypes.FetchAll:
      return { ...state, users: action.users };
    case UserTypes.FetchRoles:
      return { ...state, roles: action.roles };
    case UserTypes.Delete:
      return {
        ...state,
        user: null,
        users: ArrayUtils.deleteFromListById(state.users, action.user),
      };
    case UserTypes.Failure:
      return { ...state, errors: action.errors };
    case UserTypes.ChangeLanguage:
      return { ...state, language: action.language };
    default:
      return state;
  }
};

export default usersReducer;
