import i18n from 'i18next';
import { Dispatch } from 'redux';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'src/store/api-handler';
import {
  SettlementDetailDto,
  SettlementDto,
  SettlementEditDto
} from 'src/store/settlements/settlement';
import { alertSuccess } from '../alerts/alerts.actions';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum SettlementTypes {
  Fetch = 'settlement/Fetch',
  FetchAll = 'settlement/FetchAll',
  Delete = 'settlement/Delete',
  Failure = 'settlement/Failure'
}

export type SettlementAction =
  | {
      type: SettlementTypes.Fetch;
      settlement: Nullable<SettlementDetailDto>;
    }
  | {
      type: SettlementTypes.FetchAll;
      settlements: SettlementDto[];
    }
  | { type: SettlementTypes.Delete; settlement: SettlementDto }
  | { type: SettlementTypes.Failure; errors: any };

export const actionCreators = {
  fetchSettlement: (
    settlement: Nullable<SettlementDetailDto>
  ): SettlementAction => ({
    type: SettlementTypes.Fetch,
    settlement: settlement
  }),
  fetchSettlements: (settlements: SettlementDto[]): SettlementAction => ({
    type: SettlementTypes.FetchAll,
    settlements
  }),
  deleteSettlement: (settlement: SettlementDto): SettlementAction => ({
    type: SettlementTypes.Delete,
    settlement: settlement
  }),
  settlementRequestFailure: (errors: any): SettlementAction => ({
    type: SettlementTypes.Failure,
    errors: errors
  })
};

export const fetchSettlement = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await getRequest(`/settlements/${id}`);
    return ok
      ? dispatch(actionCreators.fetchSettlement(data))
      : dispatch(actionCreators.settlementRequestFailure(error));
  };
};

export const fetchSettlements = (projectId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(SettlementTypes.FetchAll));
    const { data } = await getRequest(`/settlements/project/${projectId}`);
    dispatch(requestEnd(SettlementTypes.FetchAll));
    return dispatch(actionCreators.fetchSettlements(data));
  };
};

export const createSettlement = (settlement: SettlementEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await postRequest('/settlements', settlement);
    return ok
      ? dispatch(alertSuccess(i18n.t('settlement.created')))
      : dispatch(actionCreators.settlementRequestFailure(error));
  };
};

export const editSettlement = (id: number, settlement: SettlementEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await putRequest(`/settlements/${id}`, settlement);
    return ok
      ? dispatch(alertSuccess(i18n.t('settlement.updated')))
      : dispatch(actionCreators.settlementRequestFailure(error));
  };
};

export const deleteSettlement = (id: number, settlement: SettlementDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await deleteRequest(`/settlements/${id}`);
    return ok
      ? dispatch(actionCreators.deleteSettlement(settlement))
      : dispatch(actionCreators.settlementRequestFailure(error));
  };
};
