// @dynamic
export class ArrayUtils {
  /**
   *  Sorting a list of object based on a given property
   * @param array List of objects to be sorted
   * @param prop Property to sort on, may be specified with dot notation for nested properties
   * @param descending Conditional for ascending or descending sort
   */
  static sortByProperty(array: any[], prop: string, descending?: boolean) {
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    });
    return array.sort((a, b) => {
      let aVal = this.getValue(a, prop) || 0;
      let bVal = this.getValue(b, prop) || 0;

      if (aVal instanceof Date && bVal instanceof Date) {
        aVal = aVal.getTime();
        bVal = bVal.getTime();
      }

      if (descending) {
        const compareValue = collator.compare(aVal, bVal);
        if (compareValue > 0) return -1;
        if (compareValue < 0) return 1;
      } else {
        return collator.compare(aVal, bVal);
      }
      return 0;
    });
  }

  static getValue(obj: any, prop: string) {
    return prop.split('.').reduce((a, b) => {
      return a[b];
    }, obj);
  }

  /**
   * Returns updated list where entries that match element.id are replaced by element.
   * @param li List to be sorted and updated
   * @param element Element that will be searched for by id and that replaces all matches.
   */
  static updateListById<T extends { id?: string | number }>(
    li: T[],
    element: T
  ): T[] {
    return li.map(item => (item.id === element.id ? element : item));
  }

  /**
   * Returns new list with entries matching element.id removed.
   * @param li List from which element will be removed.
   * @param element Element to remove.
   */
  static deleteFromListById<T extends { id?: string | number }>(
    li: T[],
    element: T
  ): T[] {
    return li.filter(x => x.id !== element.id);
  }

  /**
   *  Filter a list of object based on a given property and search text
   * @param array List of objects to be sorted
   * @param prop Property to filter on
   * @param searchText Search text to filter by
   */
  static filterByProperty(array: any[], prop: string, searchValue: any) {
    return array.filter(item => {
      if (typeof searchValue === 'string' && typeof item[prop] === 'string') {
        return item[prop].toLowerCase().search(searchValue.toLowerCase()) > -1;
      }
      return item[prop] === searchValue;
    });
  }
}
