import React from 'react';
import { useTranslation } from 'react-i18next';
import { ElementLabelDto } from 'src/store/elements/element';
import { MouldLabelDto } from 'src/store/moulds/mould';
import images from './Images';

type Props = {
  mould: MouldLabelDto;
  element: ElementLabelDto;
};

const MouldLabel: React.FC<Props> = props => {
  const { mould, element } = props;

  const { t } = useTranslation();

  //Format props
  const elementNo = element.no.trim();
  const projectName = element.projectName.trim();
  const weight = new Intl.NumberFormat('nb-NO').format(element.weight);
  const cargoWeight = new Intl.NumberFormat('nb-NO').format(
    element.cargoWeight
  );
  let elementTypeNo = mould.elementType.no;

  //Setup dates
  let date = '';
  const dateString = mould.regDate;
  if (dateString !== null) {
    date = new Intl.DateTimeFormat('nb-NO').format(new Date(dateString));
  }

  //Setup images
  const bbcMark = images[0];
  const ceMark = images[1];
  const kMark = images[2];
  let certMark = kMark;
  let logo = null;
  switch (mould.location.id) {
    case 10:
      logo = images[3];
      break;
    default:
      logo = images[4];
      break;
  }

  //Location customizations
  if (mould.location.id === 6) {
    if (elementTypeNo) {
      elementTypeNo = elementTypeNo.replace('NS-', '');
    }
    if (bbcMark) {
      certMark = bbcMark;
    }
  }

  const maxSize = 50;
  const maxWidth = 600;
  let h2Size = maxWidth / (projectName.length + elementNo.length);
  h2Size = h2Size <= maxSize ? h2Size : maxSize;
  let h1Size = h2Size * (1 + 1 / (elementNo.length - 1));
  h1Size = h1Size <= maxSize ? h1Size : maxSize;

  return (
    <table className="element-label">
      <tbody>
        <tr>
          <td className="left" rowSpan={3} valign="top">
            <img src={logo} alt="logo" />
            <br />
            <strong>{mould.location.name}</strong>
            <br />
            {mould.location.address1}
            <br />
            {mould.location.zip} {mould.location.city}
          </td>
          <td className="project-no">
            <span>
              {t('project.singular')} {element.projectNo}
            </span>
          </td>
          <td className="section">
            <span>
              {element.buildingName}/{element.floorName}/{element.sectionName}
            </span>
          </td>
          <td className="regdate">
            <span>{date}</span>
          </td>
          <td className="marks" rowSpan={3} width="30">
            <img src={certMark} alt="Cert" />
            <br />
            {!mould.elementType.no.includes('NS-EN 13369') && (
              <img src={ceMark} alt="CE" />
            )}
          </td>
          <td className="right" rowSpan={3}>
            {mould.location.certificate}
            <br />
            {mould.location.firstApproval}
            <br />
            {elementTypeNo}
            <br />
            {mould.location.id !== 6 && mould.elementType.name}
            {mould.location.id === 6 &&
              mould.elementType.name.includes('Dekke') && (
                <span>
                  Plattbrlag
                  <br />
                  DoP Plattbärlag
                </span>
              )}

            {mould.location.id === 6 &&
              mould.elementType.name.includes('Vegg') && (
                <span>
                  Skalvägg
                  <br />
                  DoP Skalvägg
                </span>
              )}
          </td>
        </tr>
        <tr>
          <td className="project-name" colSpan={3} valign="middle">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <h1 style={{ fontSize: h1Size }}>{projectName}</h1>
              <h2 style={{ fontSize: h2Size }}>{elementNo}</h2>
            </div>
          </td>
        </tr>
        <tr>
          <td align="left">
            <span>
              {t('props.weight')} <strong>{weight}</strong>
            </span>
          </td>
          <td align="center">
            <span>
              {t('props.cargoNo')} <strong>{element.cargoNo}</strong>
            </span>
          </td>
          <td align="right">
            <span>
              {t('props.cargoWeight')} <strong>{cargoWeight}</strong>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default MouldLabel;
