import { Box } from '@material-ui/core';
import {
  DataSourceRequestState,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps
} from '@progress/kendo-react-dropdowns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Auxilary from 'src/components/hoc/Auxilary';
import { PrimaryButton } from 'src/components/shared/Button/Button';
import { RootState } from 'src/store';
import {
  CollectionDto,
  CollectionLabelDto
} from 'src/store/collections/collection';
import {
  fetchCollectionLabelData,
  fetchFilteredCollections
} from 'src/store/collections/collections.actions';
import { LocationDto } from 'src/store/locations/location';
import CollectionLabelList from './CollectionLabelList';
import './Labels.css';
import { updateRequestFilter } from './LabelsPage';

type Props = {
  collectionLabelData: CollectionLabelDto;
  collections: CollectionDto[];
  location: LocationDto;
  loading: boolean;
  fetchFilteredCollections: (queryStr: string) => void;
  fetchCollectionLabelData: (id: number) => void;
  printPDF: (element: HTMLElement, fileName: string) => void;
};

const CollectionsSection: React.FC<Props> = props => {
  const {
    location,
    loading,
    collectionLabelData,
    fetchFilteredCollections,
    fetchCollectionLabelData,
    printPDF
  } = props;

  const [collections, setCollections] = useState<CollectionDto[]>([]);
  const [collection, setCollection] = useState<CollectionDto | null>(null);
  const [collectionDataState, setCollectionDataState] = useState<
    Nullable<DataSourceRequestState>
  >(null);

  const { t } = useTranslation();
  const collectionList = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCollections(props.collections);
  }, [props.collections]);

  useEffect(() => {
    setCollection(null);
    setCollectionDataState({
      skip: 0,
      take: 20,
      filter: {
        filters: [
          {
            field: 'locationId',
            operator: 'eq',
            value: location.id
          }
        ],
        logic: 'and'
      }
    });
  }, [location]);

  const fetchCollections = useCallback(
    (dataState: DataSourceRequestState) => {
      const queryStr = `${toDataSourceRequestString(dataState)}`;
      fetchFilteredCollections(queryStr);
    },
    [fetchFilteredCollections]
  );

  useEffect(() => {
    if (collectionDataState) {
      fetchCollections(collectionDataState);
    }
  }, [fetchCollections, collectionDataState]);

  const onCollectionFilterChange = (event: ComboBoxFilterChangeEvent) => {
    if (event.nativeEvent instanceof FocusEvent) return;
    setCollectionDataState(
      updateRequestFilter(collectionDataState!, event.filter, 'no')
    );
  };

  const onCollectionChange = (event: ComboBoxChangeEvent) => {
    if (event.nativeEvent instanceof FocusEvent) return;
    const collection: CollectionDto = event.value;
    setCollection(collection);
    if (collection) fetchCollectionLabelData(collection.id);
  };

  const printCollections = () => {
    if (collectionList.current && collection) {
      printPDF(
        collectionList.current,
        `${t('label.plural')}-${collection?.no}.pdf`
      );
    }
  };

  const collectionItem = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const collection: CollectionDto = itemProps.dataItem;
    return React.cloneElement(
      li,
      li.props,
      <span>
        {collection.no} {collection.comment}
      </span>
    );
  };

  return (
    <Auxilary>
      <Box
        className="cf-mb-8"
        display="flex"
        justifyContent="between"
        alignItems="flex-end"
      >
        <ComboBox
          placeholder={`${t('select')} ${t('collection.singular')}`}
          dataItemKey="id"
          textField="no"
          suggest
          filterable
          data={collections}
          value={collection}
          onChange={onCollectionChange}
          onFilterChange={onCollectionFilterChange}
          loading={loading}
          itemRender={collectionItem}
          className="cf-mr-8"
        />
        <PrimaryButton disabled={!collection} onClick={printCollections}>
          {t('print')}
        </PrimaryButton>
      </Box>

      {collection &&
        collectionLabelData &&
        collectionLabelData.elements?.length !== 0 && (
          <CollectionLabelList
            collectionLabelData={collectionLabelData}
            ref={collectionList}
          />
        )}
    </Auxilary>
  );
};

const mapStateToProps = (state: RootState) => ({
  location: state.locations.activeLocation,
  collections: state.collections.filteredCollections.data,
  loading: false,
  collectionLabelData: state.collections.collectionLabelData
});

export default connect(mapStateToProps, {
  fetchFilteredCollections,
  fetchCollectionLabelData
})(CollectionsSection);
