import izitoast from 'izitoast';
import {
  User as OidcUser,
  UserManager,
  UserManagerSettings
} from 'oidc-client';
import { Dispatch } from 'redux';
import { getRequest } from '../api-handler';
import { actionCreators as locationActionCreators } from '../locations/locations.actions';
import { UserDetailDto } from '../users/user';

export enum AuthTypes {
  LoginRequest = 'auth/LoginRequest',
  LoginSuccess = 'auth/LoginSuccess',
  LoginFailure = 'auth/LoginFailure',
  LogoutSuccess = 'auth/LogoutSuccess',
  UpdateCurrentUser = 'auth/UpdateCurrentUser'
}

export type AuthAction =
  | { type: AuthTypes.LoginRequest }
  | { type: AuthTypes.LoginSuccess; oidcUser: OidcUser }
  | { type: AuthTypes.LoginFailure; error: Nullable<object> }
  | { type: AuthTypes.LogoutSuccess }
  | { type: AuthTypes.UpdateCurrentUser; user: UserDetailDto };

export const actionCreators = {
  authRequest: (): AuthAction => ({
    type: AuthTypes.LoginRequest
  }),
  authSuccess: (oidcUser: OidcUser): AuthAction => ({
    type: AuthTypes.LoginSuccess,
    oidcUser: oidcUser
  }),
  authFailure: (error: Nullable<object>): AuthAction => ({
    type: AuthTypes.LoginFailure,
    error: error
  }),
  authLogout: (): AuthAction => ({
    type: AuthTypes.LogoutSuccess
  }),
  updateCurrentUser: (user: UserDetailDto): AuthAction => ({
    type: AuthTypes.UpdateCurrentUser,
    user: user
  })
};

export const getClientSettings = (): UserManagerSettings => ({
  authority: process.env.REACT_APP_AUTH,
  client_id: 'conpro-spa',
  redirect_uri: `${process.env.REACT_APP_APP}/login-complete`,
  post_logout_redirect_uri: process.env.REACT_APP_APP,
  response_type: 'code',
  scope: 'openid profile email conpro-api.manage roles permissions',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true
});

export const userManager = new UserManager(getClientSettings());

export const validateAuth = () => {
  return async (dispatch: Dispatch) => {
    return userManager.getUser().then(async oidcUser => {
      if (oidcUser) {
        const user = await getCurrentUser();
        if (!user) {
          return dispatch(actionCreators.authFailure(null));
        }

        if (!user.allLocations && user.location) {
          dispatch(locationActionCreators.setActiveLocation(user.location));
        }

        dispatch(actionCreators.authSuccess(oidcUser));
        return dispatch(actionCreators.updateCurrentUser(user));
      }
      return dispatch(actionCreators.authLogout());
    });
  };
};

export const startAuthentication = () => {
  return async (dispatch: Dispatch) => {
    userManager.signinRedirect().then(() => {
      return dispatch(actionCreators.authRequest());
    });
  };
};

export const completeAuthentication = () => {
  return async (dispatch: Dispatch) => {
    dispatch(actionCreators.authRequest());
    return userManager.getUser().then(async oidcUser => {
      if (!oidcUser) {
        oidcUser = await userManager.signinRedirectCallback();
      }
      if (oidcUser) {
        const user = await getCurrentUser();
        if (!user) {
          return dispatch(actionCreators.authFailure(null));
        }

        if (!user.allLocations && user.location) {
          dispatch(locationActionCreators.setActiveLocation(user.location));
        }

        dispatch(actionCreators.authSuccess(oidcUser));
        return dispatch(actionCreators.updateCurrentUser(user));
      }
      return dispatch(actionCreators.authLogout());
    });
  };
};

export const logoutOidc = () => {
  return async (dispatch: Dispatch) => {
    userManager.signoutRedirect().then(() => {
      return dispatch(actionCreators.authLogout());
    });
  };
};

export const renewToken = (): Promise<OidcUser> => {
  return userManager.signinSilent();
};

export const fetchCurrentUser = () => {
  return async (dispatch: Dispatch) => {
    const user = await getCurrentUser();

    if (!user?.allLocations && user?.location) {
      dispatch(locationActionCreators.setActiveLocation(user.location));
    }

    if (!user) {
      return dispatch(actionCreators.authFailure(null));
    }

    return dispatch(actionCreators.updateCurrentUser(user));
  };
};

const getCurrentUser = async (): Promise<Nullable<UserDetailDto>> => {
  const { ok, data, error } = await getRequest('/users/current');
  if (ok) {
    return data as UserDetailDto;
  }
  console.log(error);
  izitoast.error({ message: 'Authentication failed', position: 'bottomRight' });
  return null;
};
