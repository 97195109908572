export enum AlertTypes {
  Success = 'alerts/Success',
  Error = 'alerts/Error',
  Info = 'alerts/Info'
}

export type AlertAction =
  | { type: AlertTypes.Success; text: string }
  | { type: AlertTypes.Error; text: string }
  | { type: AlertTypes.Info; text: string };

export const alertSuccess = (text: string): AlertAction => ({
  type: AlertTypes.Success,
  text: text
});

export const alertError = (text: string): AlertAction => ({
  type: AlertTypes.Error,
  text: text
});

export const alertInfo = (text: string): AlertAction => ({
  type: AlertTypes.Info,
  text: text
});
