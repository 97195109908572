import { FileDetailDto, FileDto } from 'src/store/files/file';
import { ArrayUtils } from 'src/utils/array';
import { FileAction, FileTypes } from './files.actions';

export type FilesState = Readonly<{
  files: FileDto[];
  file: Nullable<FileDetailDto>;
  base64pdf: string;
  errors: any;
}>;

const initialState: FilesState = {
  files: [],
  file: null,
  base64pdf: '',
  errors: null
};

const filesReducer = (state: FilesState = initialState, action: FileAction) => {
  switch (action.type) {
    case FileTypes.Fetch:
      return { ...state, file: action.file };
    case FileTypes.FetchAll:
      return { ...state, files: action.files };
    case FileTypes.Show:
      return { ...state, base64pdf: action.base64pdf };
    // case FileTypes.Update:
    //   return {
    //     ...state,
    //     files: ArrayUtils.updateListById(state.files, action.file)
    //   };
    case FileTypes.Delete:
      return {
        ...state,
        file: null,
        files: ArrayUtils.deleteFromListById(state.files, action.file)
      };
    case FileTypes.Failure:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
};

export default filesReducer;
