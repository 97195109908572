import React, { Fragment } from 'react';
import { Waybill } from './Waybill';
import { CargoWaybillDto } from 'src/store/cargoes/cargo';

type Props = {
  waybill: Nullable<CargoWaybillDto>;
  showInstructions: boolean;
  pages: number;
  waybillRef: React.RefObject<HTMLDivElement>;
};

export const WaybillList: React.FC<Props> = props => {
  const { waybill, showInstructions, pages, waybillRef } = props;

  return (
    <div className="waybills" ref={waybillRef}>
      {waybill && (
        <Fragment>
          {Array(pages)
            .fill(1)
            .map((_, index) => (
              <Waybill
                key={index}
                waybill={waybill}
                page={index + 1}
                showInstructions={showInstructions}
              />
            ))}
        </Fragment>
      )}
    </div>
  );
};
