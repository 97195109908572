import React, { useRef, useEffect, useCallback } from 'react';
import Auxilary from 'src/components/hoc/Auxilary';
import styled, { keyframes } from 'styled-components';

type Props = {
  active: boolean;
  width?: number;
  top?: number;
  onClose: () => void;
};

export const Dropdown: React.FC<Props> = props => {
  const { active, width, top, onClose, children } = props;

  const dropdownContainer = useRef<HTMLDivElement>(null);

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      const listContainer = document.getElementsByClassName(
        'k-list-container'
      )[0];
      if (
        !dropdownContainer.current?.contains(event.target as Node) &&
        !listContainer
      ) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (active) {
      window.addEventListener('mousedown', handleOutsideClick);
    } else {
      window.removeEventListener('mousedown', handleOutsideClick);
    }
  }, [handleOutsideClick, active]);

  return (
    <Auxilary>
      {active ? (
        <MenuContainer ref={dropdownContainer}>
          <DropdownMenu
            onClick={() => {}}
            style={{
              width,
              top
            }}
          >
            {children}
          </DropdownMenu>
        </MenuContainer>
      ) : null}
    </Auxilary>
  );
};

Dropdown.defaultProps = {
  width: 200,
  top: 30
};

const slideDown = keyframes`
  from {
    transform: translatey(-10px);
  }
  to {
    transform: translatey(0px);
}`;

const MenuContainer = styled.div`
  position: relative;
`;

const DropdownMenu = styled.ul`
  box-shadow: 0px 0px 32px rgba(2, 64, 90, 0.16);
  background-color: #fff;
  list-style-type: none;
  margin: 0;
  padding: 8px 0;
  border-radius: 8px;
  border-top-right-radius: 0;
  position: absolute;
  right: 0;
  top: 16px;
  z-index: 1000;
  box-sizing: content-box;
  cursor: pointer;
  animation: ${slideDown} 0.2s ease-out;
`;

export const DropdownItem = styled.li`
  height: 2.5rem;
  width: 100%;
  color: var(--primary-color);
  font-family: Roboto;
  font-weight: normal;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0.25rem 1.5rem;

  &:hover {
    background: rgba(var(--primary-color-rgb), 0.1);
  }
`;

export const DropdownDivider = styled.hr`
  border-top: 1px solid rgba(var(--primary-color-rgb), 0.4);
  margin: 8px 0;
`;
