import i18n from 'i18next';
import { Dispatch } from 'redux';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'src/store/api-handler';
import {
  FileTypeDetailDto,
  FileTypeDto,
  FileTypeEditDto
} from 'src/store/filetypes/filetype';
import { alertError, alertSuccess } from '../alerts/alerts.actions';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum FileTypeTypes {
  Fetch = 'fileTypes/Fetch',
  FetchAll = 'fileTypes/FetchAll',
  Update = 'fileTypes/Update',
  Delete = 'fileTypes/Delete',
  Failure = 'fileTypes/Failure'
}

export type FileTypeAction =
  | { type: FileTypeTypes.Fetch; fileType: Nullable<FileTypeDetailDto> }
  | { type: FileTypeTypes.FetchAll; fileTypes: FileTypeDto[] }
  | { type: FileTypeTypes.Update; fileType: FileTypeDto }
  | { type: FileTypeTypes.Delete; fileType: FileTypeDto }
  | { type: FileTypeTypes.Failure; errors: any };

export const actionCreators = {
  fetchFileType: (fileType: FileTypeDetailDto): FileTypeAction => ({
    type: FileTypeTypes.Fetch,
    fileType: fileType
  }),
  fetchFileTypes: (fileTypes: FileTypeDto[]): FileTypeAction => ({
    type: FileTypeTypes.FetchAll,
    fileTypes
  }),
  updateFileType: (fileType: FileTypeDto): FileTypeAction => ({
    type: FileTypeTypes.Update,
    fileType
  }),
  deleteFileType: (fileType: FileTypeDto): FileTypeAction => ({
    type: FileTypeTypes.Delete,
    fileType
  }),
  fileTypeRequestFailure: (errors: any): FileTypeAction => ({
    type: FileTypeTypes.Failure,
    errors
  })
};

export const fetchFileType = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await getRequest(`/filetypes/${id}`);
    return ok
      ? dispatch(actionCreators.fetchFileType(data))
      : dispatch(actionCreators.fileTypeRequestFailure(error));
  };
};

export const fetchFileTypes = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FileTypeTypes.FetchAll));
    const { data } = await getRequest('/filetypes');
    dispatch(requestEnd(FileTypeTypes.FetchAll));
    return dispatch(actionCreators.fetchFileTypes(data));
  };
};

export const createFileType = (fileType: FileTypeEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await postRequest('/filetypes', fileType);
    fileType.id = data;
    return ok
      ? dispatch(actionCreators.fetchFileType(fileType as FileTypeDetailDto))
      : dispatch(actionCreators.fileTypeRequestFailure(error));
  };
};

export const editFileType = (id: number, fileType: FileTypeEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await putRequest(`/filetypes/${id}`, fileType);
    return ok
      ? dispatch(actionCreators.updateFileType(fileType))
      : dispatch(actionCreators.fileTypeRequestFailure(error));
  };
};

export const deleteFileType = (id: number, fileType: FileTypeDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await deleteRequest(`/filetypes/${id}`, fileType);
    if (ok) {
      dispatch(alertSuccess(i18n.t('fileType.deleted')));
      return dispatch(actionCreators.deleteFileType(fileType));
    } else {
      dispatch(alertError(i18n.t('fileType.deleteFailed')));
      return dispatch(actionCreators.fileTypeRequestFailure(error));
    }
  };
};
