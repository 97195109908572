import { Dispatch } from 'redux';
import { FloorDto } from 'src/store/floors/floor';
import { getRequest } from 'src/store/api-handler';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum FloorTypes {
  FetchAll = 'floors/FetchAll'
}

export type FloorAction = {
  type: FloorTypes.FetchAll;
  floors: FloorDto[];
};

export const actionCreators = {
  fetchFloors: (floors: FloorDto[]): FloorAction => ({
    type: FloorTypes.FetchAll,
    floors
  })
};

export const fetchFloors = (buildingId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(FloorTypes.FetchAll));
    const { data } = await getRequest(`/floors/building/${buildingId}`);
    dispatch(requestEnd(FloorTypes.FetchAll));
    return dispatch(actionCreators.fetchFloors(data));
  };
};
