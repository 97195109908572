import styled from 'styled-components';

export const Header1 = styled.h1`
  font-family: 'Open Sans';
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  color: inherit;
  margin-bottom: 0rem;
  position: relative;
`;
