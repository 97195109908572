import { ElementTypeDto } from 'src/store/elements/element';
import { ElementAction, ElementTypes } from './elements.actions';

export type ElementsState = Readonly<{
  readonly elementTypes: ElementTypeDto[];
}>;

export const initialState: ElementsState = {
  elementTypes: []
};

const elementReducer = (
  state: ElementsState = initialState,
  action: ElementAction
) => {
  switch (action.type) {
    case ElementTypes.FetchElementTypes:
      return { ...state, elementTypes: action.elementTypes };
    default:
      return state;
  }
};

export default elementReducer;
