import instructions from 'src/assets/images/instructions/instructions.png';
import logo from 'src/assets/images/logos/logo-mono.png';
import logoBE from 'src/assets/images/logos/logo-10.png';
import signature1 from 'src/assets/images/signatures/signature-1.png';
import signature3 from 'src/assets/images/signatures/signature-3.png';

export default {
  logo,
  logoBE,
  instructions,
  ['signature-1']: signature1,
  ['signature-3']: signature3,
};
