import { BuildingDto } from 'src/store/buildings/building';
import {
  BuildingAction,
  BuildingTypes
} from 'src/store/buildings/buildings.actions';

export type BuildingsState = Readonly<{
  buildings: BuildingDto[];
}>;

const initialState: BuildingsState = {
  buildings: []
};

const buildingsReducer = (
  state: BuildingsState = initialState,
  action: BuildingAction
): BuildingsState => {
  switch (action.type) {
    case BuildingTypes.FetchAll:
      return { ...state, buildings: action.buildings };
    default:
      return state;
  }
};

export default buildingsReducer;
