import { DataResult } from '@progress/kendo-data-query';
import {
  CargoDto,
  CargoStatus,
  CargoWaybillDto
} from 'src/store/cargoes/cargo';
import { CargoAction, CargoTypes } from './cargoes.actions';

export type CargoesState = Readonly<{
  cargo: CargoDto | null;
  cargoes: CargoDto[];
  filteredCargoes: DataResult;
  cargoStatuses: CargoStatus[];
  cargoWaybillData: CargoWaybillDto | null;
}>;

export const initialState: CargoesState = {
  cargo: null,
  cargoes: [],
  filteredCargoes: { data: [], total: 0 },
  cargoStatuses: [],
  cargoWaybillData: null
};

const cargoesReducer = (
  state: CargoesState = initialState,
  action: CargoAction
) => {
  switch (action.type) {
    case CargoTypes.Fetch:
      return { ...state, cargo: action.cargo };
    case CargoTypes.FetchAll:
      return { ...state, cargoes: action.cargoes };
    case CargoTypes.FetchFiltered:
      return { ...state, filteredCargoes: action.filteredCargoes };
    case CargoTypes.FetchStatues:
      return { ...state, cargoStatuses: action.cargoStatuses };
    case CargoTypes.FetchWaybillData:
      return { ...state, cargoWaybillData: action.cargoWaybillData };
    default:
      return state;
  }
};

export default cargoesReducer;
