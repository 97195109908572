import i18n from 'i18next';
import { Dispatch } from 'redux';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from 'src/store/api-handler';
import {
  ProjectDetailDto,
  ProjectDto,
  ProjectEditDto
} from 'src/store/projects/project';
import { alertSuccess } from '../alerts/alerts.actions';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum ProjectTypes {
  SetActive = 'projects/SetActive',
  Fetch = 'project/Fetch',
  FetchAll = 'project/FetchAll',
  Delete = 'project/Delete',
  Failure = 'project/Failure'
}

export type ProjectAction =
  | { type: ProjectTypes.SetActive; project: Nullable<ProjectDto> }
  | {
      type: ProjectTypes.Fetch;
      project: Nullable<ProjectDetailDto>;
    }
  | {
      type: ProjectTypes.FetchAll;
      projects: ProjectDto[];
    }
  | { type: ProjectTypes.Delete; project: ProjectDto }
  | { type: ProjectTypes.Failure; errors: any };

export const actionCreators = {
  setActiveProject: (project: Nullable<ProjectDto>): ProjectAction => ({
    type: ProjectTypes.SetActive,
    project
  }),
  fetchProject: (project: Nullable<ProjectDetailDto>): ProjectAction => ({
    type: ProjectTypes.Fetch,
    project: project
  }),
  fetchProjects: (projects: ProjectDto[]): ProjectAction => ({
    type: ProjectTypes.FetchAll,
    projects
  }),
  deleteProject: (project: ProjectDto): ProjectAction => ({
    type: ProjectTypes.Delete,
    project: project
  }),
  projectRequestFailure: (errors: any): ProjectAction => ({
    type: ProjectTypes.Failure,
    errors: errors
  })
};

export const setActiveProject = (project: ProjectDto | null) => {
  return async (dispatch: Dispatch) => {
    dispatch(actionCreators.setActiveProject(project));
  };
};

export const fetchProject = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await getRequest(`/projects/${id}`);
    return ok
      ? dispatch(actionCreators.fetchProject(data))
      : dispatch(actionCreators.projectRequestFailure(error));
  };
};

export const fetchProjects = (locationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(ProjectTypes.FetchAll));
    const { data } = await getRequest(`/projects/location/${locationId}`);
    dispatch(requestEnd(ProjectTypes.FetchAll));
    return dispatch(actionCreators.fetchProjects(data));
  };
};

export const createProject = (project: ProjectEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await postRequest('/projects', project);
    return ok
      ? dispatch(alertSuccess(i18n.t('project.created')))
      : dispatch(actionCreators.projectRequestFailure(error));
  };
};

export const editProject = (id: number, project: ProjectEditDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await putRequest(`/projects/${id}`, project);
    return ok
      ? dispatch(alertSuccess(i18n.t('project.updated')))
      : dispatch(actionCreators.projectRequestFailure(error));
  };
};

export const deleteProject = (id: number, project: ProjectDto) => {
  return async (dispatch: Dispatch) => {
    const { ok, error } = await deleteRequest(`/projects/${id}`);
    return ok
      ? dispatch(actionCreators.deleteProject(project))
      : dispatch(actionCreators.projectRequestFailure(error));
  };
};
