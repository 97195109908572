import React from 'react';

type Props = {
  className?: string;
  type: string;
  size?: string;
};

export const Icon: React.FC<Props> = ({ type, className, size }) => {
  return (
    <i
      className={`las ${type} ${className ? className : null}`}
      style={{ fontSize: size ?? '1.2rem' }}
    />
  );
};
