import { FileTypeDetailDto, FileTypeDto } from 'src/store/filetypes/filetype';
import { ArrayUtils } from 'src/utils/array';
import { FileTypeAction, FileTypeTypes } from './filetypes.actions';

export type FileTypesState = Readonly<{
  fileTypes: FileTypeDto[];
  fileType: Nullable<FileTypeDetailDto>;
  errors: any;
}>;

const initialState: FileTypesState = {
  fileTypes: [],
  fileType: null,
  errors: null
};

const fileTypesReducer = (
  state: FileTypesState = initialState,
  action: FileTypeAction
) => {
  switch (action.type) {
    case FileTypeTypes.Fetch:
      return { ...state, fileType: action.fileType };
    case FileTypeTypes.FetchAll:
      return { ...state, fileTypes: action.fileTypes };
    case FileTypeTypes.Update:
      return {
        ...state,
        fileTypes: ArrayUtils.updateListById(state.fileTypes, action.fileType)
      };
    case FileTypeTypes.Delete:
      return {
        ...state,
        fileType: null,
        fileTypes: ArrayUtils.deleteFromListById(
          state.fileTypes,
          action.fileType
        )
      };
    case FileTypeTypes.Failure:
      return { ...state, errors: action.errors };
    default:
      return state;
  }
};

export default fileTypesReducer;
