import { DataResult } from '@progress/kendo-data-query';
import { Dispatch } from 'redux';
import {
  CargoDto,
  CargoStatus,
  CargoWaybillDto
} from 'src/store/cargoes/cargo';
import { getRequest } from 'src/store/api-handler';
import { requestBegin, requestEnd } from '../requests/requests.actions';

export enum CargoTypes {
  Fetch = 'cargoes/Fetch',
  FetchAll = 'cargoes/FetchAll',
  FetchFiltered = 'cargoes/FetchFiltered',
  FetchTypes = 'cargoes/FetchTypes',
  FetchStatues = 'cargoes/FetchStatues',
  FetchWaybillData = 'cargoes/FetchWaybillData',
  Failure = 'cargoes/Failure'
}

export type CargoAction =
  | { type: CargoTypes.Fetch; cargo: Nullable<CargoDto> }
  | { type: CargoTypes.FetchAll; cargoes: CargoDto[] }
  | { type: CargoTypes.FetchFiltered; filteredCargoes: DataResult }
  | { type: CargoTypes.FetchStatues; cargoStatuses: CargoStatus[] }
  | { type: CargoTypes.FetchWaybillData; cargoWaybillData: CargoWaybillDto }
  | { type: CargoTypes.Failure; errors: any };

export const actionCreators = {
  fetchCargo: (cargo: CargoDto): CargoAction => ({
    type: CargoTypes.Fetch,
    cargo
  }),
  fetchCargoes: (cargoes: CargoDto[]): CargoAction => ({
    type: CargoTypes.FetchAll,
    cargoes
  }),
  fetchFilteredCargoes: (filteredCargoes: DataResult): CargoAction => ({
    type: CargoTypes.FetchFiltered,
    filteredCargoes
  }),
  fetchCargoStatuses: (cargoStatuses: CargoStatus[]): CargoAction => ({
    type: CargoTypes.FetchStatues,
    cargoStatuses
  }),
  fetchCargoWaybillData: (cargoWaybillData: CargoWaybillDto): CargoAction => ({
    type: CargoTypes.FetchWaybillData,
    cargoWaybillData
  }),
  cargoRequestFailure: (errors: any): CargoAction => ({
    type: CargoTypes.Failure,
    errors
  })
};

export const fetchCargo = (id: number) => {
  return async (dispatch: Dispatch) => {
    const { ok, data, error } = await getRequest(`/cargoes/${id}`);
    return ok
      ? dispatch(actionCreators.fetchCargo(data))
      : dispatch(actionCreators.cargoRequestFailure(error));
  };
};

export const fetchCargoes = (locationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(CargoTypes.FetchAll));
    const { data } = await getRequest(`/cargoes/location/${locationId}`);
    dispatch(requestEnd(CargoTypes.FetchAll));
    return dispatch(actionCreators.fetchCargoes(data));
  };
};

export function fetchFilteredCargoes(queryStr: string) {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(CargoTypes.FetchFiltered));
    const { data } = await getRequest(`/cargoes/filtered?${queryStr}`);
    dispatch(requestEnd(CargoTypes.FetchFiltered));
    return dispatch(actionCreators.fetchFilteredCargoes(data));
  };
}

export const fetchCargoStatuses = () => {
  return async (dispatch: Dispatch) => {
    const { data } = await getRequest(`/cargoestatuses`);
    return dispatch(actionCreators.fetchCargoStatuses(data));
  };
};

export const fetchCargoWaybillData = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(requestBegin(CargoTypes.FetchWaybillData));
    const { data } = await getRequest(`/cargoes/${id}/waybilldata`);
    dispatch(requestEnd(CargoTypes.FetchWaybillData));
    return dispatch(actionCreators.fetchCargoWaybillData(data));
  };
};
