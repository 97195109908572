import { History } from 'history';
import qs from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { PageLoader } from 'src/components/shared/Loader/PageLoader';
import { useDidMount } from 'src/hooks/useDidMount';
import { completeAuthentication } from 'src/store/auth/auth.actions';

interface Props {
  history: History;
  completeAuthentication: (dispatch?: Dispatch<AnyAction>) => Promise<any>;
}

const LoginCallback = (props: Props) => {
  const { completeAuthentication, history } = props;

  const successCallback = () => {
    history.push('/');
  };

  const errorCallback = (error: Error) => {
    console.log(error);
    history.push('/');
  };

  useDidMount(() => {
    const params = qs.parse(history.location.search);
    if (params.code) {
      completeAuthentication()
        .then(() => successCallback())
        .catch((error: Error) => errorCallback(error));
    }
  }, []);

  return <PageLoader />;
};

export default connect(null, { completeAuthentication })(LoginCallback);
