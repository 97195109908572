import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import nb from 'src/assets/translations/nb.json';
import se from 'src/assets/translations/se.json';
import gb from 'src/assets/translations/gb.json';

export const initializeI18n = () => {
  i18n.use(initReactI18next).init({
    lng: localStorage.getItem('language') as string,
    resources: {
      nb: { translations: nb },
      se: { translations: se },
      gb: { translations: gb }
    },
    fallbackLng: 'nb',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  });
};
