import { Divider, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  PrimaryButton,
  TertiaryButton
} from 'src/components/shared/Button/Button';
import { Checkbox } from 'src/components/shared/Checkbox/Checkbox';
import { Autocomplete } from 'src/components/shared/Forms/Autocomplete/Autocomplete';
import {
  ErrorMessage,
  FormField
} from 'src/components/shared/Forms/FormHelpers';
import InputField from 'src/components/shared/Input/InputField';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { Header3 } from 'src/components/shared/Typography/Header3';
import { RootState } from 'src/store';
import { selectPricebookArticles } from 'src/store/articles/articles.selectors';
import { SettlementDetailDto } from 'src/store/settlements/settlement';
import {
  maxLength,
  required,
  runValidations,
  validationRunner
} from 'src/utils/validation';

type Props = {
  settlement: Nullable<SettlementDetailDto>;
  errors?: any;
  onCancel: () => any;
  onSubmit: (settlement: SettlementDetailDto) => any;
};

export const SettlementDetailsForm: React.FC<Props> = props => {
  const { onSubmit, onCancel } = props;
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(
    false
  );
  const [settlement, setSettlement] = useState<SettlementDetailDto>({
    weightDeviationAbove: false,
    weightDeviationBelow: false,
    weightDeviationAbs: false,
    countDeviationAbove: false,
    countDeviationBelow: false,
    countDeviationAbs: false
  } as SettlementDetailDto);

  const { t } = useTranslation();

  const articles$ = useSelector((state: RootState) =>
    selectPricebookArticles(state.articles)
  );

  useEffect(() => {
    if (props.settlement) {
      setSettlement(props.settlement);
    }
  }, [props.settlement]);

  const validate = (settlement: SettlementDetailDto) => {
    const validationRunners = [
      validationRunner('name', t('props.name'), required, maxLength(50))
    ];
    return runValidations(settlement, validationRunners);
  };

  return (
    <Formik
      initialValues={settlement}
      validate={validate}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        submitCount
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Header1 className="cf-mb-8">
                {settlement.id ? t('edit') : t('create')}{' '}
                {t('settlement.singular')}
                {settlement.id && ` - ${settlement.no}`}
              </Header1>
            </Grid>
            <Grid item xs={12} md={6} className="flex align-center jc-end">
              <TertiaryButton
                onClick={onCancel}
                label={t('cancel')}
                className="mr-32"
              />
              <PrimaryButton
                type="submit"
                label={t('save.verb')}
                disabled={isSubmitting}
                onClick={() => {
                  setShowValidationErrors(true);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={2}>
              <InputField
                name="no"
                label={t('props.number')}
                value={values.no}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.no}
                displayError={touched.no || submitCount > 0}
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <InputField
                name="name"
                label={t('props.name')}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.name}
                displayError={touched.name || submitCount > 0}
              />
            </Grid>
          </Grid>
          <Divider className="cf-my-6" />
          <Header3 className="cf-my-6">
            {t('settlement.msgSettlementTransport')}
          </Header3>
          <Grid container>
            <Grid item xs={12} md={2}>
              <FormField>
                <Autocomplete
                  options={articles$}
                  label={t('article.number')}
                  outputField="id"
                  optionField="no"
                  defaultValue={values.transportArticleId}
                  onChange={value => setFieldValue('transportArticleId', value)}
                  onBlur={handleBlur}
                />
                {errors.transportArticleId && showValidationErrors && (
                  <ErrorMessage>{errors.transportArticleId}</ErrorMessage>
                )}
              </FormField>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormField>
                <Autocomplete
                  options={articles$}
                  label={t('article.singular')}
                  outputField="id"
                  optionField="name"
                  defaultValue={values.transportArticleId}
                  onChange={value => setFieldValue('transportArticleId', value)}
                  onBlur={handleBlur}
                />
                {errors.transportArticleId && showValidationErrors && (
                  <ErrorMessage>{errors.transportArticleId}</ErrorMessage>
                )}
              </FormField>
            </Grid>
            <Grid item xs={12} md={2}>
              <InputField
                name="weight"
                label={t('props.weight')}
                value={values.weight}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.weight}
                displayError={touched.weight || submitCount > 0}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputField
                name="weightDeviation"
                label={t('props.deviation')}
                value={values.weightDeviation}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.weightDeviation}
                displayError={touched.weightDeviation || submitCount > 0}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Checkbox
                label={t('props.above')}
                checked={values.weightDeviationAbove}
                name="weightDeviationAbove"
                onChange={handleChange}
              />
              <Checkbox
                label={t('props.below')}
                checked={values.weightDeviationBelow}
                name="weightDeviationBelow"
                onChange={handleChange}
              />
              <Checkbox
                label={t('props.absolute')}
                checked={values.weightDeviationAbs}
                name="weightDeviationAbs"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Divider className="cf-my-6" />
          <Header3 className="cf-my-6">
            {t('settlement.msgSettlementArticle')}
          </Header3>
          <Grid container>
            <Grid item xs={12} md={2}>
              <FormField>
                <Autocomplete
                  options={articles$}
                  label={t('article.number')}
                  outputField="id"
                  optionField="no"
                  defaultValue={values.articleId}
                  onChange={value => setFieldValue('articleId', value)}
                  onBlur={handleBlur}
                />
                {errors.articleId && showValidationErrors && (
                  <ErrorMessage>{errors.articleId}</ErrorMessage>
                )}
              </FormField>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormField>
                <Autocomplete
                  options={articles$}
                  label={t('article.singular')}
                  outputField="id"
                  optionField="name"
                  defaultValue={values.articleId}
                  onChange={value => setFieldValue('articleId', value)}
                  onBlur={handleBlur}
                />
                {errors.articleId && showValidationErrors && (
                  <ErrorMessage>{errors.articleId}</ErrorMessage>
                )}
              </FormField>
            </Grid>
            <Grid item xs={12} md={2}>
              <InputField
                name="count"
                label={t('props.count')}
                value={values.count}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.count}
                displayError={touched.count || submitCount > 0}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputField
                name="countDeviation"
                label={t('props.deviation')}
                value={values.countDeviation}
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.countDeviation}
                displayError={touched.countDeviation || submitCount > 0}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Checkbox
                label={t('props.above')}
                checked={values.countDeviationAbove}
                name="countDeviationAbove"
                onChange={handleChange}
              />
              <Checkbox
                label={t('props.below')}
                checked={values.countDeviationBelow}
                name="countDeviationBelow"
                onChange={handleChange}
              />
              <Checkbox
                label={t('props.absolute')}
                checked={values.countDeviationAbs}
                name="countDeviationAbs"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
