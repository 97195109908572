import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Auxilary from 'src/components/hoc/Auxilary';
import { Header1 } from 'src/components/shared/Typography/Header1';

const NotFound: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Auxilary>
      <Header1>404</Header1>
      <p>
        {t('error.notfoundMessage')}. <Link to="/">{t('goback')}</Link>
      </p>
    </Auxilary>
  );
};

export default NotFound;
